import constants from '~/shared/constants';

const DrawRectangle = {
  onSetup: function(opts) {
    const rectangleFeature = this.newFeature({
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.FACILITY,
        type: constants.FACILITY_TYPES.RECTANGLE,
        extrusion_height: opts.extrusion?.height || 0,
        extrusion_base: opts.extrusion?.base || 0,
        levelId: opts.levelId
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[]]
      }
    });
    this.addFeature(rectangleFeature);
    this.clearSelectedFeatures();

    this.updateUIClasses({ mouse: 'add' });
    this.setActionableState({
      trash: true
    });
    const state = { rectangleFeature };
    return state;
  },

  onTap: function(state, e) {
    if (state.startPoint) {
      this.onMouseMove(state, e);
    }

    this.onClick(state, e);
  },

  onClick: function(state, e) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }
    if (
      state.startPoint &&
      state.startPoint[0] !== e.lngLat.lng &&
      state.startPoint[1] !== e.lngLat.lat
    ) {
      this.updateUIClasses({ mouse: 'pointer' });
      state.endPoint = [e.lngLat.lng, e.lngLat.lat];
      this.changeMode('simple_select');
      this.changeMode('direct_select', { featureId: state.rectangleFeature.id });
      return;
    }

    const startPoint = [e.lngLat.lng, e.lngLat.lat];
    state.startPoint = startPoint;
  },
  onMouseMove: function(state, e) {
    if (state.startPoint) {
      state.rectangleFeature.updateCoordinate('0.0', state.startPoint[0], state.startPoint[1]);
      state.rectangleFeature.updateCoordinate('0.1', e.lngLat.lng, state.startPoint[1]);
      state.rectangleFeature.updateCoordinate('0.2', e.lngLat.lng, e.lngLat.lat);
      state.rectangleFeature.updateCoordinate('0.3', state.startPoint[0], e.lngLat.lat);
    }
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      this.changeMode('simple_select');
    }
  },
  onStop: function(state) {
    this.updateUIClasses({ mouse: 'none' });
    this.activateUIButton();

    if (this.getFeature(state.rectangleFeature.id) === undefined) {
      return;
    }

    if (state.rectangleFeature.isValid()) {
      this.map.fire('draw.create', {
        features: [state.rectangleFeature.toGeoJSON()]
      });
    } else {
      this.deleteFeature([state.rectangleFeature.id], { silent: true });
      this.changeMode('simple_select', {}, { silent: true });
    }
  },
  toDisplayFeatures: function(state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.rectangleFeature.id;
    geojson.properties.active = isActivePolygon ? 'true' : 'false';
    if (!isActivePolygon) {
      display(geojson);
      return;
    }

    if (!state.startPoint) {
      return;
    }
    display(geojson);
  },
  onTrash: function(state) {
    this.deleteFeature([state.rectangleFeature.id], { silent: true });
    this.changeMode('simple_select');
  }
};

export default DrawRectangle;
