import constants from '~/shared/constants';

export function generateStaticLayers() {
  const layers = [];

  // wall, door, window - thick, thin, logical
  Object.values(constants.EDGE_TYPES).forEach((edgeType) => {
    Object.values(constants.EDGE_THICKNESS).forEach((edgeThickness) => {
      layers.push({
        id: `static-edge-${edgeType}-${edgeThickness}`,
        source: 'staticSource',
        type: 'line',
        filter: [
          'all',
          ['==', '$type', 'LineString'],
          ['==', '_type', constants.FEATURE_TYPES.EDGE],
          ['==', 'type', edgeType],
          ['==', 'thickness', edgeThickness]
        ],
        paint: {
          'line-width': constants.STYLES.EDGE_STYLES.LINE_WIDTH[edgeThickness],
          'line-dasharray': constants.STYLES.EDGE_STYLES.LINE_DASHARRAY[edgeThickness],
          'line-color': constants.STYLES.EDGE_STYLES.LINE_COLOR[edgeType],
          'line-opacity': constants.STYLES.LINE.OPACITY.STATIC
        }
      });
    });
  });

  // ellipse, rectangle, polygon - fill, stroke, extrusion
  Object.values(constants.FACILITY_TYPES).forEach((facilityType) => {
    layers.push({
      id: `static-facility-${facilityType}-fill`,
      source: 'staticSource',
      type: 'fill',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', '_type', constants.FEATURE_TYPES.FACILITY],
        ['==', 'type', facilityType]
      ],
      paint: {
        'fill-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
        'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
        'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.INACTIVE
      }
    });

    layers.push({
      id: `static-facility-${facilityType}-stroke`,
      source: 'staticSource',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', '_type', constants.FEATURE_TYPES.FACILITY],
        ['==', 'type', facilityType]
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round'
      },
      paint: {
        'line-color': constants.STYLES.FACILITY.LINE_COLOR.INACTIVE,
        'line-opacity': constants.STYLES.FACILITY.LINE_OPACITY.INACTIVE,
        'line-width': constants.STYLES.FACILITY.LINE_WIDTH.INACTIVE
      }
    });

    layers.push({
      id: `static-facility-${facilityType}-extrusion`,
      source: 'staticSource',
      type: 'fill-extrusion',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['==', '_type', constants.FEATURE_TYPES.FACILITY],
        ['==', 'type', facilityType]
      ],
      layout: {
        visibility: 'none'
      },
      paint: {
        'fill-extrusion-color': constants.STYLES.EXTRUSION.FACILITY.COLOR.STATIC,
        'fill-extrusion-base': ['get', 'extrusion_base'],
        'fill-extrusion-height': ['get', 'extrusion_height'],
        'fill-extrusion-opacity': constants.STYLES.EXTRUSION.FACILITY.OPACITY.BRIGHT
      }
    });
  });

  // POI
  layers.push({
    id: 'static-poi',
    source: 'staticSource',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'Point'], ['==', '_type', constants.FEATURE_TYPES.POI]],
    layout: {
      'icon-image': '{icon}',
      'icon-ignore-placement': true,
      'icon-allow-overlap': true,
      'symbol-placement': 'point',
      'text-field': '{name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-allow-overlap': true,
      'text-variable-anchor': ['top', 'left', 'bottom'],
      'text-justify': 'auto',
      'text-radial-offset': 1,
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.STATIC,
      'icon-opacity': constants.STYLES.POI.ICON_OPACITY.STATIC,
      'icon-halo-width': constants.STYLES.POI.ICON_HALO_WIDTH,
      'icon-halo-color': constants.STYLES.POI.ICON_HALO_COLOR
    }
  });

  // annotation
  layers.push({
    id: 'static-annotation',
    source: 'staticSource',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'Point'], ['==', '_type', constants.FEATURE_TYPES.ANNOTATION]],
    layout: {
      'symbol-placement': 'point',
      'text-field': '{name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-allow-overlap': true,
      'text-justify': 'auto',
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.STATIC
    }
  });

  // networkSegment
  layers.push({
    id: 'static-networkSegment',
    source: 'staticSource',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', '_type', constants.FEATURE_TYPES.NETWORK_SEGMENT]
    ],
    layout: {},
    paint: {
      'line-width': constants.STYLES.LINE.LINE_WIDTH,
      'line-dasharray': constants.STYLES.LINE.DASH_ARRAY,
      'line-color': constants.STYLES.LINE.COLOR.INACTIVE,
      'line-opacity': constants.STYLES.LINE.OPACITY.STATIC
    }
  });

  // junction - normal, stairs, elevator, entryPoint
  Object.values(constants.JUNCTION_TYPES).forEach((EPType) => {
    layers.push({
      id: `static-junction-${EPType}`,
      source: 'staticSource',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
        ['==', 'type', EPType]
      ],
      paint: {
        'circle-radius': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_RADIUS[EPType],
        'circle-color': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_COLOR.INACTIVE,
        'circle-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_OPACITY.STATIC
      }
    });
    if (EPType !== constants.JUNCTION_TYPES.STAIRS) {
      if (constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType]) {
        layers.push({
          id: `static-junction-${EPType}-icon`,
          source: 'staticSource',
          type: 'symbol',
          filter: [
            'all',
            ['==', '$type', 'Point'],
            ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
            ['==', 'type', EPType]
          ],
          layout: {
            'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType],
            'icon-allow-overlap': true,
            'icon-ignore-placement': true,
            'icon-size': 0.8
          },
          paint: {
            'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.STATIC
          }
        });
      }
    } else {
      layers.push({
        id: `static-junction-${EPType}-${constants.STAIRS_TYPES.UPWARDS}-icon`,
        source: 'staticSource',
        type: 'symbol',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'type', constants.JUNCTION_TYPES.STAIRS],
          ['==', 'direction', constants.STAIRS_TYPES.UPWARDS]
        ],
        layout: {
          'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[constants.STAIRS_TYPES.UPWARDS],
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.STATIC
        }
      });
      layers.push({
        id: `static-junction-${EPType}-${constants.STAIRS_TYPES.DOWNWARDS}-icon`,
        source: 'staticSource',
        type: 'symbol',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', '_type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'type', constants.JUNCTION_TYPES.STAIRS],
          ['==', 'direction', constants.STAIRS_TYPES.DOWNWARDS]
        ],
        layout: {
          'icon-image':
            constants.STYLES.EXCHANGE_POINT_STYLES.ICON[constants.STAIRS_TYPES.DOWNWARDS],
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.STATIC
        }
      });
    }
  });

  return layers;
}

/**
 * returns an array of 1 or 2 arrays
 * each array contains a paintProperty and a value
 * returning 2 arrays is necessary in case of pois, where
 * both text and icon opacity is set
 */
export function getOpacityValues(layer, { bright }) {
  if (layer.id.split('-')[0] !== 'static') {
    return undefined;
  }
  const featureName = layer.id.split('-')[1];
  switch (featureName) {
    case 'edge':
      if (layer.type === 'fill-extrusion') {
        return [
          [
            'fill-extrusion-opacity',
            bright
              ? constants.STYLES.EXTRUSION.WALL.OPACITY.BRIGHT
              : constants.STYLES.EXTRUSION.WALL.OPACITY.LIGHT
          ]
        ];
      } else {
        return [
          [
            'line-opacity',
            bright ? constants.STYLES.LINE.OPACITY.INACTIVE : constants.STYLES.LINE.OPACITY.STATIC
          ]
        ];
      }
    case 'facility':
      if (layer.type === 'fill') {
        return [
          [
            'fill-opacity',
            bright
              ? constants.STYLES.FACILITY.FILL_OPACITY.INACTIVE
              : constants.STYLES.FACILITY.FILL_OPACITY.STATIC
          ]
        ];
      } else if (layer.type === 'line') {
        return [
          [
            'line-opacity',
            bright
              ? constants.STYLES.FACILITY.LINE_OPACITY.INACTIVE
              : constants.STYLES.FACILITY.LINE_OPACITY.STATIC
          ]
        ];
      } else if (layer.type === 'fill-extrusion') {
        return [
          [
            'fill-extrusion-opacity',
            bright
              ? constants.STYLES.EXTRUSION.FACILITY.OPACITY.BRIGHT
              : constants.STYLES.EXTRUSION.FACILITY.OPACITY.LIGHT
          ]
        ];
      } else {
        return undefined;
      }
    case 'poi':
      return [
        [
          'text-opacity',
          bright
            ? constants.STYLES.POI.TEXT_OPACITY.ACTIVE
            : constants.STYLES.POI.TEXT_OPACITY.STATIC
        ],
        [
          'icon-opacity',
          bright ? constants.STYLES.POI.ICON_OPACITY.DRAW : constants.STYLES.POI.ICON_OPACITY.STATIC
        ]
      ];
    case 'annotation':
      return [
        [
          'text-opacity',
          bright
            ? constants.STYLES.POI.TEXT_OPACITY.ACTIVE
            : constants.STYLES.POI.TEXT_OPACITY.STATIC
        ]
      ];
    case 'networkSegment':
      return [
        [
          'line-opacity',
          bright ? constants.STYLES.LINE.OPACITY.INACTIVE : constants.STYLES.LINE.OPACITY.STATIC
        ]
      ];
    case 'junction':
      if (layer.type === 'circle') {
        return [
          [
            'circle-opacity',
            bright
              ? constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_OPACITY.INACTIVE
              : constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_OPACITY.STATIC
          ]
        ];
      } else if (layer.type === 'symbol') {
        return [
          [
            'icon-opacity',
            bright
              ? constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.INACTIVE
              : constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.STATIC
          ]
        ];
      } else {
        return undefined;
      }
  }
  return undefined;
}
