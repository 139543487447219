import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import { nearestPointOnLine as turfNearestPointOnLine } from '@turf/turf';
import constants from '~/shared/constants';

const DrawReferenceNodeMode = {
  onSetup: function(opts) {
    let state = {
      ...opts,
      type: constants.FEATURE_TYPES.REFERENCE_NODE,
      dragPosition: undefined
    };

    this.map.getCanvas().style.cursor = 'crosshair';

    return state;
  },

  onMouseMove: function(state, e) {
    this.snap(state, e);
  },

  onClick: function(state, e) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }

    const { addNewFeature } = this.snap(state, e);
    if (addNewFeature) {
      const newFeature = {
        type: 'Feature',
        properties: {
          _type: constants.FEATURE_TYPES.REFERENCE_NODE,
          levelId: state.levelId
        },
        geometry: {
          type: 'Point',
          coordinates: e.lngLat.toArray()
        }
      };
      const newPoint = this.newFeature(newFeature);
      this.addFeature(newPoint);
      this.map.fire(Constants.events.CREATE, {
        features: [newPoint.toGeoJSON()]
      });
    }
  },

  snap: function(state, e) {
    // snapping is disabled if the alt key is pressed
    if (!e.originalEvent.altKey) {
      const renderedFeatures = this._ctx.map
        .queryRenderedFeatures([
          [
            e.point.x - constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y - constants.SNAPPING_DISTANCE_PX / 2
          ],
          [
            e.point.x + constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y + constants.SNAPPING_DISTANCE_PX / 2
          ]
        ])
        .filter(
          (feature) =>
            feature.properties.user__type &&
            !feature.properties.user_guideline &&
            !feature.properties.user_measure_guides
        );

      // first try to snap to an other reference node...
      let pointToSnap = renderedFeatures.find(
        (feature) => feature.properties.user__type === state.type
      );
      // ...then try to snap to other nodes...
      if (!pointToSnap) {
        pointToSnap = renderedFeatures.find(
          (feature) => feature.properties.user__type === constants.FEATURE_TYPES.NODE
        );
      }

      if (pointToSnap) {
        const point = this.getFeature(pointToSnap.properties.id);
        if (point.properties._type === state.type) {
          // do not add new reference node, if it snaps to an existing reference node
          return { addNewFeature: false };
        }
        e.lngLat.lng = point.coordinates[0];
        e.lngLat.lat = point.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = pointToSnap;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !== JSON.stringify(e.lngLat.toArray())
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();

          this.updateHighlightPoint(state, e.lngLat.toArray());
        }

        return { addNewFeature: true };
      }

      // ...then try to snap to a segment
      const renderedSegment = renderedFeatures.find(
        (feature) => feature.properties.user__type === constants.FEATURE_TYPES.EDGE
      );

      if (renderedSegment) {
        const segment = this.getFeature(renderedSegment.properties.id);
        const nearestPoint = turfNearestPointOnLine(segment, e.lngLat.toArray());

        e.lngLat.lng = nearestPoint.geometry.coordinates[0];
        e.lngLat.lat = nearestPoint.geometry.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = nearestPoint;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !== JSON.stringify(e.lngLat.toArray())
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();

          this.updateHighlightPoint(state, e.lngLat.toArray());
        }

        return { addNewFeature: true };
      }
    }

    if (state.lastSnappingCoords) {
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });

      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
    }

    return { addNewFeature: true };
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });
      return this.changeMode('simple_select');
    }
  },

  toDisplayFeatures: function(state, geojson, display) {
    display(geojson);
  },

  updateHighlightPoint(state, coordinates) {
    const highlightFeature = {
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.HIGHLIGHT_POINT,
        levelId: state.levelId
      },
      geometry: {
        type: 'Point',
        coordinates: coordinates
      }
    };

    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
    }

    const highlightPoint = this.newFeature(highlightFeature);
    highlightPoint.properties.id = highlightPoint.id;
    this.addFeature(highlightPoint);
    state.highlightPoint = highlightPoint;
  },

  removeHighlightPoint(state) {
    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
      state.highlightPoint = undefined;
    }
  }
};

export default DrawReferenceNodeMode;
