import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import constants from '~/shared/constants';

function createSupplementaryPointsForRectangle(geojson) {
  const { properties, geometry } = geojson;

  if (!properties.user_type === constants.FACILITY_TYPES.RECTANGLE) {
    return undefined;
  }

  let supplementaryPoints = [
    createVertex(properties.id, geometry.coordinates[0][0], '0.0', false),
    createVertex(properties.id, geometry.coordinates[0][1], '0.1', false),
    createVertex(properties.id, geometry.coordinates[0][2], '0.2', false),
    createVertex(properties.id, geometry.coordinates[0][3], '0.3', false)
  ];

  return supplementaryPoints;
}

export default createSupplementaryPointsForRectangle;
