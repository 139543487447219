import * as CommonSelectors from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import constants from '~/shared/constants';

const DrawZone = {};

DrawZone.onSetup = function(opts) {
  const zone = this.newFeature({
    type: Constants.geojsonTypes.FEATURE,
    properties: {
      _type: constants.FEATURE_TYPES.ZONE,
      levelId: opts.levelId,
      zoneId: undefined,
      color: constants.STYLES.ZONE.DEFAULT_COLOR,
      opacity: constants.STYLES.ZONE.DEFAULT_FILL_OPACITY
    },
    geometry: {
      type: Constants.geojsonTypes.POLYGON,
      coordinates: [[]]
    }
  });

  this.addFeature(zone);

  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  this.activateUIButton(Constants.types.POLYGON);
  this.setActionableState({
    trash: true
  });

  return {
    zone: zone,
    currentVertexPosition: 0,
    lastSnappingCoords: undefined
  };
};

DrawZone.onMouseMove = function(state, e) {
  this.snap(state, e);
  state.zone.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  if (CommonSelectors.isVertex(e)) {
    this.updateUIClasses({ mouse: Constants.cursors.POINTER });
  }
};

DrawZone.onTap = DrawZone.onClick = function(state, e) {
  this.snap(state, e);
  if (
    state.currentVertexPosition > 0 &&
    (isEventAtCoordinates(e, state.zone.coordinates[0][state.currentVertexPosition - 1]) ||
      isEventAtCoordinates(e, state.zone.coordinates[0][0]))
  ) {
    if (state.lastSnappingCoords) {
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });

      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
    }
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.zone.id] });
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD });
  state.zone.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  state.currentVertexPosition++;
  state.zone.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
};

DrawZone.onKeyUp = function(state, e) {
  if (CommonSelectors.isEscapeKey(e)) {
    this.deleteFeature([state.zone.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT);
  } else if (CommonSelectors.isEnterKey(e)) {
    this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.zone.id] });
  }
};

DrawZone.onStop = function(state) {
  this.updateUIClasses({ mouse: Constants.cursors.NONE });
  doubleClickZoom.enable(this);
  this.activateUIButton();

  // check to see if we've deleted this feature
  if (this.getFeature(state.zone.id) === undefined) {
    return;
  }

  //remove last added coordinate
  state.zone.removeCoordinate(`0.${state.currentVertexPosition}`);
  if (state.zone.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.zone.toGeoJSON()]
    });
  } else {
    this.deleteFeature([state.zone.id], { silent: true });
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true });
  }
};

DrawZone.snap = function(state, e) {
  // snapping is disabled if the alt key is pressed
  if (!e.originalEvent.altKey) {
    const renderedFeatures = this._ctx.map.queryRenderedFeatures([
      [
        e.point.x - constants.SNAPPING_DISTANCE_PX / 2,
        e.point.y - constants.SNAPPING_DISTANCE_PX / 2
      ],
      [
        e.point.x + constants.SNAPPING_DISTANCE_PX / 2,
        e.point.y + constants.SNAPPING_DISTANCE_PX / 2
      ]
    ]);

    // try to snap to a point
    const renderedPoints = renderedFeatures.filter(
      (feature) =>
        feature.properties.user__type === constants.FEATURE_TYPES.NODE ||
        feature.properties.meta === Constants.meta.VERTEX
    );

    if (renderedPoints.length) {
      const renderedPoint = renderedPoints[0];
      const feature = this.getFeature(
        renderedPoint.properties.id ?? renderedPoint.properties.parent
      );
      if (feature.type.toLowerCase() === Constants.types.POINT) {
        e.lngLat.lng = feature.coordinates[0];
        e.lngLat.lat = feature.coordinates[1];
      } else if (feature.type.toLowerCase() === Constants.types.POLYGON) {
        const pos = renderedPoint.properties.coord_path.split('.');
        e.lngLat.lng = feature.coordinates[pos[0]][pos[1]][0];
        e.lngLat.lat = feature.coordinates[pos[0]][pos[1]][1];
      }
      e.point = this._ctx.map.project(e.lngLat);
      e.featureTarget = feature;

      if (
        !state.lastSnappingCoords ||
        JSON.stringify(state.lastSnappingCoords) !== JSON.stringify(e.lngLat.toArray())
      ) {
        this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
          coordinates: e.lngLat.toArray()
        });
        state.lastSnappingCoords = e.lngLat.toArray();
        this.updateHighlightPoint(state, e.lngLat.toArray());
      }
      return;
    }
  }
  if (state.lastSnappingCoords) {
    this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
      coordinates: undefined
    });

    state.lastSnappingCoords = undefined;
    this.removeHighlightPoint(state);
  }
};

DrawZone.toDisplayFeatures = function(state, geojson, display) {
  const isActiveZone = geojson.properties.id === state.zone.id;
  geojson.properties.active = isActiveZone
    ? Constants.activeStates.ACTIVE
    : Constants.activeStates.INACTIVE;
  if (!isActiveZone) {
    if (geojson.properties.user__type === constants.FEATURE_TYPES.ZONE) {
      let supplementaryPoints = undefined;
      supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: false
      });
      supplementaryPoints.forEach(display);
    }
    return display(geojson);
  }

  // Don't render a polygon until it has two positions
  // (and a 3rd which is just the first repeated)
  if (geojson.geometry.coordinates.length === 0) {
    return;
  }

  const coordinateCount = geojson.geometry.coordinates[0].length;
  // 2 coordinates after selecting a draw type
  // 3 after creating the first point
  if (coordinateCount < 3) {
    return;
  }
  geojson.properties.meta = Constants.meta.FEATURE;
  display(createVertex(state.zone.id, geojson.geometry.coordinates[0][0], '0.0', false));
  if (coordinateCount > 3) {
    // Add a start position marker to the map, clicking on this will finish the feature
    // This should only be shown when we're in a valid spot
    const endPos = geojson.geometry.coordinates[0].length - 3;
    display(
      createVertex(state.zone.id, geojson.geometry.coordinates[0][endPos], `0.${endPos}`, false)
    );
  }
  if (coordinateCount <= 3) {
    // If we've only drawn two positions (plus the closer),
    // make a LineString instead of a Polygon
    const lineCoordinates = [
      [geojson.geometry.coordinates[0][0][0], geojson.geometry.coordinates[0][0][1]],
      [geojson.geometry.coordinates[0][1][0], geojson.geometry.coordinates[0][1][1]]
    ];
    // create an initial vertex so that we can track the first point on mobile devices
    display({
      type: Constants.geojsonTypes.FEATURE,
      properties: geojson.properties,
      geometry: {
        coordinates: lineCoordinates,
        type: Constants.geojsonTypes.LINE_STRING
      }
    });
    if (coordinateCount === 3) {
      return;
    }
  }
  // render the Polygon
  return display(geojson);
};

DrawZone.onTrash = function(state) {
  this.deleteFeature([state.zone.id], { silent: true });
  this.changeMode(Constants.modes.SIMPLE_SELECT);
};

(DrawZone.updateHighlightPoint = function(state, coordinates) {
  const highlightFeature = {
    type: 'Feature',
    properties: {
      _type: constants.FEATURE_TYPES.HIGHLIGHT_POINT,
      levelId: state.levelId
    },
    geometry: {
      type: 'Point',
      coordinates: coordinates
    }
  };

  if (state.highlightPoint && state.highlightPoint.id) {
    this.deleteFeature(state.highlightPoint.id);
  }

  const highlightPoint = this.newFeature(highlightFeature);
  highlightPoint.properties.id = highlightPoint.id;
  this.addFeature(highlightPoint);
  state.highlightPoint = highlightPoint;
}),
  (DrawZone.removeHighlightPoint = function(state) {
    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
      state.highlightPoint = undefined;
    }
  });

export default DrawZone;
