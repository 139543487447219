import { store } from '../../store';
import { bbox as turfBbox } from '@turf/turf';

export class FitBoundsControl {
  constructor({ title }) {
    this._title = title;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement('button');
    this._btn.className = 'mapboxgl-ctrl-icon v-icon notranslate mdi mdi-crosshairs-gps';
    this._btn.type = 'button';
    this._btn.title = this._title;
    this._btn.onclick = function() {
      const bbox = getBBoxOfAll();
      if (bbox) {
        _this._map.fitBounds(bbox, { padding: 50, duration: 500 });
      }
    };

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  localize({ title }) {
    this._title = title;
    this.updateTitle();
  }

  updateTitle() {
    this._btn.title = this._title;
  }
}

export function getBBoxOfAll() {
  const allFeatures = store.getters['feature/allFeatures'];
  const currentImageMeta = store.getters['image/currentImageMeta'];
  const customImages = [...store.getters['customImage/imagesOnLevel']];

  if (
    (allFeatures && allFeatures.length) ||
    currentImageMeta ||
    (customImages && customImages.length)
  ) {
    const features = [...allFeatures.map((item) => item.feature)];

    if (currentImageMeta) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: currentImageMeta.coordinates
        }
      });
    }

    if (customImages) {
      for (const img of customImages) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: img.meta.coordinates
          }
        });
      }
    }

    return turfBbox({
      type: 'FeatureCollection',
      features
    });
  }
}
