import createVertex from '@mapbox/mapbox-gl-draw/src/lib/create_vertex';
import { destination as turfDestination } from '@turf/turf';
import constants from '~/shared/constants';

function createSupplementaryPointsForEllipse(geojson) {
  const { properties } = geojson;

  if (!properties.user_type === constants.FACILITY_TYPES.ELLIPSE) {
    return undefined;
  }

  const verticeCoord1 = turfDestination(
    properties.user_center,
    properties.user_semiMajorAxis,
    properties.user_bearing
  );

  // example to use more than one vertices

  // const verticeCoord2 = turfDestination(
  //   properties.user_center,
  //   properties.user_semiMajorAxis,
  //   properties.user_bearing - 180
  // );

  let supplementaryPoints = [
    createVertex(properties.id, verticeCoord1.geometry.coordinates, '0.0', false)
  ];

  return supplementaryPoints;
}

export default createSupplementaryPointsForEllipse;
