import constants from '~/shared/constants';

const generateEdgeStyles = () => {
  let edgeStyles = [];

  Object.values(constants.EDGE_TYPES).forEach((edgeType) => {
    Object.values(constants.EDGE_THICKNESS).forEach((edgeThickness) => {
      const inactive = {
        id: `gl-draw-edge-${edgeType}-${edgeThickness}-inactive`,
        type: 'line',
        filter: [
          'all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'false'],
          ['==', 'user__type', constants.FEATURE_TYPES.EDGE],
          ['==', 'user_type', edgeType],
          ['==', 'user_thickness', edgeThickness]
        ],
        paint: {
          'line-width': constants.STYLES.EDGE_STYLES.LINE_WIDTH[edgeThickness],
          'line-dasharray': constants.STYLES.EDGE_STYLES.LINE_DASHARRAY[edgeThickness],
          'line-color': constants.STYLES.EDGE_STYLES.LINE_COLOR[edgeType],
          'line-opacity': constants.STYLES.LINE.OPACITY.INACTIVE
        }
      };
      const active = {
        id: `gl-draw-edge-${edgeType}-${edgeThickness}-active`,
        type: 'line',
        filter: [
          'all',
          ['==', '$type', 'LineString'],
          ['==', 'active', 'true'],
          ['==', 'user__type', constants.FEATURE_TYPES.EDGE],
          ['==', 'user_type', edgeType],
          ['==', 'user_thickness', edgeThickness]
        ],
        paint: {
          'line-width': constants.STYLES.EDGE_STYLES.LINE_WIDTH[edgeThickness],
          'line-dasharray': constants.STYLES.EDGE_STYLES.LINE_DASHARRAY[edgeThickness],
          'line-color': constants.STYLES.LINE.COLOR.ACTIVE,
          'line-opacity': constants.STYLES.LINE.OPACITY.ACTIVE
        }
      };

      edgeStyles.push(active, inactive);
    });
  });

  return edgeStyles;
};

const generateExchangePointStyles = () => {
  let exchangePointStyles = [];

  Object.values(constants.JUNCTION_TYPES).forEach((EPType) => {
    let inactive = [
      {
        id: `gl-draw-exchange-${EPType}-inactive`,
        type: 'circle',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'false'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', EPType]
        ],
        paint: {
          'circle-radius': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_RADIUS[EPType],
          'circle-color': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_COLOR.INACTIVE,
          'circle-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_OPACITY.INACTIVE
        }
      }
    ];
    if (constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType]) {
      inactive.push({
        id: `gl-draw-exchange-${EPType}-icon-inactive`,
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'false'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', EPType]
        ],
        type: 'symbol',
        layout: {
          'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType],
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.INACTIVE
        }
      });
    }
    let active = [
      {
        id: `gl-draw-exchange-${EPType}-active`,
        type: 'circle',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'true'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', EPType]
        ],
        paint: {
          'circle-radius': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_RADIUS[EPType] * 2,
          'circle-color': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_COLOR.ACTIVE,
          'circle-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.CIRCLE_OPACITY.ACTIVE,
          'circle-blur': 0.8
        }
      }
    ];
    if (constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType]) {
      active.push({
        id: `gl-draw-exchange-${EPType}-icon-active`,
        type: 'symbol',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'true'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', EPType]
        ],
        layout: {
          'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[EPType],
          'icon-ignore-placement': true,
          'icon-allow-overlap': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.ACTIVE
        }
      });
    }
    exchangePointStyles.push(...active, ...inactive);
  });

  Object.values(constants.STAIRS_TYPES).forEach((SType) => {
    let inactive = [];
    if (constants.STYLES.EXCHANGE_POINT_STYLES.ICON[SType]) {
      inactive.push({
        id: `gl-draw-exchange-${SType}-icon-inactive`,
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'false'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', constants.JUNCTION_TYPES.STAIRS],
          ['==', 'user_direction', SType]
        ],
        type: 'symbol',
        layout: {
          'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[SType],
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.INACTIVE
        }
      });
    }
    let active = [];
    if (constants.STYLES.EXCHANGE_POINT_STYLES.ICON[SType]) {
      active.push({
        id: `gl-draw-exchange-${SType}-icon-active`,
        type: 'symbol',
        filter: [
          'all',
          ['==', '$type', 'Point'],
          ['==', 'active', 'true'],
          ['==', 'user__type', constants.FEATURE_TYPES.JUNCTION],
          ['==', 'user_type', constants.JUNCTION_TYPES.STAIRS],
          ['==', 'user_direction', SType]
        ],
        layout: {
          'icon-image': constants.STYLES.EXCHANGE_POINT_STYLES.ICON[SType],
          'icon-ignore-placement': true,
          'icon-allow-overlap': true,
          'icon-size': 0.8
        },
        paint: {
          'icon-opacity': constants.STYLES.EXCHANGE_POINT_STYLES.ICON_OPACITY.ACTIVE
        }
      });
    }
    exchangePointStyles.push(...active, ...inactive);
  });

  return exchangePointStyles;
};

export default [
  // reference node
  {
    id: 'gl-draw-reference-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.REFERENCE_NODE]
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#627d85',
      'circle-opacity': 0.7
    }
  },
  {
    id: 'gl-draw-reference-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.REFERENCE_NODE]
    ],
    paint: {
      'circle-radius': 12.5,
      'circle-color': '#627d85',
      'circle-opacity': 0.7,
      'circle-blur': 0.8
    }
  },

  // rectangle
  {
    id: 'gl-draw-rectangle-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.RECTANGLE]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.INACTIVE
    }
  },
  {
    id: 'gl-draw-rectangle-fill-active',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.RECTANGLE]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.ACTIVE
    }
  },
  {
    id: 'gl-draw-rectangle-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.RECTANGLE]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': constants.STYLES.FACILITY.LINE_COLOR.INACTIVE,
      'line-opacity': constants.STYLES.FACILITY.LINE_OPACITY.INACTIVE,
      'line-width': constants.STYLES.FACILITY.LINE_WIDTH.INACTIVE
    }
  },

  // ellipse
  {
    id: 'gl-draw-ellipse-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.ELLIPSE]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.INACTIVE
    }
  },
  {
    id: 'gl-draw-ellipse-fill-active',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.ELLIPSE]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.ACTIVE
    }
  },
  {
    id: 'gl-draw-ellipse-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.ELLIPSE]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': constants.STYLES.FACILITY.LINE_COLOR.INACTIVE,
      'line-opacity': constants.STYLES.FACILITY.LINE_OPACITY.INACTIVE,
      'line-width': constants.STYLES.FACILITY.LINE_WIDTH.INACTIVE
    }
  },

  // polygon
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.POLYGON]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.INACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.INACTIVE
    }
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.POLYGON]
    ],
    paint: {
      'fill-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-outline-color': constants.STYLES.FACILITY.FILL_COLOR.ACTIVE,
      'fill-opacity': constants.STYLES.FACILITY.FILL_OPACITY.ACTIVE
    }
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY],
      ['==', 'user_type', constants.FACILITY_TYPES.POLYGON]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': constants.STYLES.FACILITY.LINE_COLOR.INACTIVE,
      'line-opacity': constants.STYLES.FACILITY.LINE_OPACITY.INACTIVE,
      'line-width': constants.STYLES.FACILITY.LINE_WIDTH.INACTIVE
    }
  },

  // facilities
  {
    id: 'gl-draw-facility-stroke-active',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      ['==', 'user__type', constants.FEATURE_TYPES.FACILITY]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': constants.STYLES.FACILITY.LINE_COLOR.ACTIVE,
      'line-opacity': constants.STYLES.FACILITY.LINE_OPACITY.ACTIVE,
      'line-width': constants.STYLES.FACILITY.LINE_WIDTH.ACTIVE,
      'line-dasharray': constants.STYLES.FACILITY.LINE_DASH_ARRAY
    }
  },

  // network segment
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'active', 'false'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.NETWORK_SEGMENT]
    ],
    layout: {},
    paint: {
      'line-width': constants.STYLES.LINE.LINE_WIDTH,
      'line-dasharray': constants.STYLES.LINE.DASH_ARRAY,
      'line-color': constants.STYLES.LINE.COLOR.INACTIVE,
      'line-opacity': constants.STYLES.LINE.OPACITY.INACTIVE
    }
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.NETWORK_SEGMENT]
    ],
    layout: {},
    paint: {
      'line-width': constants.STYLES.LINE.LINE_WIDTH,
      'line-dasharray': constants.STYLES.LINE.DASH_ARRAY,
      'line-color': constants.STYLES.LINE.COLOR.ACTIVE,
      'line-opacity': constants.STYLES.LINE.OPACITY.ACTIVE
    }
  },

  ...generateEdgeStyles(),

  // node
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.NODE]
    ],
    paint: {
      'circle-radius': 6,
      'circle-color': '#ff6666',
      'circle-opacity': 0.8
    }
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.NODE]
    ],
    paint: {
      'circle-radius': 12,
      'circle-color': '#ff0000',
      'circle-opacity': 0.8,
      'circle-blur': 0.8
    }
  },

  // zone
  {
    id: 'gl-draw-zone-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.ZONE]
    ],
    paint: {
      'fill-color': ['get', 'user_color'],
      'fill-outline-color': ['get', 'user_color'],
      'fill-opacity': ['get', 'user_opacity']
    }
  },
  {
    id: 'gl-draw-zone-fill-active',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user__type', constants.FEATURE_TYPES.ZONE]
    ],
    paint: {
      'fill-color': ['get', 'user_color'],
      'fill-outline-color': ['get', 'user_color'],
      'fill-opacity': ['min', ['*', ['get', 'user_opacity'], 3], 1]
    }
  },
  {
    id: 'gl-draw-zone-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
      ['==', 'user__type', constants.FEATURE_TYPES.ZONE]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-opacity': ['min', ['*', ['get', 'user_opacity'], 3], 1],
      'line-width': constants.STYLES.ZONE.DEFAULT_LINE_WIDTH
    }
  },
  {
    id: 'gl-draw-zone-stroke-active',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'true'],
      ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      ['==', 'user__type', constants.FEATURE_TYPES.ZONE]
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': ['get', 'user_color'],
      'line-opacity': ['min', ['*', ['get', 'user_opacity'], 4], 1],
      'line-width': constants.STYLES.ZONE.DEFAULT_LINE_WIDTH,
      'line-dasharray': constants.STYLES.ZONE.DEFAULT_LINE_DASH_ARRAY
    }
  },
  {
    id: 'gl-draw-zone-name',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'user__type', constants.FEATURE_TYPES.ZONE]],
    layout: {
      'symbol-placement': 'point',
      'text-field': '{user_displayName}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-justify': 'center',
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': 0.8
    }
  },

  // polygon and zone helper vertices
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': constants.STYLES.FACILITY.VERTEX_STROKE_RADIUS,
      'circle-color': constants.STYLES.FACILITY.VERTEX_STROKE_COLOR
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': constants.STYLES.FACILITY.VERTEX_MAIN_RADIUS,
      'circle-color': constants.STYLES.FACILITY.VERTEX_MAIN_COLOR
    }
  },
  {
    id: 'gl-draw-polygon-midpoint-stroke',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': constants.STYLES.FACILITY.VERTEX_STROKE_RADIUS,
      'circle-color': constants.STYLES.FACILITY.VERTEX_STROKE_COLOR
    }
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': constants.STYLES.FACILITY.VERTEX_MAIN_RADIUS,
      'circle-color': constants.STYLES.FACILITY.VERTEX_MAIN_COLOR
    }
  },

  ...generateExchangePointStyles(),

  // poi
  {
    id: 'gl-draw-poi-active-glow',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.POI]
    ],
    paint: {
      'circle-radius': constants.STYLES.POI.ACTIVE_CIRCLE_RADIUS,
      'circle-color': constants.STYLES.POI.ACTIVE_CIRCLE_COLOR,
      'circle-opacity': constants.STYLES.POI.ACTIVE_CIRCLE_OPACTIY,
      'circle-blur': constants.STYLES.POI.ACTIVE_CIRCLE_BLUR
    }
  },
  {
    id: 'gl-draw-poi-active',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.POI]
    ],
    layout: {
      'icon-image': '{user_icon}',
      'icon-ignore-placement': true,
      'icon-allow-overlap': true,
      'symbol-placement': 'point',
      'text-field': '{user_name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-variable-anchor': ['top', 'left', 'bottom'],
      'text-justify': 'auto',
      'text-radial-offset': 1,
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.ACTIVE,
      'icon-opacity': constants.STYLES.POI.ICON_OPACITY.DRAW,
      'icon-halo-width': constants.STYLES.POI.ICON_HALO_WIDTH,
      'icon-halo-color': constants.STYLES.POI.ICON_HALO_COLOR
    }
  },
  {
    id: 'gl-draw-poi-inactive',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.POI]
    ],
    layout: {
      'icon-image': '{user_icon}',
      'icon-ignore-placement': true,
      'icon-allow-overlap': true,
      'symbol-placement': 'point',
      'text-field': '{user_name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-variable-anchor': ['top', 'left', 'bottom'],
      'text-justify': 'auto',
      'text-radial-offset': 1,
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.INACTIVE,
      'icon-opacity': constants.STYLES.POI.ICON_OPACITY.DRAW,
      'icon-halo-width': constants.STYLES.POI.ICON_HALO_WIDTH,
      'icon-halo-color': constants.STYLES.POI.ICON_HALO_COLOR
    }
  },

  // annotation
  {
    id: 'gl-draw-annotation-active-glow',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.ANNOTATION]
    ],
    paint: {
      'circle-radius': constants.STYLES.POI.ACTIVE_CIRCLE_RADIUS,
      'circle-color': constants.STYLES.POI.ACTIVE_CIRCLE_COLOR,
      'circle-opacity': constants.STYLES.POI.ACTIVE_CIRCLE_OPACTIY,
      'circle-blur': constants.STYLES.POI.ACTIVE_CIRCLE_BLUR
    }
  },
  {
    id: 'gl-draw-annotation-active',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['==', 'user__type', constants.FEATURE_TYPES.ANNOTATION]
    ],
    layout: {
      'symbol-placement': 'point',
      'text-field': '{user_name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-justify': 'auto',
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.ACTIVE
    }
  },
  {
    id: 'gl-draw-annotation-inactive',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'false'],
      ['==', 'user__type', constants.FEATURE_TYPES.ANNOTATION]
    ],
    layout: {
      'symbol-placement': 'point',
      'text-field': '{user_name}',
      'text-padding': 4,
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-justify': 'auto',
      'text-max-width': 7,
      'text-size': {
        stops: [
          [15, 10],
          [18, 16]
        ]
      }
    },
    paint: {
      'text-opacity': constants.STYLES.POI.TEXT_OPACITY.INACTIVE
    }
  },

  // guideline
  {
    id: 'gl-draw-guideline-left-angle',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_left_angle']],
    layout: {
      'symbol-placement': 'line-center',
      'text-field': '{user_left_angle}',
      'text-rotation-alignment': 'map',
      'text-keep-upright': true,
      'text-offset': [0, -1],
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-size': 12
    },
    paint: {}
  },
  {
    id: 'gl-draw-guideline-right-angle',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_right_angle']],
    layout: {
      'symbol-placement': 'line-center',
      'text-field': '{user_right_angle}',
      'text-rotation-alignment': 'map',
      'text-keep-upright': true,
      'text-offset': [0, 1],
      'text-anchor': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-size': 12
    },
    paint: {}
  },
  {
    id: 'gl-draw-guideline-length',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'LineString'], ['has', 'user_length']],
    layout: {
      'symbol-placement': 'line-center',
      'text-field': '{user_length}',
      'text-rotation-alignment': 'map',
      'text-keep-upright': true,
      'text-offset': ['get', 'user_offset'],
      'text-anchor': ['get', 'user_anchor'],
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-size': 12
    },
    paint: {}
  },

  // measure-guides

  {
    id: 'gl-draw-measure-guides-length',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'user__type', 'measure_guide'],
      ['has', 'user_measure_guides'],
      ['has', 'user_guide_length']
    ],
    layout: {
      'text-field': '{user_guide_length}',
      'text-rotate': { type: 'identity', property: 'user_guide_rotation' },
      'text-keep-upright': true,
      'text-offset': [0, -1],
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-size': 12
    },
    paint: {}
  },
  {
    id: 'gl-draw-measure-guides-angle',
    type: 'symbol',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'user__type', 'measure_guide'],
      ['has', 'user_measure_guides'],
      ['has', 'user_guide_angle']
    ],
    layout: {
      'symbol-placement': 'point',
      'text-field': '{user_guide_angle}',
      'text-rotation-alignment': 'map',
      'text-keep-upright': true,
      'text-anchor': 'center',
      'text-justify': 'center',
      'text-ignore-placement': true,
      'text-allow-overlap': true,
      'text-size': 12
    },
    paint: {}
  },

  // highlight point
  {
    id: 'gl-draw-highlight-point',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'user__type', constants.FEATURE_TYPES.HIGHLIGHT_POINT]
    ],
    paint: {
      'circle-radius': 12,
      'circle-color': '#ffcc00',
      'circle-opacity': 0.9,
      'circle-blur': 0.7
    }
  }
];
