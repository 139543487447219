import {
  distance as turfDistance,
  centroid as turfCentroid,
  bearing as turfBearing,
  destination as turfDestination,
  bearingToAzimuth
} from '@turf/turf';
import constants from '~/shared/constants';

let RotateMode = {
  onSetup: function(opts) {
    let state = {};
    state.selectedFeature = opts.selectedFeature;

    //TODO load the state if selectedFeature is passed
    //(note that its type is different from featureTarget's type)
    state.originalCenter = undefined;
    return state;
  },

  onMouseDown: function(state, e) {
    if (e.featureTarget) {
      if (
        !state.selectedFeature ||
        (state.selectedFeature && state.selectedFeature.id === e.featureTarget.properties.id)
      ) {
        if (this._ctx.api.get(e.featureTarget.properties.id)) {
          e.target['dragPan'].disable();
          state.featureType = e.featureTarget.properties['meta:type'];
          state.selectedFeature = this._ctx.api.get(e.featureTarget.properties.id);
          state.originalFeature = this._ctx.api.get(e.featureTarget.properties.id);
          state.originalCenter = turfCentroid(state.originalFeature);
          state.originalBearing = turfBearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);
          this.setSelected(e.featureTarget.properties.id);
        }
      }
    }
  },

  onClick: function(state, e) {
    if (e.featureTarget) {
      if (e.featureTarget.properties.id !== state.selectedFeature.id) {
        if (this._ctx.api.get(e.featureTarget.properties.id)) {
          e.target['dragPan'].disable();
          state.featureType = e.featureTarget.properties['meta:type'];
          state.selectedFeature = this._ctx.api.get(e.featureTarget.properties.id);
          state.originalFeature = this._ctx.api.get(e.featureTarget.properties.id);
          state.originalCenter = turfCentroid(state.originalFeature);
          state.originalBearing = turfBearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);

          this.setSelected(e.featureTarget.properties.id);
        }
      }
    } else {
      this.clearSelectedFeatures();
      state.selectedFeature = undefined;
    }
  },

  onDrag: function(state, e) {
    if (!state.selectedFeature) {
      return;
    }

    state.isDragged = true;

    let draggedBearing = turfBearing(state.originalCenter, [e.lngLat.lng, e.lngLat.lat]);
    let rotatedCoords = [];
    let multipolys = [];
    let polyCoords = [];
    let shouldRotate = true;
    switch (state.featureType) {
      case 'Point':
        shouldRotate = false;
        break;
      case 'LineString':
        shouldRotate = false;
        break;
      case 'Polygon':
        state.originalFeature.geometry.coordinates[0].forEach(function(coords) {
          let distanceFromCenter = turfDistance(state.originalCenter, coords);
          let bearingFromCenter = turfBearing(state.originalCenter, coords);
          let newPoint = turfDestination(
            state.originalCenter,
            distanceFromCenter,
            bearingFromCenter + draggedBearing - state.originalBearing
          );
          polyCoords.push(newPoint.geometry.coordinates);
        });
        rotatedCoords.push(polyCoords);
        break;
      case 'MultiLineString':
        state.originalFeature.geometry.coordinates.forEach(function(polygon) {
          let polyCoords = [];
          polygon.forEach(function(coords) {
            let distanceFromCenter = turfDistance(state.originalCenter, coords);
            let bearingFromCenter = turfBearing(state.originalCenter, coords);
            let newPoint = turfDestination(
              state.originalCenter,
              distanceFromCenter,
              bearingFromCenter + draggedBearing - state.originalBearing
            );
            polyCoords.push(newPoint.geometry.coordinates);
          });
          multipolys.push(polyCoords);
        });
        rotatedCoords = multipolys;
        break;
      case 'MultiPolygon':
        state.originalFeature.geometry.coordinates.forEach(function(polygon) {
          let polyCoords = [];
          polygon.forEach(function(polygonHoles) {
            let polyHoleCoords = [];
            polygonHoles.forEach(function(coords) {
              let distanceFromCenter = turfDistance(state.originalCenter, coords);
              let bearingFromCenter = turfBearing(state.originalCenter, coords);
              let newPoint = turfDestination(
                state.originalCenter,
                distanceFromCenter,
                bearingFromCenter + draggedBearing - state.originalBearing
              );
              polyHoleCoords.push(newPoint.geometry.coordinates);
            });
            polyCoords.push(polyHoleCoords);
          });
          multipolys.push(polyCoords);
        });
        rotatedCoords = multipolys;
        break;
      default:
        return;
    }
    if (shouldRotate) {
      let newFeature = state.selectedFeature;
      newFeature.geometry.coordinates = rotatedCoords;

      if (state.originalFeature.properties.type === constants.FACILITY_TYPES.ELLIPSE) {
        const draggedAngle = bearingToAzimuth(draggedBearing);
        const originalAngle = bearingToAzimuth(state.originalBearing);
        const diff = draggedAngle - originalAngle;
        let newFeatureAngle = bearingToAzimuth(state.originalFeature.properties.bearing) + diff;

        if (newFeatureAngle >= 360) {
          newFeatureAngle = newFeatureAngle - 360;
        }

        newFeature.properties.bearing =
          newFeatureAngle <= 180 ? newFeatureAngle : newFeatureAngle - 360;
      }

      this._ctx.api.add(newFeature);
    }
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      this.onMouseUp();
      this.changeMode(constants.DRAW_MODES.SIMPLE_SELECT);
    }
  },

  onMouseUp: function(state, e) {
    e.target['dragPan'].enable();
    state.originalCenter = undefined;
    state.featureType = undefined;

    if (state.isDragged) {
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.ROTATION, {
        coordinates: e.lngLat.toArray()
      });
    }

    return state;
  },

  toDisplayFeatures: function(state, geojson, display) {
    const isActive = state.selectedFeature && geojson.properties.id === state.selectedFeature.id;
    geojson.properties.active = isActive ? 'true' : 'false';

    display(geojson);
  }
};

export default RotateMode;
