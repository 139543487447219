import {
  bearing as turfBearing,
  distance as turfDistance,
  ellipse as turfEllipse
} from '@turf/turf';

import constants from '~/shared/constants';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

const DrawEllipseMode = {
  onSetup: function(opts) {
    const ellipseFeature = this.newFeature({
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.FACILITY,
        type: constants.FACILITY_TYPES.ELLIPSE,
        extrusion_height: opts.extrusion?.height || 0,
        extrusion_base: opts.extrusion?.base || 0,
        center: [],
        semiMajorAxis: undefined,
        semiMinorAxis: undefined,
        bearing: undefined,
        eccentricity: opts.eccentricity || 0.8,
        steps: opts.steps || 64,
        levelId: opts.levelId
      },
      geometry: {
        type: 'Polygon',
        coordinates: [[]]
      }
    });
    this.addFeature(ellipseFeature);

    this.clearSelectedFeatures();

    this.updateUIClasses({ mouse: 'add' });
    this.setActionableState({
      trash: true
    });

    const state = { ellipseFeature };
    return state;
  },

  onTap: function(state, e) {
    let { center } = state.ellipseFeature.properties;

    if (center && center.length) {
      this.onMouseMove(state, e);
    }

    this.onClick(state, e);
  },

  onClick: function(state, e) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }
    let { center } = state.ellipseFeature.properties;

    if (center && center.length && center[0] !== e.lngLat.lng && center[1] !== e.lngLat.lat) {
      this.updateUIClasses({ mouse: 'pointer' });

      // changing to simple_select resets the cursor
      this.changeMode('simple_select');
      this.changeMode('direct_select', { featureId: state.ellipseFeature.id });
      return;
    }

    state.ellipseFeature.properties.center = [e.lngLat.lng, e.lngLat.lat];
  },

  onMouseMove: function(state, e) {
    let { center, eccentricity, steps } = state.ellipseFeature.properties;
    if (center && center.length) {
      const semiMajorAxis = turfDistance(center, [e.lngLat.lng, e.lngLat.lat]);
      const semiMinorAxis = semiMajorAxis * Math.sqrt(1 - eccentricity ** 2);

      if (!semiMinorAxis || !semiMajorAxis) {
        return;
      }

      const bearing = turfBearing(center, [e.lngLat.lng, e.lngLat.lat]);

      state.ellipseFeature.properties = {
        ...state.ellipseFeature.properties,
        semiMajorAxis,
        semiMinorAxis,
        bearing
      };

      const ellipse = turfEllipse(center, semiMinorAxis, semiMajorAxis, {
        angle: bearing,
        steps: steps
      });
      state.ellipseFeature.incomingCoords(ellipse.geometry.coordinates);
    }
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      return this.changeMode('simple_select');
    }
  },

  onStop: function(state) {
    this.updateUIClasses({ mouse: 'none' });
    this.activateUIButton();

    if (this.getFeature(state.ellipseFeature.id) === undefined) {
      return;
    }

    if (state.ellipseFeature.isValid()) {
      this.map.fire('draw.create', {
        features: [state.ellipseFeature.toGeoJSON()]
      });
    } else {
      this.deleteFeature([state.ellipseFeature.id], { silent: true });
      this.changeMode('simple_select', {}, { silent: true });
    }
  },

  toDisplayFeatures: function(state, geojson, display) {
    const isActivePolygon = geojson.properties.id === state.ellipseFeature.id;
    geojson.properties.active = isActivePolygon
      ? Constants.activeStates.ACTIVE
      : Constants.activeStates.INACTIVE;
    if (!isActivePolygon) {
      display(geojson);

      return;
    }

    let { center } = state.ellipseFeature.properties;

    if (!center || !center.length) {
      return;
    }
    display(geojson);
  },

  onTrash: function(state) {
    this.deleteFeature([state.ellipseFeature.id], { silent: true });
    this.changeMode('simple_select');
  }
};

export default DrawEllipseMode;
