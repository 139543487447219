import constants from '~/shared/constants';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';

const DrawPoi = {
  onSetup: function(opts) {
    let state = {
      type: opts.type,
      levelId: opts.levelId,
      poiName: undefined
    };
    return state;
  },

  onClick: function(state, e) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }

    const newFeature = {
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.POI
      },
      geometry: {
        type: 'Point',
        coordinates: e.lngLat.toArray()
      }
    };
    const newPoint = this.newFeature(newFeature);

    this.map.fire(Constants.events.CREATE, {
      features: [newPoint.toGeoJSON()]
    });

    this.changeMode('simple_select');
  },

  toDisplayFeatures: function(state, geojson, display) {
    display(geojson);
  }
};

export default DrawPoi;
