<template>
  <v-form>
    <v-row dense class="mt-2" v-if="editorMode">
      <span class="mt-1 ml-3">{{ $t('Transparency') + ':' }}</span>
      <v-slider
        v-model="nodeTransparency"
        :disabled="!visible"
        class="mt-0 ml-1"
        min="0"
        max="100"
        thumb-label
        step="10"
      >
      </v-slider>
      <v-tooltip bottom v-if="editorMode">
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            class="mr-2 pt-1"
            :color="areNodesVisible ? 'primary' : undefined"
            small
            icon
            @click="areNodesVisible = !areNodesVisible"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span v-if="areNodesVisible">{{ $t('Hide reference nodes') }}</span>
        <span v-else>{{ $t('Show reference nodes') }}</span>
      </v-tooltip>
    </v-row>
  </v-form>
</template>

<style lang="scss" scoped>
.v-input--selection-controls.v-input {
  margin-top: auto;
  margin-bottom: 0px;
}
</style>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ReferenceNodePanel',
  props: ['map', 'draw'],

  data() {
    return {
      maxTransparencyValue: 100
    };
  },

  computed: {
    ...mapGetters('status', ['editorMode']),
    ...mapGetters('referenceNode', ['transparency', 'visible']),

    nodeTransparency: {
      get() {
        return this.transparency;
      },
      set(value) {
        this.changeTransparency(value);
      }
    },

    areNodesVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        this.changeVisibility(value);
      }
    }
  },

  methods: {
    ...mapMutations('referenceNode', {
      changeTransparency: 'changeTransparency',
      changeVisibility: 'changeVisibility'
    })
  },

  watch: {
    areNodesVisible() {
      const visibility = this.areNodesVisible ? 'visible' : 'none';
      if (this.map.getLayer('gl-draw-reference-point-inactive.hot')) {
        this.map.setLayoutProperty(
          'gl-draw-reference-point-inactive.hot',
          'visibility',
          visibility
        );
      }
      if (this.map.getLayer('gl-draw-reference-point-inactive.cold')) {
        this.map.setLayoutProperty(
          'gl-draw-reference-point-inactive.cold',
          'visibility',
          visibility
        );
      }
    },

    nodeTransparency() {
      if (this.areNodesVisible) {
        const newTransparency = 1 - this.nodeTransparency / this.maxTransparencyValue;
        if (this.map.getLayer('gl-draw-reference-point-inactive.hot')) {
          this.map.setPaintProperty(
            'gl-draw-reference-point-inactive.hot',
            'circle-opacity',
            newTransparency
          );
        }
        if (this.map.getLayer('gl-draw-reference-point-inactive.cold')) {
          this.map.setPaintProperty(
            'gl-draw-reference-point-inactive.cold',
            'circle-opacity',
            newTransparency
          );
        }
      }
    }
  }
};
</script>
