<template>
  <div>
    <v-row class="mx-2" no-gutters>
      <v-col cols="12">
        <v-checkbox
          class="my-2"
          :label="$t('Wheelchair')"
          v-model="wheelchair.open"
          :indeterminate="wheelchair.ind"
          @click="changeCheckBox(-1)"
          hide-details
        ></v-checkbox>
        <div>
          {{ $t('Passing through') }}
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-2" no-gutters>
      <v-col cols="6">
        <v-checkbox
          class="my-0"
          :label="$t(vtrNames[0])"
          v-model="vtr[0].open"
          :indeterminate="vtr[0].ind"
          @click="changeCheckBox(0)"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          class="my-0"
          :label="$t(vtrNames[1])"
          v-model="vtr[1].open"
          :indeterminate="vtr[1].ind"
          @click="changeCheckBox(1)"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row class="ma-2" no-gutters>
      <v-col cols="6">
        <v-checkbox
          class="my-0"
          :label="$t(vtrNames[2])"
          v-model="vtr[2].open"
          :indeterminate="vtr[2].ind"
          @click="changeCheckBox(2)"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          class="my-0"
          :label="$t(vtrNames[3])"
          v-model="vtr[3].open"
          :indeterminate="vtr[3].ind"
          @click="changeCheckBox(3)"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from 'vuex';
import constants from '~/shared/constants';
export default {
  name: 'VtrPanel',

  props: ['draw'],

  data() {
    return {
      wheelchair: { open: undefined, ind: false },
      vtr: [
        { open: undefined, ind: false },
        { open: undefined, ind: false },
        { open: undefined, ind: false },
        { open: undefined, ind: false }
      ],
      vtrNames: [
        constants.VTR.STAFF,
        constants.VTR.GUEST,
        constants.VTR.PUBLIC,
        constants.VTR.EMERGENCY
      ]
    };
  },
  computed: {
    ...mapGetters('feature', ['selectedNetworkSegmentIds'])
  },
  methods: {
    changeCheckBox: function(property) {
      let segments = this.draw.getSelected().features;
      let segment;
      if (property === -1) {
        for (segment of segments) {
          this.draw.setFeatureProperty(segment.id, 'wheelchair', this.wheelchair.open);
        }
        this.wheelchair.ind = false;
      } else if (property > -1 && property < 4) {
        for (segment of segments) {
          let vtr = segment.properties.vtr;
          vtr =
            vtr.substring(0, property) +
            (this.vtr[property].open ? 'N' : 'Y') +
            vtr.substring(property + 1);
          this.draw.setFeatureProperty(segment.id, 'vtr', vtr);
        }
        this.vtr[property].ind = false;
      }
      this.$emit('change');
    }
  },
  watch: {
    selectedNetworkSegmentIds: {
      immediate: true,
      handler() {
        let segments = this.draw.getSelected().features;
        this.wheelchair = { open: undefined, ind: false };
        this.vtr = [
          { open: undefined, ind: false },
          { open: undefined, ind: false },
          { open: undefined, ind: false },
          { open: undefined, ind: false }
        ];
        for (let segment of segments) {
          if (
            segment.properties &&
            Object.prototype.hasOwnProperty.call(segment.properties, 'wheelchair')
          ) {
            if (typeof this.wheelchair.open === 'undefined') {
              this.wheelchair.open = segment.properties.wheelchair;
            } else if (segment.properties.wheelchair !== this.wheelchair.open) {
              this.wheelchair.ind = true;
            }
          }
          if (segment.properties && segment.properties.vtr) {
            if (typeof this.vtr[0].open === 'undefined') {
              for (let i = 0; i < 4; i++) {
                this.vtr[i].open = segment.properties.vtr[i] === 'N';
              }
            } else {
              for (let i = 0; i < 4; i++) {
                if (this.vtr[i].open !== (segment.properties.vtr[i] === 'N')) {
                  this.vtr[i].ind = true;
                }
              }
            }
          }
        }
      }
    }
  }
};
</script>
