<template>
  <gli-dialog
    :title="isNew ? $t('Add new elevator') : $t('Edit elevator')"
    :buttonSaveText="isNew ? $t('Add') : $t('Edit')"
    :isNew="true"
    :editedObject="levelStops"
    :width="250"
    :oneSelected="oneSelected"
    id="dialog"
    @save="save"
    @cancel="cancel"
  >
    <v-row>
      <v-col cols="12" class="pb-0 pt-1 pl-5">
        <b>{{ $t('Elevator stops') }}:</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0 px-4">
        <v-divider class="px-6"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pl-4">
        <v-checkbox
          v-for="(level, index) of levels"
          :key="level.id"
          class="my-2"
          :label="$t(level.name)"
          v-model="levelStops[index]"
          :disabled="level.id === originalId"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
  </gli-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'ElevatorDialog',

  data() {
    return {
      levelStops: [],
      originalId: undefined
    };
  },
  computed: {
    ...mapGetters('elevator', ['chosenLevels', 'editedElevator', 'isNew']),
    ...mapGetters('level', ['editedLevel', 'levels']),
    ...mapGetters('site', ['editedSite']),
    oneSelected: function() {
      let selected = 0;
      for (const levelStop of this.levelStops) {
        if (levelStop) {
          selected++;
          if (selected > 1) {
            return false;
          }
        }
      }
      return true;
    }
  },

  created() {
    this.levelStops = [];
    for (const level of this.levels) {
      if (this.chosenLevels.length) {
        this.levelStops.push(this.chosenLevels.includes(level.id));
      } else {
        this.levelStops.push(level.id === this.editedLevel.id);
      }
      if (level.id === this.editedLevel.id) {
        this.originalId = level.id;
      }
    }
  },

  watch: {
    levelStops() {
      let levels = [];
      for (let i = 0; i < this.levels.length; i++) {
        if (this.levelStops[i]) {
          levels.push(this.levels[i]);
        }
      }

      this.setChosenLevels([...levels]);
    }
  },

  methods: {
    ...mapMutations('elevator', ['setChosenLevels']),
    ...mapActions('elevator', {
      saveElevator: 'finishAdding',
      editElevator: 'edit',
      modifyElevator: 'modify',
      cancelElevator: 'cancel'
    }),
    save() {
      if (this.isNew) {
        this.saveElevator();
      } else {
        this.modifyElevator({});
      }
    },
    cancel() {
      if (this.isNew) {
        this.$emit('cancel', this.editedElevator.feature);
      }
      this.cancelElevator(this.editedElevator.feature.id);
    }
  },
  components: {}
};
</script>
