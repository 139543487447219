import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import constants from '~/shared/constants';

import { nearestPointOnLine as turfNearestPointOnLine } from '@turf/turf';
import { getFromPropertyName, getToPropertyName } from '~/shared/utils';

const DrawNodeMode = {
  onSetup: function(opts) {
    const segmentType =
      opts.type === constants.FEATURE_TYPES.NODE
        ? constants.FEATURE_TYPES.EDGE
        : constants.FEATURE_TYPES.NETWORK_SEGMENT;

    let state = {
      type: opts.type,
      direction: opts.direction,
      segmentType,
      levelId: opts.levelId
    };
    if (state.type === constants.FEATURE_TYPES.JUNCTION) {
      state.junctionType = opts.junctionType ? opts.junctionType : constants.JUNCTION_TYPES.NORMAL;
    }
    return state;
  },

  snap: function(state, e) {
    // snapping is disabled if the alt key is pressed
    if (!e.originalEvent.altKey) {
      const renderedFeatures = this._ctx.map
        .queryRenderedFeatures([
          [
            e.point.x - constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y - constants.SNAPPING_DISTANCE_PX / 2
          ],
          [
            e.point.x + constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y + constants.SNAPPING_DISTANCE_PX / 2
          ]
        ])
        .filter(
          (feature) =>
            feature.properties.user__type &&
            !feature.properties.user_guideline &&
            !feature.properties.user_measure_guides
        );

      // first try to snap to a point:
      // first try to snap to the same types...
      let renderedPoints = renderedFeatures.filter(
        (feature) => feature.properties.user__type === state.type
      );
      // ...then try to snap to a reference node...
      if (renderedPoints.length === 0) {
        renderedPoints = renderedFeatures.filter(
          (feature) => feature.properties.user__type === constants.FEATURE_TYPES.REFERENCE_NODE
        );
      }

      if (renderedPoints.length) {
        const renderedPoint = renderedPoints[0];
        const point = this.getFeature(renderedPoint.properties.id);
        e.lngLat.lng = point.coordinates[0];
        e.lngLat.lat = point.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = renderedPoint;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !== JSON.stringify(e.lngLat.toArray())
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();

          this.updateHighlightPoint(state, e.lngLat.toArray());
        }
        return;
      }

      // ...then try to snap to a segment
      const renderedSegments = renderedFeatures.filter(
        (feature) => feature.properties.user__type === state.segmentType
      );

      if (renderedSegments.length) {
        const renderedSegment = renderedSegments[0];
        const segment = this.getFeature(renderedSegment.properties.id);

        const nearestPoint = turfNearestPointOnLine(segment, e.lngLat.toArray());

        e.lngLat.lng = nearestPoint.geometry.coordinates[0];
        e.lngLat.lat = nearestPoint.geometry.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = renderedSegment;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !==
            JSON.stringify(nearestPoint.geometry.coordinates)
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();

          this.updateHighlightPoint(state, e.lngLat.toArray());
        }

        return;
      }
    }
    if (state.lastSnappingCoords) {
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });

      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
    }
  },

  onClick: function(state, e) {
    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      return this.changeMode('simple_select');
    }

    this.snap(state, e);

    const renderedFeatures = this._ctx.map.queryRenderedFeatures(e.point);

    // if there is already a point at the clicked position, check if it should be transformed
    if (renderedFeatures.some((feature) => state.type === feature.properties.user__type)) {
      if (state.type === constants.FEATURE_TYPES.JUNCTION) {
        let renderedJunctionIds = [];
        for (const feature of renderedFeatures) {
          if (
            feature.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
            !renderedJunctionIds.includes(feature.properties.id)
          ) {
            renderedJunctionIds.push(feature.properties.id);
          }
        }
        if (renderedJunctionIds.length === 1) {
          const renderedJunction = this.getFeature(renderedJunctionIds[0]);
          if (renderedJunction.properties.type !== constants.JUNCTION_TYPES.NORMAL) {
            this.map.fire(constants.CUSTOM_DRAW_EVENTS.SPECIAL_JUNCTION_OVERWRITING, {
              message: 'Special junctions cannot be overwritten!'
            });
          } else {
            if (state.junctionType !== constants.JUNCTION_TYPES.NORMAL) {
              this._ctx.api.setFeatureProperty(renderedJunction.id, 'type', state.junctionType);

              if (state.junctionType === constants.JUNCTION_TYPES.STAIRS) {
                this._ctx.api.setFeatureProperty(renderedJunction.id, 'direction', state.direction);
              }

              this.map.fire(Constants.events.CREATE, {
                features: [this.getFeature(renderedJunctionIds[0]).toGeoJSON()]
              });
            }
          }
        }
      }
      return;
    }

    const newFeature = {
      type: 'Feature',
      properties: {
        _type: state.type,
        type: state.junctionType,
        levelId: state.levelId
      },
      geometry: {
        type: 'Point',
        coordinates: e.lngLat.toArray()
      }
    };

    if (state.junctionType === constants.JUNCTION_TYPES.STAIRS) {
      newFeature.properties.direction = state.direction;
    }

    const newPoint = this.newFeature(newFeature);

    newPoint.properties.id = newPoint.id;

    this.addFeature(newPoint);

    // if there is a segment at the clicked position then split the segment
    const segmentFeatures = renderedFeatures.filter(
      (feature) =>
        state.segmentType === feature.properties.user__type &&
        !feature.properties.user_guideline &&
        !feature.properties.user_measure_guides
    );
    if (segmentFeatures.length) {
      const segment = this.getFeature(segmentFeatures[0].properties.id);

      // align the point to the centre of the segment (the clicked segment has a width by style)
      const nearestPoint = turfNearestPointOnLine(segment, e.lngLat.toArray());
      newPoint.incomingCoords(nearestPoint.geometry.coordinates);

      const originalTo = {
        toId: segment.properties[getToPropertyName(state.segmentType)],
        coordinates: segment.coordinates[1]
      };

      segment.properties[getToPropertyName(state.segmentType)] = newPoint.id;
      segment.incomingCoords([segment.coordinates[0], nearestPoint.geometry.coordinates]);

      const newSegment = this.newFeature({
        type: 'Feature',
        properties: {
          ...segment.properties,
          alreadyCreated: false,
          id: undefined
        },
        geometry: {
          type: 'LineString',
          coordinates: [nearestPoint.geometry.coordinates, originalTo.coordinates]
        }
      });

      newSegment.properties[getFromPropertyName(state.segmentType)] = newPoint.id;
      newSegment.properties[getToPropertyName(state.segmentType)] = originalTo.toId;

      this.addFeature(newSegment);
    }

    this.map.fire(Constants.events.CREATE, {
      features: [newPoint.toGeoJSON()]
    });
  },

  onMouseMove: function(state, e) {
    this.snap(state, e);
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });
      return this.changeMode('simple_select');
    }
  },

  onMouseOut: function(state) {
    state.lastSnappingCoords = undefined;
    this.removeHighlightPoint(state);
  },

  toDisplayFeatures: function(state, geojson, display) {
    display(geojson);
  },

  updateHighlightPoint(state, coordinates) {
    const highlightFeature = {
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.HIGHLIGHT_POINT,
        levelId: state.levelId
      },
      geometry: {
        type: 'Point',
        coordinates: coordinates
      }
    };

    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
    }

    const highlightPoint = this.newFeature(highlightFeature);
    highlightPoint.properties.id = highlightPoint.id;
    this.addFeature(highlightPoint);
    state.highlightPoint = highlightPoint;
  },

  removeHighlightPoint(state) {
    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
      state.highlightPoint = undefined;
    }
  }
};

export default DrawNodeMode;
