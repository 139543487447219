import {
  dissolve as turfDissolve,
  bearing as turfBearing,
  polygon as turfPolygon,
  transformTranslate as turfTransformTranslate
} from '@turf/turf';
import turfBuffer from 'turfbufferEndCapStyle';
import constants from '../constants';

function createWallPolygons(wallLines, radius, options) {
  let mergedWalls = {};

  for (let wallLine of wallLines) {
    const type = wallLine.properties.type;
    let addElement = (elem) => {
      if (Object.prototype.hasOwnProperty.call(mergedWalls, type)) {
        mergedWalls[type].push(elem);
      } else {
        mergedWalls[type] = [elem];
      }
    };

    let copy = {
      type: wallLine.type,
      properties: {
        _type: wallLine.properties._type,
        type: type,
        thickness: wallLine.properties.thickness
      },
      geometry: {
        type: 'LineString',
        coordinates: [...wallLine.geometry.coordinates]
      }
    };

    let isAlone = true;
    if (wallLine.properties.fromNode) {
      let fromEdges = wallLines.filter(
        (e) =>
          e.properties.type === type &&
          e.properties.fromNode &&
          e.properties.fromNode.toString() === wallLine.properties.fromNode.toString() &&
          e.id !== wallLine.id
      );
      if (fromEdges.length) {
        for (let fromEdge of fromEdges) {
          let newItem = {
            type: copy.type,
            properties: copy.properties,
            geometry: {
              type: 'LineString',
              coordinates: [
                ...fromEdge.geometry.coordinates.reverse(),
                ...wallLine.geometry.coordinates.slice(1)
              ]
            }
          };
          // set back coordinates, because reverse() is destructive
          fromEdge.geometry.coordinates.reverse();
          addElement(newItem);
        }
        isAlone = false;
      }

      fromEdges = wallLines.filter(
        (e) =>
          e.properties.type === type &&
          e.properties.toNode &&
          e.properties.toNode.toString() === wallLine.properties.fromNode.toString() &&
          e.id !== wallLine.id
      );
      if (fromEdges.length) {
        for (let fromEdge of fromEdges) {
          let newItem = {
            type: copy.type,
            properties: copy.properties,
            geometry: {
              type: 'LineString',
              coordinates: [
                ...fromEdge.geometry.coordinates,
                ...wallLine.geometry.coordinates.slice(1)
              ]
            }
          };
          addElement(newItem);
        }
        isAlone = false;
      }
    }
    if (wallLine.properties.toNode) {
      let toEdges = wallLines.filter(
        (e) =>
          e.properties.type === type &&
          e.properties.fromNode &&
          e.properties.fromNode.toString() === wallLine.properties.toNode.toString() &&
          e.id !== wallLine.id
      );

      if (toEdges.length) {
        for (let toEdge of toEdges) {
          let newItem = {
            type: copy.type,
            properties: copy.properties,
            geometry: {
              type: 'LineString',
              coordinates: [
                ...wallLine.geometry.coordinates,
                ...toEdge.geometry.coordinates.slice(1)
              ]
            }
          };
          addElement(newItem);
        }
        isAlone = false;
      }

      toEdges = wallLines.filter(
        (e) =>
          e.properties.type === type &&
          e.properties.toNode &&
          e.properties.toNode.toString() === wallLine.properties.toNode.toString() &&
          e.id !== wallLine.id
      );
      if (toEdges.length) {
        for (let toEdge of toEdges) {
          let newItem = {
            type: copy.type,
            properties: copy.properties,
            geometry: {
              type: 'LineString',
              coordinates: [
                ...wallLine.geometry.coordinates,
                ...toEdge.geometry.coordinates.reverse().slice(1)
              ]
            }
          };
          // set back coordinates, because reverse() is destructive
          toEdge.geometry.coordinates.reverse();
          addElement(newItem);
        }
        isAlone = false;
      }
    }
    if (isAlone) {
      addElement(copy);
    }
  }

  let wallPolygons = [];
  for (const walls of Object.values(mergedWalls)) {
    for (let wall of walls) {
      wall.properties.idx = walls.indexOf(wall);
    }

    let buffered = turfBuffer({ type: 'FeatureCollection', features: walls }, radius, options);
    const bufferedIdxs = buffered.features.map((wall) => wall.properties.idx);
    if (buffered.features && buffered.features.length < walls.length) {
      for (const wall of walls) {
        if (!bufferedIdxs.includes(wall.properties.idx) && wall.geometry.coordinates.length === 2) {
          const bearing = turfBearing(wall.geometry.coordinates[0], wall.geometry.coordinates[1]);
          const rad = (bearing * Math.PI) / 180;
          const leftLine = turfTransformTranslate(
            turfTransformTranslate(wall, radius * Math.cos(rad), -90, {
              units: 'meters'
            }),
            radius * Math.sin(rad),
            0,
            {
              units: 'meters'
            }
          );

          const rightLine = turfTransformTranslate(
            turfTransformTranslate(wall, radius * Math.cos(rad), 90, {
              units: 'meters'
            }),
            radius * Math.sin(rad),
            180,
            {
              units: 'meters'
            }
          );

          const coords = [
            [
              [...leftLine.geometry.coordinates[0]],
              [...leftLine.geometry.coordinates[1]],
              [...rightLine.geometry.coordinates[1]],
              [...rightLine.geometry.coordinates[0]],
              [...leftLine.geometry.coordinates[0]]
            ]
          ];
          let bufferedPolygon = turfPolygon(coords, { ...wall.properties });
          buffered.features.push(bufferedPolygon);
        }
      }
    }
    try {
      let dissolved = turfDissolve(buffered);
      for (let feature of dissolved.features) {
        feature.properties = {
          _type: walls[0].properties._type,
          type: walls[0].properties.type,
          thickness: walls[0].properties.thickness
        };
      }
      wallPolygons.push(...dissolved.features);
    } catch (e) {
      // fallback if can not dissolve
      wallPolygons.push(...buffered.features);
    }
  }

  return wallPolygons;
}

export function createAllWallPolygons(features) {
  const edges = features.filter(
    (feature) =>
      Object.values(constants.EDGE_TYPES).includes(feature.properties.type) ||
      feature.properties._type === constants.FEATURE_TYPES.EDGE
  );

  let polygons = { type: 'FeatureCollection', features: [] };

  const thickWalls = edges.filter(
    (edge) => edge.properties.thickness === constants.EDGE_THICKNESS.THICK
  );

  const thickWallPolygons = createWallPolygons(thickWalls, 0.15, {
    units: 'meters',
    steps: 3,
    endCapStyle: 'flat',
    joinStyle: 'mitre',
    mitreLimit: 1.5
  });

  const thinWalls = edges.filter(
    (edge) => edge.properties.thickness === constants.EDGE_THICKNESS.THIN
  );

  const thinWallPolygons = createWallPolygons(thinWalls, 0.075, {
    units: 'meters',
    steps: 3,
    endCapStyle: 'flat',
    joinStyle: 'mitre',
    mitreLimit: 1.5
  });

  polygons.features.push(...thickWallPolygons, ...thinWallPolygons);

  polygons.features.forEach((polygon) => {
    if (polygon.properties.type === constants.EDGE_TYPES.WINDOW) {
      polygons.features.push({
        ...polygon,
        properties: {
          extrusion_base: 2.5,
          extrusion_height: 3
        }
      });
    }

    polygon.properties = {
      type: polygon.properties.type,
      extrusion_base: polygon.properties.type === constants.EDGE_TYPES.DOOR ? 2.5 : 0,
      extrusion_height: polygon.properties.type === constants.EDGE_TYPES.WINDOW ? 1 : 3
    };
  });

  return polygons;
}
