<template>
  <div style="height: 100%">
    <GLIMap @load="mapLoaded"></GLIMap>
    <ElevatorDialog v-if="editedElevator" v-on:cancel="cancelElevator"></ElevatorDialog>
    <context-menu v-if="map && editedSite" :map="map" :draw="draw"></context-menu>
    <div id="panel">
      <v-card tile v-if="map && editedSite" :disabled="!!addStairsStartedOnLevelId">
        <v-card-text :disabled="!!addStairsStartedOnLevelId" class="pa-2">
          <div class="header">
            <v-row align="center">
              <v-col cols="12">
                <GliEditable
                  @siteNameChanged="siteNameChanged"
                  v-model="editedSite.name"
                ></GliEditable>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-expansion-panels accordion v-model="panel">
          <poi-search v-if="map" :map="map"></poi-search>
          <routing-panel v-if="map" :map="map"></routing-panel>
          <v-expansion-panel :readonly="!canEdit" v-if="editedSite">
            <v-expansion-panel-header class="pb-2" :expand-icon="canEdit ? 'mdi-chevron-down' : ''">
              <v-icon class="mr-2 flex-grow-0">mdi-office-building</v-icon>{{ $t('Level') }}
              <level-selector></level-selector>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    class="ml-2"
                    @click.stop="showExportPopup = !showExportPopup"
                    dense
                    color="primary"
                    >mdi-export</v-icon
                  >
                </template>
                <span>{{ $t('Export level') }}</span>
              </v-tooltip>
            </v-expansion-panel-header>

            <v-expansion-panel-content :eager="true" v-if="canEdit">
              <v-card-text class="py-2 px-0">
                <level-editor
                  :map="map"
                  :draw="draw"
                  @duplicateLevel="duplicateLevel"
                  @setupLayers="setupLayers"
                ></level-editor>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-dialog width="400" v-model="showExportPopup">
            <v-card>
              <v-card-title class="py-2 primary white--text">
                {{ $t('Export level') }}
              </v-card-title>
              <v-card-text id="content-wrapper" class="pa-4">
                <v-radio-group v-model="exportAs">
                  <v-radio
                    :label="$t('Export as GeoJSON')"
                    :value="constants.EXPORT_TYPES.GEOJSON"
                  ></v-radio>
                  <v-radio
                    :label="$t('Export as PNG')"
                    :value="constants.EXPORT_TYPES.PNG"
                  ></v-radio>
                  <v-radio
                    :label="$t('Export as TIFF')"
                    :value="constants.EXPORT_TYPES.TIFF"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="isLoading" @click="showExportPopup = false">
                  {{ $t('Cancel') }}</v-btn
                >
                <v-btn :disabled="isLoading" color="primary" @click="_exportLevel">
                  <v-progress-circular
                    v-if="isLoading"
                    class="mr-2"
                    :size="20"
                    :width="2"
                    indeterminate
                  ></v-progress-circular>
                  {{ $t('Export') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-expansion-panel v-show="currentImageMeta || canEdit">
            <v-expansion-panel-header v-show="canEdit">
              <v-icon class="mr-2 flex-grow-0">mdi-floor-plan</v-icon>
              {{ $t('Floorplan') }}
            </v-expansion-panel-header>

            <div v-show="!canEdit" class="image-panel-viewer-mode pa-1 pl-3">
              <v-row no-gutters class="align-center">
                <v-tooltip bottom v-if="currentImageMeta">
                  <template v-slot:activator="{ on }">
                    <v-simple-checkbox
                      v-on="on"
                      class="mt-1"
                      v-model="isImageLayerVisible"
                      :disabled="!currentImageMeta"
                      on-icon="visibility"
                      off-icon="visibility"
                      color="primary"
                      hide-details
                    ></v-simple-checkbox>
                    <span>{{ $t('Floorplan image layer') }}</span>
                  </template>
                  <span>{{
                    isImageLayerVisible ? $t('Hide image layer') : $t('Show image layer')
                  }}</span>
                </v-tooltip>
              </v-row>
            </div>
            <v-expansion-panel-content
              v-if="canEdit"
              :eager="true"
              class="scrollable floorplan-panel-content mt-1"
            >
              <v-row dense>
                <v-col cols="12">
                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.NODE)"
                    :tooltip="$t('Add new node')"
                    :color="currentDrawMode === constants.DRAW_MODES.NODE ? 'primary' : undefined"
                    icon="mdi-vector-point"
                  ></panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.EDGE)"
                    :tooltip="$t('Add new edge')"
                    :color="currentDrawMode === constants.DRAW_MODES.EDGE ? 'primary' : undefined"
                    icon="mdi-vector-line"
                  ></panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.REFERENCE_NODE)"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.REFERENCE_NODE
                        ? 'primary'
                        : undefined
                    "
                    icon="mdi-transit-connection"
                    :tooltip="$t('Reference nodes settings')"
                  ></panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.CUSTOM_IMAGE)"
                    btnClass="ml-3"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.CUSTOM_IMAGE ? 'primary' : undefined
                    "
                    icon="mdi-image-multiple-outline"
                    :tooltip="$t('Custom images')"
                  ></panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.POI)"
                    :tooltip="$t('Add new POI')"
                    :color="currentDrawMode === constants.DRAW_MODES.POI ? 'primary' : undefined"
                    icon="mdi-map-marker"
                  >
                  </panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.ANNOTATION)"
                    :tooltip="$t('Add new annotation')"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.ANNOTATION ? 'primary' : undefined
                    "
                    icon="mdi-text-short"
                  >
                  </panel-button>
                </v-col>

                <v-col cols="12">
                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.POLYGON)"
                    :tooltip="$t('Add new polygon')"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.POLYGON ? 'primary' : undefined
                    "
                    icon="mdi-vector-polygon"
                  >
                  </panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.RECTANGLE)"
                    :tooltip="$t('Add new rectangle')"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.RECTANGLE ? 'primary' : undefined
                    "
                    icon="mdi-rectangle-outline"
                  >
                  </panel-button>

                  <panel-button
                    @click="switchDrawMode(constants.DRAW_MODES.ELLIPSE)"
                    :tooltip="$t('Add new ellipse')"
                    :color="
                      currentDrawMode === constants.DRAW_MODES.ELLIPSE ? 'primary' : undefined
                    "
                    icon="mdi-ellipse-outline"
                  >
                  </panel-button>

                  <panel-button
                    @click="duplicateSelectedFacility"
                    btnClass="ml-3"
                    color="undefined"
                    :disabled="
                      !isOneFacilitySelected &&
                        !isOnePoiSelected &&
                        !isOneAnnotationSelected &&
                        !isOneCustomImageSelected
                    "
                    icon="mdi-content-copy"
                    :tooltip="$t('Duplicate selected')"
                  >
                  </panel-button>

                  <panel-button
                    @click="rotate = !rotate"
                    :color="rotate ? 'primary' : undefined"
                    :disabled="!isOneFacilitySelected && !rotate"
                    icon="mdi-format-rotate-90"
                    :tooltip="$t('Rotate selected')"
                  >
                  </panel-button>

                  <panel-button
                    @click="deleteSelected"
                    :tooltip="$t('Delete selected')"
                    :disabled="
                      (currentDrawMode !== constants.DRAW_MODES.SIMPLE_SELECT ||
                        !selected ||
                        selected.features.length === 0) &&
                        !isOneCustomImageSelected
                    "
                    icon="mdi-delete"
                  >
                  </panel-button>
                </v-col>
              </v-row>
              <EdgeTypeSelector
                :map="map"
                :draw="draw"
                @change="
                  (e) => {
                    updateFeatures(false, e);
                  }
                "
                :undoCount="undoCount"
              ></EdgeTypeSelector>

              <PoiPanel :map="map" :draw="draw"></PoiPanel>
              <AnnotationPanel :map="map" :draw="draw"></AnnotationPanel>

              <custom-image-panel :map="map"> </custom-image-panel>

              <reference-node-panel
                v-show="currentDrawMode === constants.DRAW_MODES.REFERENCE_NODE"
                :map="map"
                :draw="draw"
              >
              </reference-node-panel>

              <v-row dense v-if="isOneFacilitySelected">
                <v-col cols="3" class="mt-2 pr-2">
                  <v-text-field
                    class="my-1"
                    :label="$t('Bearing')"
                    min="-180"
                    max="180"
                    step="1"
                    suffix="°"
                    type="number"
                    v-model.number="bearing"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="9" class="mt-2 pl-2">
                  <v-select
                    :label="$t('Tag')"
                    item-text="name"
                    item-value="name"
                    :items="tags"
                    v-model="selectedTag"
                    hide-details
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense class="justify-center">
                <v-col v-if="extrusionCanBeSpecified" cols="6" class="pr-2">
                  <v-text-field
                    v-model.number="extrusionHeight"
                    type="number"
                    :label="$t('Extrusion top')"
                    :min="extrusionBase"
                    max="100"
                    step="0.1"
                    suffix="m"
                    hide-details
                  ></v-text-field> </v-col
                ><v-col v-if="extrusionCanBeSpecified" cols="6" class="pl-2">
                  <v-text-field
                    v-model.number="extrusionBase"
                    type="number"
                    :label="$t('Extrusion base')"
                    min="0"
                    :max="extrusionHeight"
                    step="0.1"
                    suffix="m"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense v-if="isOneEllipseSelected">
                <v-col cols="5">
                  <v-text-field
                    class="my-1"
                    :label="$t('Major axis')"
                    :min="minLengthOfAxis"
                    step="0.01"
                    suffix="m"
                    type="number"
                    v-model.number="semiMajorAxis"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        :class="'mx-1 mt-6 ' + (keepFacilityAspectRatio ? 'primary' : undefined)"
                        v-bind="attrs"
                        v-on="on"
                        @click="changekeepFacilityAspectRatio()"
                      >
                        <v-icon small>mdi-link</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="keepFacilityAspectRatio">{{ $t('Ignore aspect ratio') }}</span>
                    <span v-else>{{ $t('Keep aspect ratio') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    class="my-1"
                    :label="$t('Minor axis')"
                    :min="minLengthOfAxis"
                    step="0.01"
                    suffix="m"
                    type="number"
                    v-model.number="semiMinorAxis"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense v-if="isOneRectangleSelected">
                <v-col cols="5">
                  <v-text-field
                    class="my-1"
                    :label="$t('Length')"
                    :min="minLengthOfAxis"
                    step="0.01"
                    suffix="m"
                    type="number"
                    v-model.number="rectangleLength"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        :class="'mx-1 mt-6 ' + (keepFacilityAspectRatio ? 'primary' : undefined)"
                        v-bind="attrs"
                        v-on="on"
                        @click="changekeepFacilityAspectRatio()"
                      >
                        <v-icon small>mdi-link</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="keepFacilityAspectRatio">{{ $t('Ignore aspect ratio') }}</span>
                    <span v-else>{{ $t('Keep aspect ratio') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    class="my-1"
                    :label="$t('Width')"
                    :min="minLengthOfAxis"
                    step="0.01"
                    suffix="m"
                    type="number"
                    v-model.number="rectangleWidth"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="canEdit">
            <v-expansion-panel-header>
              <v-icon class="mr-2 flex-grow-0">mdi-routes</v-icon>{{ $t('Route') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mt-1">
                <v-row dense class="mt-2 mb-2">
                  <v-col cols="12">
                    <panel-button
                      :color="currentDrawMode === constants.DRAW_MODES.NODE ? 'primary' : undefined"
                      @click="switchDrawMode(constants.DRAW_MODES.NODE)"
                      :tooltip="$t('Add new node')"
                      icon="mdi-vector-point"
                    >
                    </panel-button>
                    <panel-button
                      :color="currentDrawMode === constants.DRAW_MODES.EDGE ? 'primary' : undefined"
                      @click="switchDrawMode(constants.DRAW_MODES.EDGE)"
                      :tooltip="$t('Add new edge')"
                      icon="mdi-vector-line"
                    >
                    </panel-button>
                    <panel-button
                      btnClass="ml-3"
                      :color="
                        currentDrawMode === constants.DRAW_MODES.ENTRY_POINT ? 'primary' : undefined
                      "
                      @click="switchDrawMode(constants.DRAW_MODES.ENTRY_POINT)"
                      icon="mdi-door"
                      :tooltip="$t('Add new entry point')"
                    >
                    </panel-button>

                    <panel-button
                      v-if="levels.length > 1"
                      icon="mdi-elevator-passenger"
                      :tooltip="$t('Add new elevator')"
                      :color="
                        currentDrawMode === constants.DRAW_MODES.ELEVATOR ? 'primary' : undefined
                      "
                      @click="switchDrawMode(constants.DRAW_MODES.ELEVATOR)"
                    >
                    </panel-button>

                    <panel-button
                      v-if="levels.length > 1"
                      icon="mdi-stairs"
                      :tooltip="$t('Add new stairs')"
                      :color="
                        currentDrawMode === constants.DRAW_MODES.STAIRS ? 'primary' : undefined
                      "
                      @click="switchDrawMode(constants.DRAW_MODES.STAIRS)"
                    >
                    </panel-button>
                  </v-col>
                  <v-col cols="12">
                    <panel-button
                      :disabled="
                        currentDrawMode !== constants.DRAW_MODES.SIMPLE_SELECT ||
                          !selected ||
                          selected.features.length === 0
                      "
                      @click="deleteSelected"
                      :tooltip="$t('Delete selected')"
                      icon="mdi-delete"
                    >
                    </panel-button>
                  </v-col>
                </v-row>

                <div class="mt-4" v-if="isElevatorSelected">
                  <v-select
                    :label="$t('Elevator movement type')"
                    item-text="name"
                    item-value="id"
                    :items="elevatorMoveTypes"
                    v-model="elevatorMoveType"
                    hide-details
                  >
                  </v-select>
                </div>

                <VtrPanel
                  v-if="isNetworkSegmentSelected"
                  :draw="draw"
                  @change="
                    () => {
                      updateFeatures(false, constants.OPERATIONS.NETWORK.MODIFY_VTR);
                    }
                  "
                ></VtrPanel>
                <entry-point-panel
                  v-if="
                    currentDrawMode === constants.DRAW_MODES.ENTRY_POINT || isOneEntryPointSelected
                  "
                  v-on:setTransition="setEntryPointTransition"
                ></entry-point-panel>
                <div v-if="isNetworkSegmentSelected" class="mx-2 mt-4 mb-2">
                  {{ $t('Path type') }}
                </div>
                <v-row v-if="isNetworkSegmentSelected" class="mx-2 my-0" no-gutters>
                  <v-col cols="6">
                    <v-tooltip top :open-delay="700">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          class="mr-3"
                          :color="onlyStairsSelected ? 'primary' : undefined"
                          @click="changePathClass(constants.PATH_CLASS_ACTION_TYPES.STAIRS)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-stairs</v-icon>
                        </v-btn>
                      </template>
                      <span v-if="onlyStairsSelected">{{ $t('Change type to normal') }}</span>
                      <span v-else>{{ $t('Change type to stairs') }}</span>
                    </v-tooltip>
                    <v-tooltip top :open-delay="700">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          class="mr-3"
                          @click="changePathClass(constants.PATH_CLASS_ACTION_TYPES.DIRECTION)"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!onlyStairsSelected"
                        >
                          <v-icon>mdi-swap-vertical</v-icon>
                        </v-btn>
                      </template>
                      {{ $t('Switch stairs direction') }}
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="canEdit">
            <v-expansion-panel-header>
              <v-icon class="mr-2 flex-grow-0">mdi-texture-box</v-icon>
              {{ $t('Zone') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mt-1">
                <v-row dense class="mt-2 mb-2">
                  <v-col cols="12">
                    <panel-button
                      :color="currentDrawMode === constants.DRAW_MODES.ZONE ? 'primary' : undefined"
                      @click="switchDrawMode(constants.DRAW_MODES.ZONE)"
                      :tooltip="$t('Add new zone')"
                      icon="mdi-vector-square-plus"
                    >
                    </panel-button>
                    <panel-button
                      :disabled="
                        currentDrawMode !== constants.DRAW_MODES.SIMPLE_SELECT ||
                          !selected ||
                          selected.features.length === 0
                      "
                      @click="deleteSelected"
                      :tooltip="$t('Delete selected')"
                      icon="mdi-delete"
                    >
                    </panel-button>
                  </v-col>
                </v-row>
                <v-row>
                  <zone-panel
                    v-if="isOneZoneSelected"
                    @change="
                      (e) => {
                        updateSelectedZone(e);
                      }
                    "
                  ></zone-panel>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-card-text class="pa-2" v-if="canEdit">
          <div>
            <v-row :key="undoCount" v-if="undoCount || redoCount" class="mt-1">
              <v-col cols="6">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-badge color="primary" overlap :content="undoCount" :value="undoCount">
                      <gli-editor-button
                        v-on="on"
                        :disabled="undoCount === 0"
                        small
                        @click="callUndo"
                      >
                        <v-icon class="mr-1">mdi-undo</v-icon>
                      </gli-editor-button>
                    </v-badge>
                  </template>
                  <span>{{ $t('Undo operation') }} - {{ $t(undoOperation) }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-badge
                      class="ml-4"
                      color="primary"
                      overlap
                      :content="redoCount"
                      :value="redoCount"
                    >
                      <gli-editor-button v-on="on" :disabled="redoCount === 0" small @click="redo">
                        <v-icon class="mr-1">mdi-redo</v-icon>
                      </gli-editor-button>
                    </v-badge>
                  </template>
                  <span>{{ $t('Redo operation') }} - {{ $t(redoOperation) }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="6">
                <gli-editor-button
                  :disabled="!undoCount && !redoCount"
                  :block="true"
                  @click="cancel"
                  >{{ $t('Cancel') }}</gli-editor-button
                >
              </v-col>
              <v-col cols="6">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <gli-editor-button
                      v-on="on"
                      :disabled="!undoCount && !redoCount"
                      :block="true"
                      class="primary"
                      :color="imageSizeSumOk ? 'primary' : 'error'"
                      @click="save"
                      >{{ $t('Save') }}</gli-editor-button
                    >
                  </template>
                  <span v-if="imageSizeSumOk">{{ $t('Save site') }}</span>
                  <span v-else>{{ $t('Total image size is over 16 MB!') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <v-card class="mt-2" v-if="addStairsStartedOnLevelId">
        <v-card-text class="pa-2">
          <stairs-panel @cancel="cancelStairsAdding"></stairs-panel>
        </v-card-text>
      </v-card>
    </div>
    <v-overlay class="text-center" :value="imageConvertInProgress" opacity=".5">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <div class="mt-4">
        <b>{{ $t('Processing image...') }}</b>
      </div>
    </v-overlay>
  </div>
</template>

<style lang="scss" scoped>
@import '../../node_modules/@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

.w100 {
  width: 100%;
}

#panel {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 355px;

  ::v-deep .header {
    background-color: var(--v-primary-base);
    color: #fff;
    padding: 13px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.0892857143em;
  }

  ::v-deep .image-panel-viewer-mode {
    font-size: 15px;

    .v-input--selection-controls__ripple {
      border-radius: 50%;
      cursor: pointer;
      height: 30px;
      position: absolute;
      -webkit-transition: inherit;
      transition: inherit;
      width: 30px;
      left: -10px;
      top: calc(50% - 22px);
      margin: 7px;
    }
  }
}

.floorplan-panel-content {
  max-height: calc(100vh - 480px);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  padding-right: 4px;
}

.scrollable::-webkit-scrollbar {
  width: 6px;

  background-color: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
  background: var(--v-primary-base);
}

::v-deep .level-button {
  width: 100px;
}

::v-deep .level-button.active {
  font-weight: bold;
  color: var(--v-primary-base);
}
.v-expansion-panel-header {
  min-height: 20px;
  padding: 8px 12px;
}
.v-expansion-panel-header--active {
  min-height: 48px;
  padding: 16px 12px;
}

.theme--light.v-btn--active::before,
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active,
.theme--light.v-btn:focus::before,
.theme--light.v-btn:hover::before {
  opacity: 0 !important;
}
</style>

<script>
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import MapboxDraw from '@mapbox/mapbox-gl-draw/index';
import * as DrawConstants from '@mapbox/mapbox-gl-draw/src/constants';
import {
  bearing as turfBearing,
  centroid as turfCentroid,
  clone as turfClone,
  destination as turfDestination,
  distance as turfDistance,
  ellipse as turfEllipse,
  lineIntersect as turfLineIntersect,
  transformTranslate as turfTranslate
} from '@turf/turf';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import constants from '~/shared/constants';
import { createAllWallPolygons } from '~/shared/helpers/walls';
import {
  haveCommonEndpoint,
  isEdgeOrNetworkSegment,
  splitLine,
  tIntersection,
  wait
} from '~/shared/utils';
import ContextMenu from '../components/ContextMenu.vue';
import ElevatorDialog from '../components/ElevatorDialog';
import PanelButton from '../components/PanelButton';
import ReferenceNodePanel from '../components/ReferenceNodePanel.vue';
import StairsPanel from '../components/StairsPanel';
import VtrPanel from '../components/VtrPanel.vue';
import GliEditable from '../components/base/GliEditable';
import confirm from '../helpers/confirm';
import DirectSelectModeOverride from '../helpers/map/DirectSelectModeOverride';
import DrawAnnotationMode from '../helpers/map/DrawAnnotationMode';
import DrawEdgeMode from '../helpers/map/DrawEdgeMode';
import DrawEllipseMode from '../helpers/map/DrawEllipseMode';
import DrawNodeMode from '../helpers/map/DrawNodeMode';
import DrawPoiMode from '../helpers/map/DrawPoiMode';
import DrawPolygonMode from '../helpers/map/DrawPolygonMode';
import DrawRectangleMode from '../helpers/map/DrawRectangleMode';
import DrawReferenceNodeMode from '../helpers/map/DrawReferenceNodeMode';
import DrawZoneMode from '../helpers/map/DrawZoneMode';
import { getBBoxOfAll } from '../helpers/map/FitBoundsControl';
import RotateMode from '../helpers/map/RotateMode';
import SimpleSelectModeOverride from '../helpers/map/SimpleSelectModeOverride';
import additionalDrawStyles from '../helpers/map/additionalDrawStyles';
import { generateStaticLayers, getOpacityValues } from '../helpers/map/additionalMapboxStyles';
import i18n from '../i18n';
import { ZoneActions } from '../store/actions';

const ELLIPSE_STEPS = 64;
const ELLIPSE_ECCENTRICITY = 0.8;
const SHIFT_METER_KILOMETER = 1000;

export default {
  name: 'FloorplanEditor',

  data() {
    return {
      constants,
      map: undefined,
      draw: undefined,
      all: undefined,
      selected: undefined,
      rotate: false,
      defaultExtrusionHeight: 1,
      defaultExtrusionBase: 0,
      layersVisibleInExtrusion: [],
      onlyStairsSelected: true,
      panel: undefined,
      staticFeatures: [],
      minLengthOfAxis: 0.01,
      exportAs: 'geojson',
      isLoading: false,
      showExportPopup: false
    };
  },

  computed: {
    ...mapGetters('account', ['canEdit', 'isSuperAdmin']),
    ...mapGetters('app', ['lastBaseMapLayerId']),
    ...mapGetters('site', ['editedSite']),
    ...mapGetters('feature', ['currentFeatures', 'selectedNetworkSegmentIds']),
    ...mapGetters('elevator', ['editedElevator', 'moveType', 'lastMoveWasSuccessful']),
    ...mapGetters('stairs', {
      editedStairs: 'editedStairs',
      addStairsStartedOnLevelId: 'addStartedOnLevelId'
    }),
    ...mapGetters('status', {
      extrusionState: 'extrusion',
      keepFacilityAspectRatio: 'keepFacilityAspectRatio',
      currentMainMode: 'currentMainMode',
      currentDrawMode: 'currentDrawMode'
    }),
    ...mapGetters('level', ['editedLevel', 'levels', 'numberOfNewLevels']),
    ...mapGetters('image', [
      'currentImageMeta',
      'currentImageUrl',
      'isLayerVisible',
      'imageConvertInProgress',
      'imagesByLevel',
      'imageTransparency',
      'editImageOnSetup'
    ]),
    ...mapGetters('customImage', {
      customImagesOnLevel: 'imagesOnLevel',
      customImagesByLevel: 'imagesByLevel',
      selectedImageMeta: 'selectedImageMeta',
      customImageLoadedFromHistory: 'loadedFromHistory'
    }),
    ...mapGetters('history', {
      undoCount: 'undoCount',
      redoCount: 'redoCount',
      undoOperation: 'undoOperation',
      redoOperation: 'redoOperation'
    }),
    ...mapGetters('referenceNode', {
      referenceNodeVisible: 'visible'
    }),
    ...mapGetters('organization', ['organizations']),
    ...mapGetters('entryPoint', ['networkTransition', 'siteTransition']),

    extrusion: {
      get() {
        return this.extrusionState;
      },
      set() {
        this.toggleExtrusion();
      }
    },

    elevatorMoveType: {
      get() {
        return this.moveType;
      },
      set(value) {
        this.changeElevatorMoveType(value);
      }
    },

    isNetworkSegmentSelected() {
      return this.selectedNetworkSegmentIds && this.selectedNetworkSegmentIds.length;
    },

    isImageLayerVisible: {
      get() {
        return this.isLayerVisible;
      },
      set(value) {
        this.setLayerVisibility(value);
      }
    },

    isElevatorSelected() {
      if (!this.selected || !this.selected.features) {
        return false;
      }

      return this.selected.features.some(
        (feature) => feature.properties.type === constants.JUNCTION_TYPES.ELEVATOR
      );
    },

    isOneEllipseSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties.type === constants.FACILITY_TYPES.ELLIPSE
      );
    },

    isOneRectangleSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties.type === constants.FACILITY_TYPES.RECTANGLE
      );
    },

    isOnePolygonSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties.type === constants.FACILITY_TYPES.POLYGON
      );
    },

    isOneFacilitySelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties._type === constants.FEATURE_TYPES.FACILITY
      );
    },

    isOnePoiSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties._type === constants.FEATURE_TYPES.POI
      );
    },

    isOneAnnotationSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties._type === constants.FEATURE_TYPES.ANNOTATION
      );
    },

    isOneCustomImageSelected() {
      return this.selected?.features?.length === 0 && this.selectedImageMeta;
    },

    isOneEntryPointSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties._type === constants.FEATURE_TYPES.JUNCTION &&
        this.selected.features[0].properties.type === constants.JUNCTION_TYPES.ENTRY_POINT
      );
    },

    isOneZoneSelected() {
      return (
        this.selected?.features?.length === 1 &&
        this.selected.features[0].properties._type === constants.FEATURE_TYPES.ZONE
      );
    },

    extrusionCanBeSpecified() {
      return this.isOneFacilitySelected;
    },

    bearing: {
      get() {
        return this.getHelper('bearing').toFixed(0);
      },
      set(value) {
        if (typeof value !== 'number') {
          value = 0;
        }
        this.setHelper('bearing', value % 360);

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.BEARING);
      }
    },

    rectangleLength: {
      get() {
        return (this.getHelper('length') * SHIFT_METER_KILOMETER).toFixed(2);
      },
      set(value) {
        if (typeof value !== 'number' || value < 0) {
          value = 0;
        }

        this.setHelper('length', value / SHIFT_METER_KILOMETER);

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.RECTANGLE_LENGTH);
      }
    },

    rectangleWidth: {
      get() {
        return (this.getHelper('width') * SHIFT_METER_KILOMETER).toFixed(2);
      },
      set(value) {
        if (typeof value !== 'number' || value < this.minLengthOfAxis) {
          value = this.minLengthOfAxis;
        }
        // exchange meters to kilometers
        this.setHelper('width', value / SHIFT_METER_KILOMETER);

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.RECTANGLE_WIDTH);
      }
    },

    semiMajorAxis: {
      get() {
        // exchange kilometers to meters
        return (this.getHelper('semiMajorAxis') * SHIFT_METER_KILOMETER).toFixed(2);
      },
      set(value) {
        if (typeof value !== 'number' || value < this.minLengthOfAxis) {
          value = this.minLengthOfAxis;
        }
        // exchange meters to kilometers
        this.setHelper('semiMajorAxis', value / SHIFT_METER_KILOMETER);

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.MAJOR_AXIS);
      }
    },

    semiMinorAxis: {
      get() {
        // exchange kilometers to meters
        return (this.getHelper('semiMinorAxis') * SHIFT_METER_KILOMETER).toFixed(2);
      },
      set(value) {
        if (typeof value !== 'number' || value < this.minLengthOfAxis) {
          value = this.minLengthOfAxis;
        }
        // exchange meters to kilometers
        this.setHelper('semiMinorAxis', value / SHIFT_METER_KILOMETER);

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.MINOR_AXIS);
      }
    },

    extrusionHeight: {
      get() {
        if (this.selected?.features?.length === 1) {
          return this.selected.features[0].properties['extrusion_height'];
        } else if (this.selected?.features?.length > 1) {
          return 0;
        }
        return this.defaultExtrusionHeight;
      },
      set(value) {
        if (typeof value !== 'number') {
          value = 0;
        }
        let maxValidValue = value;
        let selected = this.draw.getSelected();
        let skipHistory = true;
        selected.features.forEach((feature) => {
          if (feature.properties._type === 'facility') {
            let validatedValue = value;
            // the height must not be less than the base
            if (feature.properties.extrusion_base > value) {
              validatedValue = feature.properties.extrusion_base;
              if (maxValidValue < validatedValue) {
                maxValidValue = validatedValue;
              }
            }
            if (validatedValue !== feature.properties.extrusion_height) {
              skipHistory = false;
            }
            this.draw.setFeatureProperty(feature.id, 'extrusion_height', validatedValue);
          }
        });
        this.defaultExtrusionHeight = maxValidValue;

        const updated = this.draw.getAll();
        this.draw.set(updated);

        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.EXTRUSION_TOP, skipHistory);
      }
    },

    extrusionBase: {
      get() {
        if (this.selected?.features?.length === 1) {
          return this.selected.features[0].properties['extrusion_base'];
        } else if (this.selected?.features?.length > 1) {
          return 0;
        }
        return this.defaultExtrusionBase;
      },
      set(value) {
        if (typeof value !== 'number') {
          value = 0;
        }
        let minValidValue = value;
        let selected = this.draw.getSelected();
        let skipHistory = true;
        // the base should not be larger than the height
        selected.features.forEach((feature) => {
          if (feature.properties._type === 'facility') {
            let validatedValue = value;
            if (feature.properties.extrusion_height < value) {
              validatedValue = feature.properties.extrusion_height;
              if (minValidValue > validatedValue) {
                minValidValue = validatedValue;
              }
            }
            if (validatedValue !== feature.properties.extrusion_base) {
              skipHistory = false;
            }
            this.draw.setFeatureProperty(feature.id, 'extrusion_base', validatedValue);
          }
        });
        this.defaultExtrusionBase = minValidValue;

        const updated = this.draw.getAll();
        this.draw.set(updated);
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.EXTRUSION_BASE, skipHistory);
      }
    },

    pathClasses() {
      return [
        { text: i18n.t('normal'), value: constants.PATH_CLASSES.NORMAL },
        { text: i18n.t('stairs up'), value: constants.PATH_CLASSES.STAIRS_UP },
        { text: i18n.t('stairs down'), value: constants.PATH_CLASSES.STAIRS_DOWN }
      ];
    },

    elevatorMoveTypes() {
      return [
        { id: constants.ELEVATOR_MOVE_TYPES.FREE, name: this.$t('Free move') },
        { id: constants.ELEVATOR_MOVE_TYPES.WITH_PATH, name: this.$t('Move with path') }
      ];
    },

    imageSizeSumOk() {
      let sizeSum = 0;
      if (this.imagesByLevel) {
        for (let levelId in this.imagesByLevel) {
          if (this.imagesByLevel[levelId].meta.size) {
            sizeSum += this.imagesByLevel[levelId].meta.size;
          }
        }
      }
      if (this.customImagesByLevel) {
        for (let levelId in this.customImagesByLevel) {
          for (const image of this.customImagesByLevel[levelId]) {
            if (image.meta.size) {
              sizeSum += image.meta.size;
            }
          }
        }
      }
      if (sizeSum < constants.IMAGE_SIZE_LIMIT) {
        return true;
      } else {
        return false;
      }
    },

    selectedTag: {
      get() {
        if (this.isOneFacilitySelected) {
          return this.selected.features[0].properties.tag;
        }
        return undefined;
      },
      set(value) {
        if (this.isOneFacilitySelected) {
          const tag = this.tags.find((tag) => tag.name === value);
          if (tag) {
            if ('top' in tag && 'base' in tag) {
              this.extrusionHeight = tag.top;
              this.extrusionBase = tag.base;
            }
            this.draw.setFeatureProperty(this.selected.features[0].id, 'tag', value);
          } else {
            this.draw.setFeatureProperty(this.selected.features[0].id, 'tag', undefined);
          }
          this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.CHANGE_TAG, false);
        }
      }
    },

    tags() {
      let organization;
      let tags = [];
      if (this.isSuperAdmin) {
        organization = this.organizations.find((org) => org.id === this.editedSite.organizationId);
        if (!organization) {
          for (const org of this.organizations) {
            if (org.settings && org.settings.tags) {
              tags.push(...org.settings.tags);
            }
          }
          const tagNames = tags.map((tag) => tag.name);
          if (this.selectedTag && !tagNames.includes(this.selectedTag)) {
            tags.push({ name: this.selectedTag });
          }
          return tags;
        }
      } else {
        organization = this.organizations[0];
      }
      tags.push(...organization.settings.tags);
      const tagNames = organization.settings.tags.map((tag) => tag.name);
      if (this.selectedTag && !tagNames.includes(this.selectedTag)) {
        tags.push({ name: this.selectedTag });
      }
      return tags;
    }
  },

  async mounted() {
    await this.getAllOrganizations();

    this.layersVisibleInExtrusion = ['static-edge-wall-extrusion'];
    Object.values(constants.FACILITY_TYPES).forEach((facilityType) => {
      ['active', 'inactive'].forEach((activeOrInactive) => {
        ['hot', 'cold'].forEach((hotOrCold) => {
          this.layersVisibleInExtrusion.push(
            `gl-draw-extrusion-${facilityType}-${activeOrInactive}.${hotOrCold}`
          );
        });
      });
    });
  },

  watch: {
    isImageLayerVisible() {
      if (this.map.getLayer('level-image')) {
        this.map.setLayoutProperty(
          'level-image',
          'visibility',
          this.isImageLayerVisible ? 'visible' : 'none'
        );
      }
    },

    currentFeatures: {
      handler() {
        this.loadFeatures();
      },
      deep: true
    },

    editedLevel() {
      if (this.editedLevel) {
        this.setupLayers();
      }
    },

    currentMainMode() {
      if (this.currentMainMode === constants.MAIN_MODES.STATIC && this.panel !== undefined) {
        this.panel = undefined;
      }
      this.updateCurrentFeatures(this.editedLevel.id);
      this.toggleExtrusion(false);
      this.loadFeatures();
      this.setupLayers();
    },

    panel(newValue) {
      switch (newValue) {
        case 0: {
          this.setCurrentMainMode(constants.MAIN_MODES.LEVEL);
          break;
        }
        case 1: {
          this.setCurrentMainMode(constants.MAIN_MODES.FLOORPLAN);
          break;
        }
        case 2: {
          this.setCurrentMainMode(constants.MAIN_MODES.NETWORK);
          break;
        }
        case 3: {
          this.setCurrentMainMode(constants.MAIN_MODES.ZONE);
          break;
        }
        default: {
          // newValue is undefined when all panels are closed
          this.setCurrentMainMode(constants.MAIN_MODES.STATIC);
          break;
        }
      }
    },

    async extrusion() {
      const updated = this.draw.getAll();
      this.setupLayers();
      this.draw.set(updated);
    },

    rotate() {
      if (this.rotate) {
        this.draw.changeMode(constants.DRAW_MODES.ROTATE, {
          selectedFeature: this.draw.getSelected().features[0]
        });
      } else {
        this.draw.changeMode(constants.DRAW_MODES.SIMPLE_SELECT);
      }
    },

    selectedNetworkSegmentIds() {
      this.onlyStairsSelected = true;
      if (this.selectedNetworkSegmentIds && this.selectedNetworkSegmentIds.length > 0) {
        let segments = this.draw.getSelected().features;
        this.onlyStairsSelected = !segments.some(
          (segment) => segment.properties.pathClass === constants.PATH_CLASSES.NORMAL
        );
      }
    },

    '$route.params.siteId': async function(siteId) {
      if (siteId) {
        await this.editSite({ siteId });
      }
      this.fitFeaturesBounds();
    },

    currentImageUrl() {
      this.updateLevelImageLayer({ removeSource: true });
    },

    currentImageMeta() {
      if (this.currentImageMeta) {
        this.updateLevelImageLayer();
        this.map.getSource('level-image').setCoordinates(this.currentImageMeta.coordinates);
      }
    },

    customImagesOnLevel(newArray, oldArray) {
      this.updateCustomImages(newArray, oldArray);
    },

    isOneFacilitySelected() {
      this.defaultExtrusionHeight = 1;
      this.defaultExtrusionBase = 0;
    },

    isOneEntryPointSelected(newVal) {
      if (!newVal) {
        this.clearEntryPointState();
      }
    },

    isOneZoneSelected(newVal) {
      if (!newVal) {
        this[ZoneActions.CLEAR_STATE]();
      }
    }
  },

  beforeDestroy() {
    this.endSiteEditing();
    this.clearRouting();
  },

  methods: {
    ...mapMutations('site', {
      endSiteEditing: 'cancel',
      editSiteMutation: 'edit'
    }),
    ...mapMutations('feature', {
      updateSNS: 'updateSelectedNetworkSegmentIds',
      updateCurrentFeatures: 'updateCurrentFeatures'
    }),
    ...mapMutations('elevator', {
      changeElevatorMoveType: 'changeMoveType',
      setElevatorMoveResult: 'setMoveResult'
    }),
    ...mapMutations('image', ['setLayerVisibility']),
    ...mapMutations('routing', ['clearRouting']),
    ...mapMutations('status', {
      mapLoadedVuex: 'mapLoaded',
      updateStatusBarMouseCoords: 'updateMouseCoords',
      updateStatusBarEdgeProps: 'updateEdgeProps',
      updateStatusBarSnappingCoords: 'updateSnappingCoords',
      toggleExtrusion: 'toggleExtrusion',
      changekeepFacilityAspectRatio: 'changekeepFacilityAspectRatio'
    }),
    ...mapActions('status', ['setCurrentMainMode', 'setCurrentDrawMode', 'setSkipApplyStyle']),
    ...mapActions('site', { editSite: 'edit', saveSite: 'save' }),
    ...mapActions('level', {
      duplicateLevel: 'duplicate',
      exportLevel: 'export'
    }),
    ...mapActions('feature', ['updateLevel']),
    ...mapActions('poi', { addPoi: 'addNew' }),
    ...mapActions('annotation', { addAnnotation: 'addNew' }),
    ...mapActions('image', ['saveImage', 'getImage', 'changeEditImageOnSetup']),
    ...mapActions('customImage', {
      duplicateCustomImage: 'duplicate',
      deleteCustomImage: 'deleteImage',
      clearCustomImageLoadedFromHistory: 'clearLoadedFromHistory'
    }),
    ...mapActions('history', ['undo', 'redo']),
    ...mapActions('history', { addToHistory: 'add', loadCurrentFromHistory: 'loadCurrent' }),
    ...mapActions('alert', ['error']),
    ...mapActions('stairs', { addStairs: 'add' }),
    ...mapActions('elevator', { addElevator: 'startAdding' }),
    ...mapActions('entryPoint', {
      editEntryPoint: 'edit',
      clearEntryPointState: 'clear'
    }),
    ...mapActions('referenceNode', { addNewReferenceNode: 'addNewReferenceNode' }),
    ...mapActions('organization', { getAllOrganizations: 'getAll' }),
    ...mapActions('zone', [ZoneActions.EDIT, ZoneActions.CLEAR_STATE, ZoneActions.SET_PROPERTIES]),

    callUndo() {
      // reset edge mode internal state
      if (this.currentDrawMode === constants.DRAW_MODES.EDGE) {
        this.switchDrawMode(constants.DRAW_MODES.EDGE); // exit the mode
        this.switchDrawMode(constants.DRAW_MODES.EDGE); // enter the mode
      }

      this.undo();
    },

    cancelStairsAdding() {
      this.switchDrawMode(constants.DRAW_MODES.SIMPLE_SELECT);
      this.loadCurrentFromHistory();
    },

    updateFeatures(clearDraw = true, operation, skipHistory = false) {
      let editedFeatures = this.getIntersectedFeatures(constants.FEATURES_TO_SAVE.DRAW);
      editedFeatures = editedFeatures
        .filter((feature) => !feature.properties.guideline && !feature.properties.measure_guides)
        .map((feature) => ({
          feature: { ...feature },
          id: feature.id
        }));

      editedFeatures.push(...this.staticFeatures);

      if (clearDraw) {
        this.draw.deleteAll();
      }

      this.updateLevel({
        levelId: this.editedLevel.id,
        features: editedFeatures,
        operation,
        skipHistory
      });
    },

    loadFeatures() {
      this.draw.deleteAll();
      this.staticFeatures = [];

      // separate features to draw and static source based on main mode
      if (this.currentFeatures) {
        for (const { id, feature } of this.currentFeatures) {
          if (
            constants.FEATURE_GROUPS[this.currentMainMode].DRAW.includes(feature.properties._type)
          ) {
            this.draw.add({ ...feature, id });
          } else {
            this.staticFeatures.push({ feature, id });
          }
        }
      }

      this.map.getSource('staticSource').setData({
        type: 'FeatureCollection',
        features: this.staticFeatures ? this.staticFeatures.map(({ feature }) => feature) : []
      });
    },

    switchDrawMode(drawMode) {
      this.map.getCanvas().focus();
      this.updateSNS([]);

      // if switching to current draw mode, switch back to default
      // image and stairs modes are excluded,
      // because they can have a levelchange during an operation
      if (
        (this.currentDrawMode === drawMode &&
          ![constants.DRAW_MODES.IMAGE, constants.DRAW_MODES.STAIRS].includes(
            this.currentDrawMode
          )) ||
        drawMode === constants.DRAW_MODES.DEFAULT
      ) {
        this.setCurrentDrawMode(constants.DEFAULT_DRAW_MODES[this.currentMainMode]);
        this.map.getCanvas().style.cursor = 'default';
        this.draw.changeMode(constants.DEFAULT_DRAW_MODES[this.currentMainMode]);
        this.map.fire(DrawConstants.events.MODE_CHANGE, {
          mode: constants.DEFAULT_DRAW_MODES[this.currentMainMode]
        });
        return;
      }

      let opts = { levelId: this.editedLevel.id };
      switch (this.currentMainMode) {
        case constants.MAIN_MODES.STATIC: {
          // always switch to static draw mode
          this.setCurrentDrawMode(constants.DEFAULT_DRAW_MODES[this.currentMainMode]);
          this.map.getCanvas().style.cursor = 'default';
          this.draw.changeMode(constants.DEFAULT_DRAW_MODES[this.currentMainMode]);
          this.map.fire(DrawConstants.events.MODE_CHANGE, {
            mode: constants.DEFAULT_DRAW_MODES[this.currentMainMode]
          });
          return;
        }
        case constants.MAIN_MODES.LEVEL: {
          if (drawMode === constants.DRAW_MODES.IMAGE) {
            // floorplan image mode
            this.draw.changeMode(constants.DRAW_MODES.SIMPLE_SELECT);
            this.map.fire(DrawConstants.events.MODE_CHANGE, {
              mode: constants.DRAW_MODES.SIMPLE_SELECT
            });
            this.setCurrentDrawMode(drawMode);
            return;
          } else {
            // can only be static (in theory)
            this.draw.changeMode(drawMode);
            this.map.fire(DrawConstants.events.MODE_CHANGE, {
              mode: drawMode
            });
            this.setCurrentDrawMode(drawMode);
            return;
          }
        }
        case constants.MAIN_MODES.FLOORPLAN: {
          switch (drawMode) {
            case constants.DRAW_MODES.NODE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.NODE };
              break;
            }
            case constants.DRAW_MODES.EDGE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.EDGE };
              break;
            }
            case constants.DRAW_MODES.REFERENCE_NODE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.REFERENCE_NODE };
              break;
            }
            case constants.DRAW_MODES.ELLIPSE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              this.rotate = false;
              opts = {
                ...opts,
                steps: ELLIPSE_STEPS,
                eccentricity: ELLIPSE_ECCENTRICITY,
                extrusion: { height: this.extrusionHeight, base: this.extrusionBase }
              };
              this.$nextTick(() => {
                this.draw.changeMode(drawMode, opts);
                this.map.fire(DrawConstants.events.MODE_CHANGE, {
                  mode: drawMode,
                  featureType: opts.type
                });
                document.querySelector('canvas').focus();
              });
              this.setCurrentDrawMode(drawMode);
              return;
            }
            case constants.DRAW_MODES.RECTANGLE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              this.rotate = false;
              opts = {
                ...opts,
                extrusion: { height: this.extrusionHeight, base: this.extrusionBase }
              };
              this.$nextTick(() => {
                this.draw.changeMode(drawMode, opts);
                this.map.fire(DrawConstants.events.MODE_CHANGE, {
                  mode: drawMode
                });
                document.querySelector('canvas').focus();
              });
              this.setCurrentDrawMode(drawMode);
              return;
            }
            case constants.DRAW_MODES.POLYGON: {
              this.map.getCanvas().style.cursor = 'crosshair';
              this.rotate = false;
              opts = {
                ...opts,
                extrusion: { height: this.extrusionHeight, base: this.extrusionBase }
              };
              this.$nextTick(() => {
                this.draw.changeMode(drawMode, opts);
                this.map.fire(DrawConstants.events.MODE_CHANGE, {
                  mode: drawMode
                });
                document.querySelector('canvas').focus();
              });
              this.setCurrentDrawMode(drawMode);
              return;
            }
            case constants.DRAW_MODES.POI: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.POI };
              break;
            }
            case constants.DRAW_MODES.ANNOTATION: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.ANNOTATION };
              break;
            }
            case constants.DRAW_MODES.CUSTOM_IMAGE: {
              this.draw.changeMode(constants.DRAW_MODES.STATIC);
              this.map.fire(DrawConstants.events.MODE_CHANGE, {
                mode: constants.DRAW_MODES.STATIC
              });
              this.setCurrentDrawMode(drawMode);
              return;
            }
          }
          break;
        }
        case constants.MAIN_MODES.NETWORK: {
          switch (drawMode) {
            case constants.DRAW_MODES.NODE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.JUNCTION };
              break;
            }
            case constants.DRAW_MODES.EDGE: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = { ...opts, type: constants.FEATURE_TYPES.NETWORK_SEGMENT };
              break;
            }
            case constants.DRAW_MODES.ENTRY_POINT: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = {
                ...opts,
                type: constants.FEATURE_TYPES.JUNCTION,
                junctionType: constants.JUNCTION_TYPES.ENTRY_POINT
              };
              this.draw.changeMode(constants.DRAW_MODES.NODE, opts);
              this.map.fire(DrawConstants.events.MODE_CHANGE, {
                mode: constants.DRAW_MODES.NODE,
                featureType: opts.type
              });
              document.querySelector('canvas').focus();
              this.setCurrentDrawMode(drawMode);
              return;
            }
            case constants.DRAW_MODES.STAIRS: {
              this.map.getCanvas().style.cursor = 'crosshair';
              let stairsDirection = constants.STAIRS_TYPES.NOTSET;

              if (this.addStairsStartedOnLevelId) {
                const fromStairsLevelIndex = this.levels.findIndex(
                  (level) => level.id === this.addStairsStartedOnLevelId
                );
                const toStairsLevelIndex = this.levels.findIndex(
                  (level) => level.id === this.editedLevel.id
                );

                stairsDirection =
                  fromStairsLevelIndex > toStairsLevelIndex
                    ? constants.STAIRS_TYPES.DOWNWARDS
                    : constants.STAIRS_TYPES.UPWARDS;
              }

              opts = {
                ...opts,
                type: constants.FEATURE_TYPES.JUNCTION,
                junctionType: constants.JUNCTION_TYPES.STAIRS,
                direction: stairsDirection
              };
              this.draw.changeMode(constants.DRAW_MODES.NODE, opts);
              document.querySelector('canvas').focus();
              this.setCurrentDrawMode(drawMode);
              return;
            }
            case constants.DRAW_MODES.ELEVATOR: {
              this.map.getCanvas().style.cursor = 'crosshair';
              opts = {
                ...opts,
                type: constants.FEATURE_TYPES.JUNCTION,
                junctionType: constants.JUNCTION_TYPES.ELEVATOR
              };
              this.draw.changeMode(constants.DRAW_MODES.NODE, opts);
              this.map.fire(DrawConstants.events.MODE_CHANGE, {
                mode: constants.DRAW_MODES.NODE,
                featureType: opts.type
              });
              document.querySelector('canvas').focus();
              this.setCurrentDrawMode(drawMode);
              return;
            }
          }
          break;
        }
        case constants.MAIN_MODES.ZONE: {
          // only zone mode is available
          if (drawMode === constants.DRAW_MODES.ZONE) {
            this.map.getCanvas().style.cursor = 'crosshair';
            opts = { ...opts, type: constants.FEATURE_TYPES.ZONE };
          } else {
            return;
          }
        }
      }

      this.draw.changeMode(drawMode, opts);
      this.map.fire(DrawConstants.events.MODE_CHANGE, {
        mode: drawMode,
        featureType: opts.type
      });
      this.setCurrentDrawMode(drawMode);
    },

    setupLayers() {
      // TODO handle custom images
      const extrusionLayersAlreadySet = [];
      this.map.getStyle().layers.forEach((layer) => {
        if (layer.id.split('-')[0] === 'static') {
          if (this.map.getLayer(layer.id)) {
            const featureName = layer.id.split('-')[1];
            if (constants.FEATURE_GROUPS[this.currentMainMode].HIDDEN.includes(featureName)) {
              this.map.setLayoutProperty(layer.id, 'visibility', 'none');
            } else if (
              constants.FEATURE_GROUPS[this.currentMainMode].BRIGHT.includes(featureName) ||
              constants.FEATURE_GROUPS[this.currentMainMode].DRAW.includes(featureName)
            ) {
              let visibility = 'visible';
              if (constants.EXTRUSION_FEATURES.includes(featureName)) {
                // ^ is the bitwise XOR operator, it does work for booleans
                visibility =
                  (layer.type !== 'fill-extrusion') ^ this.extrusion ? 'visible' : 'none';
              }
              this.map.setLayoutProperty(layer.id, 'visibility', visibility);

              if (visibility === 'visible') {
                const opacities = getOpacityValues(layer, {
                  bright: true
                });
                if (opacities) {
                  for (const opacity of opacities) {
                    this.map.setPaintProperty(layer.id, ...opacity);
                  }
                }
              }
            } else if (constants.FEATURE_GROUPS[this.currentMainMode].LIGHT.includes(featureName)) {
              let visibility = 'visible';
              if (constants.EXTRUSION_FEATURES.includes(featureName)) {
                // ^ is the bitwise XOR operator, it does work for booleans
                visibility =
                  (layer.type !== 'fill-extrusion') ^ this.extrusion ? 'visible' : 'none';
              }
              this.map.setLayoutProperty(layer.id, 'visibility', visibility);

              if (visibility === 'visible') {
                const opacities = getOpacityValues(layer, {
                  bright: false
                });
                if (opacities) {
                  for (const opacity of opacities) {
                    this.map.setPaintProperty(layer.id, ...opacity);
                  }
                }
              }
            }
            if (layer.type === 'fill-extrusion') {
              extrusionLayersAlreadySet.push(layer.id);
            }
          }
        }
        if (
          this.layersVisibleInExtrusion.includes(layer.id) &&
          !extrusionLayersAlreadySet.includes(layer.id)
        ) {
          this.map.setLayoutProperty(layer.id, 'visibility', this.extrusion ? 'visible' : 'none');
          if (this.extrusion) {
            const opacities = getOpacityValues(layer, {
              bright: true
            });
            if (opacities) {
              for (const opacity of opacities) {
                this.map.setPaintProperty(layer.id, ...opacity);
              }
            }
          }
        }
      });
      if (this.addStairsStartedOnLevelId) {
        this.switchDrawMode(constants.DRAW_MODES.STAIRS);
        return;
      } else if (this.currentMainMode === constants.MAIN_MODES.LEVEL && this.editImageOnSetup) {
        this.changeEditImageOnSetup(false);
        this.switchDrawMode(constants.DRAW_MODES.IMAGE);
        return;
      }

      this.switchDrawMode(constants.DRAW_MODES.DEFAULT);
    },

    fitFeaturesBounds() {
      const bbox = getBBoxOfAll();
      if (bbox) {
        this.map.fitBounds(bbox, { padding: 50, duration: 500 });
      }
    },

    async save() {
      if (this.imageSizeSumOk) {
        this.switchDrawMode(constants.DRAW_MODES.DEFAULT);
        await this.saveSite();
      }
    },

    async cancel() {
      this.setSkipApplyStyle(true);
      await this.editSite({ siteId: this.$route.params.siteId, mainMode: this.currentMainMode });
    },

    getIntersectedFeatures(mode) {
      let features, changed;
      let i, j;
      do {
        switch (mode) {
          case constants.FEATURES_TO_SAVE.DRAW: {
            features = this.draw.getAll().features;
            break;
          }
        }
        changed = false;
        i = 0;
        while (i < features.length - 1 && !changed) {
          if (isEdgeOrNetworkSegment(features[i])) {
            j = i + 1;
            while (j < features.length && !changed) {
              if (
                features[i].properties._type === features[j].properties._type &&
                !features[j].properties.guideline &&
                !features[j].properties.measure_guides
              ) {
                const intersection = turfLineIntersect(features[i], features[j]);
                if (intersection.features && intersection.features.length === 1) {
                  let lineOne = features[i];
                  let lineTwo = features[j];
                  if (haveCommonEndpoint(lineOne, lineTwo) || tIntersection(lineOne, lineTwo)) {
                    //lines have common endpoint
                    //TODO: maybe we should check if node exists,
                    //but in theory there cannot be any problems
                  } else {
                    //create new node/junction
                    let newPoint = intersection.features[0];
                    newPoint.properties.levelId = lineOne.properties.levelId;
                    const lineType = lineOne.properties._type;
                    if (lineType === constants.FEATURE_TYPES.EDGE) {
                      newPoint.properties._type = constants.FEATURE_TYPES.NODE;
                    } else if (lineType === constants.FEATURE_TYPES.NETWORK_SEGMENT) {
                      newPoint.properties._type = constants.FEATURE_TYPES.JUNCTION;
                      newPoint.properties.type = constants.JUNCTION_TYPES.NORMAL;
                    }

                    let newPointCopy = { ...newPoint };
                    newPointCopy.geometry = { ...newPoint.geometry };
                    newPointCopy.geometry.coordinates = [...newPoint.geometry.coordinates];
                    let newPointId = this.draw.add(newPointCopy)[0];
                    newPoint.id = newPointId;

                    //create new edges/networksegments
                    let lineOneParts = splitLine(lineOne, newPoint);
                    let lineTwoParts = splitLine(lineTwo, newPoint);

                    let lineOneA = lineOneParts[0];
                    let lineOneB = lineOneParts[1];
                    let lineTwoA = lineTwoParts[0];
                    let lineTwoB = lineTwoParts[1];

                    this.draw.add({
                      type: 'FeatureCollection',
                      features: [lineOneA, lineOneB, lineTwoA, lineTwoB]
                    });
                    switch (mode) {
                      case constants.FEATURES_TO_SAVE.DRAW: {
                        this.draw.delete([lineOne.id, lineTwo.id]);
                        changed = true;
                        break;
                      }
                    }
                  }
                } else if (intersection.length > 1) {
                  break;
                }
              }
              j++;
            }
          }
          i++;
        }
      } while (changed);
      return features;
    },

    async mapLoaded(map) {
      this.map = map;

      //add source and layers for static features
      this.map.addSource('staticSource', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      const staticLayers = generateStaticLayers();
      for (const staticLayer of staticLayers) {
        this.map.addLayer(staticLayer);
      }

      this.map.addSource('wall-extrusion', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      const extrusionVisibility = this.extrusion ? 'visible' : 'none';

      this.map.addLayer({
        id: 'static-edge-wall-extrusion',
        source: 'wall-extrusion',
        type: 'fill-extrusion',
        layout: {
          visibility: extrusionVisibility
        },
        paint: {
          'fill-extrusion-color':
            constants.STYLES.EDGE_STYLES.LINE_COLOR[constants.EDGE_TYPES.WALL],
          'fill-extrusion-base': ['get', 'extrusion_base'],
          'fill-extrusion-height': ['get', 'extrusion_height'],
          'fill-extrusion-opacity': constants.STYLES.EXTRUSION.WALL.OPACITY.BRIGHT
        }
      });

      const drawModes = {
        ...MapboxDraw.modes,
        simple_select: SimpleSelectModeOverride,
        direct_select: DirectSelectModeOverride
      };

      drawModes[constants.DRAW_MODES.NODE] = DrawNodeMode;
      drawModes[constants.DRAW_MODES.EDGE] = DrawEdgeMode;
      drawModes[constants.DRAW_MODES.ELLIPSE] = DrawEllipseMode;
      drawModes[constants.DRAW_MODES.RECTANGLE] = DrawRectangleMode;
      drawModes[constants.DRAW_MODES.POLYGON] = DrawPolygonMode;
      drawModes[constants.DRAW_MODES.POI] = DrawPoiMode;
      drawModes[constants.DRAW_MODES.ANNOTATION] = DrawAnnotationMode;
      drawModes[constants.DRAW_MODES.REFERENCE_NODE] = DrawReferenceNodeMode;
      drawModes[constants.DRAW_MODES.ZONE] = DrawZoneMode;
      drawModes[constants.DRAW_MODES.ROTATE] = RotateMode;
      drawModes[constants.DRAW_MODES.STATIC] = StaticMode;

      this.draw = new MapboxDraw({
        modes: drawModes,
        displayControlsDefault: false,
        keybindings: true,
        controls: {},
        userProperties: true,
        styles: [...additionalDrawStyles]
      });

      this.map.addControl(this.draw, 'top-left');

      // wait for the draw to add its styles to map
      let counter = 0;
      let warnLogged = false;
      while (
        !this.map
          .getStyle()
          .layers.some((layer) => layer.id === 'gl-draw-polygon-fill-inactive.hot')
      ) {
        await wait(10);
        counter++;
        if (counter % 500 === 0 && !warnLogged) {
          // eslint-disable-next-line no-console
          console.warn('Some important layers from map draw style might be missing.');
          warnLogged = true;
        }
      }

      this.map.on('draw.actionable', () => {
        if (!this.layersCreated && this.map.getSource('mapbox-gl-draw-hot')) {
          const postfixes = ['hot', 'cold'];

          for (let facilityType of Object.values(constants.FACILITY_TYPES)) {
            for (let postfix of postfixes) {
              this.map.addLayer({
                id: `gl-draw-extrusion-${facilityType}-active.${postfix}`,
                type: 'fill-extrusion',
                source: 'mapbox-gl-draw-' + postfix,
                filter: [
                  'all',
                  ['==', 'active', 'true'],
                  ['==', '$type', 'Polygon'],
                  ['==', 'user_type', facilityType]
                ],
                layout: {
                  visibility: extrusionVisibility
                },
                paint: {
                  'fill-extrusion-color': constants.STYLES.EXTRUSION.FACILITY.COLOR.ACTIVE,
                  'fill-extrusion-base': ['get', 'user_extrusion_base'],
                  'fill-extrusion-height': ['get', 'user_extrusion_height'],
                  'fill-extrusion-opacity': constants.STYLES.EXTRUSION.FACILITY.OPACITY.BRIGHT
                }
              });

              this.map.addLayer({
                id: `gl-draw-extrusion-${facilityType}-inactive.${postfix}`,
                type: 'fill-extrusion',
                source: 'mapbox-gl-draw-' + postfix,
                filter: [
                  'all',
                  ['==', 'active', 'false'],
                  ['==', '$type', 'Polygon'],
                  ['==', 'user_type', facilityType]
                ],
                layout: {
                  visibility: extrusionVisibility
                },
                paint: {
                  'fill-extrusion-color': constants.STYLES.EXTRUSION.FACILITY.COLOR.INACTIVE,
                  'fill-extrusion-base': ['get', 'user_extrusion_base'],
                  'fill-extrusion-height': ['get', 'user_extrusion_height'],
                  'fill-extrusion-opacity': constants.STYLES.EXTRUSION.FACILITY.OPACITY.BRIGHT
                }
              });
            }
          }

          this.layersCreated = true;

          let url = process.env.BASE_URL + 'images/arrow.png';
          let self = this;

          this.map.loadImage(url, function(err, image) {
            if (err) {
              return;
            }
            self.map.addImage('arrow', image);

            const postfixes = ['hot', 'cold'];

            for (let postfix of postfixes) {
              const layerUpId = 'gl-draw-arrow-up.' + postfix;
              const layerDownId = 'gl-draw-arrow-down.' + postfix;

              self.map.addLayer({
                id: layerUpId,
                type: 'symbol',
                source: 'mapbox-gl-draw-' + postfix,
                filter: [
                  'all',
                  ['==', '$type', 'LineString'],
                  ['any', ['==', 'user_pathClass', 'stairs_up']]
                ],
                layout: {
                  'symbol-placement': 'line',
                  'symbol-spacing': 27,
                  'icon-allow-overlap': true,
                  'icon-ignore-placement': true,
                  'icon-image': 'arrow',
                  'icon-size': 0.5,
                  'icon-rotate': 90,
                  visibility: 'visible'
                }
              });

              self.map.addLayer({
                id: layerDownId,
                type: 'symbol',
                source: 'mapbox-gl-draw-' + postfix,
                filter: [
                  'all',
                  ['==', '$type', 'LineString'],
                  ['any', ['==', 'user_pathClass', 'stairs_down']]
                ],
                layout: {
                  'symbol-placement': 'line',
                  'symbol-spacing': 27,
                  'icon-allow-overlap': true,
                  'icon-ignore-placement': true,
                  'icon-image': 'arrow',
                  'icon-size': 0.5,
                  'icon-rotate': -90,
                  visibility: 'visible'
                }
              });
            }
          });
        }
      });

      const getDraw = () => {
        // draw.getSelected returns empty while drawing
        if (
          this.draw.getMode() !== constants.DRAW_MODES.ELLIPSE &&
          this.draw.getMode() !== constants.DRAW_MODES.RECTANGLE
        ) {
          this.all = { ...this.draw.getAll() };
          this.selected = { ...this.draw.getSelected() };
        }
      };

      this.map.on('draw.create', (e) => {
        getDraw();

        // why nextTick?
        // EdgeTypeSelector also subscribes to draw.create and that code have to run before this,
        // since when intersection happens then the following code removes those edges
        // on which EdgeTypeSelector wants to set properties
        this.$nextTick(() => {
          if (!e.skipHistory) {
            let operation;

            switch (this.currentMainMode) {
              case constants.MAIN_MODES.FLOORPLAN: {
                switch (e.features[0].properties._type) {
                  case constants.FEATURE_TYPES.NODE: {
                    operation = constants.OPERATIONS.FLOORPLAN.ADD_NODE;
                    break;
                  }
                  case constants.FEATURE_TYPES.EDGE: {
                    operation = constants.OPERATIONS.FLOORPLAN.ADD_EDGE;
                    break;
                  }
                  case constants.FEATURE_TYPES.FACILITY: {
                    switch (e.features[0].properties.type) {
                      case constants.FACILITY_TYPES.RECTANGLE: {
                        operation = constants.OPERATIONS.FLOORPLAN.ADD_RECTANGLE;
                        break;
                      }
                      case constants.FACILITY_TYPES.ELLIPSE: {
                        operation = constants.OPERATIONS.FLOORPLAN.ADD_ELLIPSE;
                        break;
                      }
                      case constants.FACILITY_TYPES.POLYGON: {
                        operation = constants.OPERATIONS.FLOORPLAN.ADD_POLYGON;
                        break;
                      }
                    }
                    break;
                  }
                }
                break;
              }
              case constants.MAIN_MODES.NETWORK: {
                switch (e.features[0].properties._type) {
                  case constants.FEATURE_TYPES.JUNCTION: {
                    switch (e.features[0].properties.type) {
                      case constants.JUNCTION_TYPES.ENTRY_POINT: {
                        operation = constants.OPERATIONS.NETWORK.ADD_ENTRY_POINT;
                        break;
                      }
                      case constants.JUNCTION_TYPES.ELEVATOR: {
                        operation = constants.OPERATIONS.NETWORK.ADD_ELEVATOR;
                        break;
                      }
                      case constants.JUNCTION_TYPES.STAIRS: {
                        operation = constants.OPERATIONS.NETWORK.ADD_STAIRS;
                        break;
                      }
                      case constants.JUNCTION_TYPES.NORMAL: {
                        operation = constants.OPERATIONS.NETWORK.ADD_JUNCTION;
                        break;
                      }
                    }
                    break;
                  }

                  case constants.FEATURE_TYPES.NETWORK_SEGMENT: {
                    operation = constants.OPERATIONS.NETWORK.ADD_NETWORK_SEGMENT;
                    break;
                  }
                }
                break;
              }
              case constants.MAIN_MODES.ZONE: {
                operation = constants.OPERATIONS.ZONE.ADD;
                break;
              }
            }

            const isEntryPoint =
              e.features[0].properties.type === constants.JUNCTION_TYPES.ENTRY_POINT;
            const isStairs = e.features[0].properties.type === constants.JUNCTION_TYPES.STAIRS;
            const isElevator = e.features[0].properties.type === constants.JUNCTION_TYPES.ELEVATOR;
            const isReferenceNode =
              e.features[0].properties._type === constants.FEATURE_TYPES.REFERENCE_NODE;
            const isPoi = e.features[0].properties._type === constants.FEATURE_TYPES.POI;
            const isAnnotation =
              e.features[0].properties._type === constants.FEATURE_TYPES.ANNOTATION;

            let skipHistory = false;
            if (isStairs || isEntryPoint) {
              skipHistory = true;

              if (this.addStairsStartedOnLevelId === e.features[0].properties.levelId) {
                this.draw.delete(e.features[0].id);
                return;
              }
            } else if (isElevator) {
              skipHistory = true;
            }

            if (isReferenceNode) {
              this.addNewReferenceNode({ feature: e.features[0] });
              return;
            }

            if (isPoi || isAnnotation) {
              return;
            }

            this.updateFeatures(false, operation, skipHistory);

            if (isStairs) {
              this.addStairs({ feature: e.features[0] });
            }

            if (isElevator) {
              this.addElevator({ feature: e.features[0] });
              this.switchDrawMode(constants.DRAW_MODES.SIMPLE_SELECT);
            }

            if (isEntryPoint) {
              this.setEntryPointTransition(e.features[0]);
            }
          }
        });
      });

      this.map.on(constants.CUSTOM_DRAW_EVENTS.MODIFICATION, () => {
        if (this.lastMoveWasSuccessful) {
          let operation;
          switch (this.currentMainMode) {
            case constants.MAIN_MODES.FLOORPLAN: {
              operation = constants.OPERATIONS.FLOORPLAN.MODIFY;
              break;
            }
            case constants.MAIN_MODES.NETWORK: {
              operation = constants.OPERATIONS.NETWORK.MODIFY;
              break;
            }
            case constants.MAIN_MODES.ZONE: {
              operation = constants.OPERATIONS.ZONE.MODIFY;
            }
          }
          this.updateFeatures(false, operation);
        } else {
          this.setElevatorMoveResult(true);
        }
      });

      this.map.on(constants.CUSTOM_DRAW_EVENTS.ROTATION, () => {
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.ROTATION);
      });

      await this.map.on('draw.render', async () => {
        getDraw();
        this.setWallExtrusion();
      });

      this.map.on('draw.modechange', ({ mode }) => {
        this.updateStatusBarEdgeProps(undefined);

        if (mode !== constants.DRAW_MODES.ROTATE) {
          this.rotate = false;
        }
        if (mode === constants.DRAW_MODES.SIMPLE_SELECT) {
          this.map.getCanvas().style.cursor = 'default';
        }

        this.setCurrentDrawMode(mode);
      });

      this.map.on('draw.selectionchange', () => {
        if (this.draw.getMode() === constants.DRAW_MODES.SIMPLE_SELECT) {
          const updated = this.draw.getAll();
          this.draw.set(updated);
        }
        if (
          this.currentMainMode === constants.MAIN_MODES.NETWORK &&
          this.currentDrawMode === constants.DRAW_MODES.SIMPLE_SELECT
        ) {
          const selected = this.draw.getSelected();
          if (
            selected?.features.length === 1 &&
            selected.features[0].properties._type === constants.FEATURE_TYPES.JUNCTION &&
            selected.features[0].properties.type === constants.JUNCTION_TYPES.ENTRY_POINT
          ) {
            this.editEntryPoint({ feature: selected.features[0] });
          }
          let onlyNetworkSegment = false;
          if (selected.features && selected.features.length > 0) {
            onlyNetworkSegment = true;
          }
          for (let feature of selected.features) {
            if (feature.properties._type !== constants.FEATURE_TYPES.NETWORK_SEGMENT) {
              onlyNetworkSegment = false;
            }
          }
          if (onlyNetworkSegment) {
            let selectedIds = [];
            for (let feature of selected.features) {
              selectedIds.push(feature.id);
            }
            this.updateSNS(selectedIds);
            return;
          }
        } else if (
          this.currentMainMode === constants.MAIN_MODES.ZONE &&
          (this.currentDrawMode === constants.DRAW_MODES.SIMPLE_SELECT ||
            this.currentDrawMode === constants.DRAW_MODES.DIRECT_SELECT)
        ) {
          const selected = this.draw.getSelected();
          if (
            selected?.features?.length === 1 &&
            selected.features[0].properties._type === constants.FEATURE_TYPES.ZONE
          ) {
            this[ZoneActions.EDIT](selected.features[0]);
          }
        }
        this.updateSNS([]);
      });

      await this.map.on('draw.delete', async (e) => {
        this.updateSNS([]);
        if (e.features[0].properties._type === constants.FEATURE_TYPES.HIGHLIGHT_POINT) {
          return;
        }
        this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.DELETE);
      });

      this.map.on(constants.CUSTOM_DRAW_EVENTS.EDGE_DRAWING, (e) => {
        if (e.isDrawing) {
          this.updateStatusBarEdgeProps({ length: e.length, minAngle: e.minAngle });
        } else {
          this.updateStatusBarEdgeProps(undefined);
        }
      });

      this.map.on(constants.CUSTOM_DRAW_EVENTS.SNAPPING, (e) => {
        this.updateStatusBarSnappingCoords(e.coordinates);
      });

      this.map.on(constants.CUSTOM_DRAW_EVENTS.SPECIAL_JUNCTION_OVERWRITING, (e) => {
        this.error(i18n.t(e.message));
      });

      this.map.on('mouseout', () => {
        this.updateStatusBarMouseCoords(undefined);
        this.updateStatusBarSnappingCoords(undefined);
      });

      this.map.on('mousemove', (e) => {
        this.updateStatusBarMouseCoords([e.lngLat.lng, e.lngLat.lat]);
        if (this.currentDrawMode === constants.DRAW_MODES.EDGE) {
          let featuresHere = this.draw.getFeatureIdsAt(e.point);
          if (featuresHere.length > 0) {
            for (let i = 0; i < featuresHere.length; i++) {
              let feature = this.draw.get(featuresHere[i]);
              if (feature && feature.geometry.type === 'Point') {
                this.map.getCanvas().style.cursor = 'pointer';
                return;
              }
            }
          }
          this.map.getCanvas().style.cursor = 'crosshair';
        }
      });

      this.map.getCanvas().addEventListener(
        'keydown',
        (e) => {
          if (e.which === 27) {
            if (this.addStairsStartedOnLevelId) {
              this.loadCurrentFromHistory();
            }
            if (
              ![
                constants.DRAW_MODES.NODE,
                constants.DRAW_MODES.EDGE,
                constants.DRAW_MODES.REFERENCE_NODE
              ].includes(this.currentDrawMode)
            ) {
              this.switchDrawMode(constants.DRAW_MODES.DEFAULT);
            }
            return;
          }
        },
        true
      );

      this.map.getCanvas().addEventListener(
        'keyup',
        (e) => {
          if (
            this.currentDrawMode === constants.DRAW_MODES.SIMPLE_SELECT ||
            this.currentDrawMode === constants.DRAW_MODES.CUSTOM_IMAGE
          ) {
            e.preventDefault();
            if (e.which === 8 || e.which === 46) {
              this.deleteSelected();
            }
          }
        },
        true
      );

      this.mapLoadedVuex();

      const siteId = this.$route.params.siteId;

      if (siteId) {
        await this.editSite({ siteId });
        this.fitFeaturesBounds();
        this.switchDrawMode(constants.DRAW_MODES.DEFAULT);
      }
    },

    duplicateSelectedFacility() {
      if (!this.isOneCustomImageSelected) {
        const originalFeature = this.draw.getSelected().features[0];
        let newFeature = turfClone(originalFeature);
        delete newFeature.id;
        delete newFeature.properties.id;
        if (newFeature.properties.alreadyCreated) {
          delete newFeature.properties.alreadyCreated;
        }

        let oldPointCoords;
        if (this.isOneEllipseSelected) {
          oldPointCoords = [...originalFeature.properties.center];
        }
        if (this.isOneRectangleSelected || this.isOnePolygonSelected) {
          oldPointCoords = [...originalFeature.geometry.coordinates[0][0]];
        }
        if (this.isOneRectangleSelected) {
          oldPointCoords = [...originalFeature.geometry.coordinates[0][0]];
        }
        if (this.isOnePoiSelected || this.isOneAnnotationSelected) {
          oldPointCoords = [...originalFeature.geometry.coordinates];
        }
        const oldPointPixels = this.map.project(oldPointCoords);
        const newPointPixels = { x: oldPointPixels.x + 150, y: oldPointPixels.y + 150 };
        const newPoint = this.map.unproject(newPointPixels);
        const newPointCoords = [newPoint.lng, newPoint.lat];
        if (this.isOneEllipseSelected) {
          newFeature.properties.center = [...newPointCoords];
        }
        const translation = turfDistance(oldPointCoords, newPointCoords);

        newFeature = turfTranslate(newFeature, translation, this.map.getBearing() + 135);
        const newIds = this.draw.add(newFeature);
        newFeature.id = newIds[0];
        this.draw.changeMode(constants.DRAW_MODES.SIMPLE_SELECT, { featureIds: newIds });
        this.map.fire(DrawConstants.events.SELECTION_CHANGE, {
          features: [newFeature]
        });
      } else {
        let newCoords = [];
        for (let coords of this.selectedImageMeta.coordinates) {
          const oldPointPixels = this.map.project(coords);
          const newPointPixels = { x: oldPointPixels.x + 150, y: oldPointPixels.y + 150 };
          const newPoint = this.map.unproject(newPointPixels);
          const newPointCoords = [newPoint.lng, newPoint.lat];
          newCoords.push(newPointCoords);
        }
        this.duplicateCustomImage(newCoords);
      }
      this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.DUPLICATE);
    },

    changePathClass(actionType) {
      let segments = this.draw.getSelected().features;
      let operation = constants.OPERATIONS.NETWORK.MODIFY;
      switch (actionType) {
        case constants.PATH_CLASS_ACTION_TYPES.STAIRS: {
          for (let segment of segments) {
            this.draw.setFeatureProperty(
              segment.id,
              'pathClass',
              this.onlyStairsSelected
                ? constants.PATH_CLASSES.NORMAL
                : constants.PATH_CLASSES.STAIRS_UP
            );
          }
          this.onlyStairsSelected = !this.onlyStairsSelected;
          operation = constants.OPERATIONS.NETWORK.MODIFY_PATH_CLASS;
          break;
        }
        case constants.PATH_CLASS_ACTION_TYPES.DIRECTION: {
          for (let segment of segments) {
            this.draw.setFeatureProperty(
              segment.id,
              'pathClass',
              segment.properties.pathClass === constants.PATH_CLASSES.STAIRS_UP
                ? constants.PATH_CLASSES.STAIRS_DOWN
                : constants.PATH_CLASSES.STAIRS_UP
            );
          }
          operation = constants.OPERATIONS.NETWORK.MODIFY_STAIRS_DIRECTION;
          break;
        }
      }
      this.updateFeatures(false, operation);
      this.draw.set(this.draw.getAll());
    },

    getRectangleBearing(geometry) {
      const bearing = turfBearing(geometry.coordinates[0][2], geometry.coordinates[0][1]);
      return Math.round(bearing);
    },

    getPolygonBearing(geometry) {
      const bearing = turfBearing(geometry.coordinates[0][2], geometry.coordinates[0][1]);
      return Math.round(bearing);
    },

    getRectangleLength(geometry) {
      const distance = turfDistance(geometry.coordinates[0][0], geometry.coordinates[0][1]);
      return distance;
    },

    getRectangleWidth(geometry) {
      const distance = turfDistance(geometry.coordinates[0][1], geometry.coordinates[0][2]);
      return distance;
    },

    getHelper(propName) {
      if (this.isOneEllipseSelected) {
        return this.selected.features[0].properties[propName];
      } else if (this.isOneRectangleSelected) {
        if (propName === 'bearing') {
          return this.getRectangleBearing(this.selected.features[0].geometry);
        }

        if (propName === 'width') {
          return this.getRectangleWidth(this.selected.features[0].geometry);
        }

        if (propName === 'length') {
          return this.getRectangleLength(this.selected.features[0].geometry);
        }
      } else if (this.isOneFacilitySelected) {
        if (propName === 'bearing') {
          return this.getPolygonBearing(this.selected.features[0].geometry);
        }
      }

      return '';
    },

    setHelper(propName, value) {
      let selected = this.draw.getSelected();

      selected.features.forEach((feature) => {
        if (feature.properties.type === constants.FACILITY_TYPES.RECTANGLE) {
          if (propName === 'bearing') {
            const currentBearing = this.getRectangleBearing(feature.geometry);
            const center = turfCentroid(feature);

            const newCoords = [];

            feature.geometry.coordinates[0].forEach((coords) => {
              let distanceFromCenter = turfDistance(center, coords);
              let bearingFromCenter = turfBearing(center, coords);

              let newPoint = turfDestination(
                center,
                distanceFromCenter,
                bearingFromCenter + value - currentBearing
              );
              newCoords.push(newPoint.geometry.coordinates);
            });

            feature.geometry.coordinates[0] = newCoords;

            // overwrites it if added with the same id
            this.draw.add(feature);

            return;
          }

          if (propName === 'length') {
            const coords = feature.geometry.coordinates[0];
            const bearingLength = turfBearing(coords[0], coords[1]);

            if (this.keepFacilityAspectRatio) {
              const previousLength = turfDistance(coords[0], coords[1]);
              const previousWidth = turfDistance(coords[0], coords[3]);
              const proportion = value / previousLength;
              const bearingWidth = turfBearing(coords[0], coords[3]);

              coords[3] = turfDestination(
                coords[0],
                previousWidth * proportion,
                bearingWidth
              ).geometry.coordinates;
            }
            coords[1] = turfDestination(coords[0], value, bearingLength).geometry.coordinates;
            coords[2] = turfDestination(coords[3], value, bearingLength).geometry.coordinates;

            // overwrites it if added with the same id
            this.draw.add(feature);

            return;
          }

          if (propName === 'width') {
            const coords = feature.geometry.coordinates[0];
            const bearingWidth = turfBearing(coords[1], coords[2]);

            if (this.keepFacilityAspectRatio) {
              const previousWidth = turfDistance(coords[0], coords[3]);
              const previousLength = turfDistance(coords[0], coords[1]);
              const proportion = value / previousWidth;
              const bearingLength = turfBearing(coords[0], coords[1]);

              coords[1] = turfDestination(
                coords[0],
                previousLength * proportion,
                bearingLength
              ).geometry.coordinates;
            }

            coords[2] = turfDestination(coords[1], value, bearingWidth).geometry.coordinates;
            coords[3] = turfDestination(coords[0], value, bearingWidth).geometry.coordinates;

            // overwrites it if added with the same id
            this.draw.add(feature);

            return;
          }
        } else if (feature.properties.type === constants.FACILITY_TYPES.POLYGON) {
          if (propName === 'bearing') {
            const currentBearing = this.getPolygonBearing(feature.geometry);
            const center = turfCentroid(feature);

            const newCoords = [];

            feature.geometry.coordinates[0].forEach((coords) => {
              let distanceFromCenter = turfDistance(center, coords);
              let bearingFromCenter = turfBearing(center, coords);

              let newPoint = turfDestination(
                center,
                distanceFromCenter,
                bearingFromCenter + value - currentBearing
              );
              newCoords.push(newPoint.geometry.coordinates);
            });

            feature.geometry.coordinates[0] = newCoords;

            // overwrites it if added with the same id
            this.draw.add(feature);

            return;
          }
        } else {
          feature.properties[propName] = value;

          if (this.keepFacilityAspectRatio) {
            const { eccentricity } = feature.properties;
            if (propName === 'semiMajorAxis') {
              feature.properties.semiMinorAxis = value * Math.sqrt(1 - eccentricity ** 2);
            } else if (propName === 'semiMinorAxis') {
              feature.properties.semiMajorAxis = value / Math.sqrt(1 - eccentricity ** 2);
            }
          } else {
            const editedAxis = value;
            let otherAxis;

            if (propName === 'semiMajorAxis') {
              otherAxis = Number(feature.properties.semiMinorAxis);
            } else if (propName === 'semiMinorAxis') {
              otherAxis = Number(feature.properties.semiMajorAxis);
            }

            const shorterAxis = Math.min(editedAxis, otherAxis);
            const longerAxis = Math.max(editedAxis, otherAxis);

            feature.properties.eccentricity = Math.sqrt(1 - (shorterAxis / longerAxis) ** 2);
          }

          let { center, semiMinorAxis, semiMajorAxis, steps, bearing } = feature.properties;

          const ellipse = turfEllipse(center, semiMinorAxis, semiMajorAxis, {
            angle: bearing,
            steps: steps
          });

          feature.geometry.coordinates = ellipse.geometry.coordinates;

          // overwrites it if added with the same id
          this.draw.add(feature);
        }
      });
    },

    setWallExtrusion() {
      if (this.extrusion) {
        let features = [];
        if (this.currentMainMode === constants.MAIN_MODES.FLOORPLAN) {
          features = this.draw.getAll().features;
        } else {
          features = this.currentFeatures.map((item) => ({ id: item.id, ...item.feature }));
        }

        const polygons = createAllWallPolygons(features);
        this.map.getSource('wall-extrusion').setData(polygons);
      }
    },

    async deleteSelected() {
      if (this.currentDrawMode === constants.DRAW_MODES.SIMPLE_SELECT) {
        const answer = await confirm(
          i18n.t('Are you sure you want to delete the selected item(s)?'),
          {
            title: i18n.t('Delete item(s)'),
            buttonTrueText: i18n.t('Delete'),
            buttonFalseText: i18n.t('Cancel')
          }
        );

        if (answer) {
          this.draw.trash();
        }
      } else if (this.currentDrawMode === constants.DRAW_MODES.CUSTOM_IMAGE) {
        this.deleteCustomImage();
      }
    },

    updateLevelImageLayer({ removeSource } = {}) {
      // source is removed to prevent flickering between level changes
      if (removeSource) {
        if (this.map.getLayer('level-image')) {
          this.map.removeLayer('level-image');
        }

        if (this.map.getSource('level-image')) {
          this.map.removeSource('level-image');
        }
      }

      if (this.currentImageUrl) {
        if (!this.map.getSource('level-image')) {
          this.map.addSource('level-image', {
            coordinates: this.currentImageMeta.coordinates,
            url: this.currentImageUrl,
            type: 'image'
          });
        }
        if (!this.map.getLayer('level-image')) {
          this.map.addLayer(
            {
              id: 'level-image',
              source: 'level-image',
              type: 'raster',
              paint: {
                'raster-opacity': 1 - this.imageTransparency / 100,
                'raster-fade-duration': 0
              }
            },
            this.lastBaseMapLayerId
          );
          if (this.lastBaseMapLayerId !== 'rooms') {
            this.map.moveLayer(this.lastBaseMapLayerId, 'level-image');
          }
        }
        this.isImageLayerVisible = true;
      } else {
        this.isImageLayerVisible = false;
      }
    },

    updateCustomImages(newArray, oldArray) {
      if (!this.customImageLoadedFromHistory) {
        for (const img of oldArray) {
          if (img.meta && img.meta.id) {
            if (this.map.getLayer(`custom-image-${img.meta.id}`)) {
              this.map.removeLayer(`custom-image-${img.meta.id}`);
            }

            if (this.map.getSource(`custom-image-${img.meta.id}`)) {
              this.map.removeSource(`custom-image-${img.meta.id}`);
            }
          }
        }
      }

      if (newArray && newArray.length > 0) {
        let lastLayerId = this.lastBaseMapLayerId;
        if (this.map.getLayer('level-image')) {
          lastLayerId = 'level-image';
        }
        for (const img of newArray) {
          if (!this.map.getSource(`custom-image-${img.meta.id}`)) {
            this.map.addSource(`custom-image-${img.meta.id}`, {
              coordinates: img.meta.coordinates,
              url: `data:${img.meta.mimeType}${img.meta.levelId};base64,${img.base64}`,
              type: 'image'
            });
          } else {
            this.map.getSource(`custom-image-${img.meta.id}`).setCoordinates(img.meta.coordinates);
          }

          if (!this.map.getLayer(`custom-image-${img.meta.id}`)) {
            this.map.addLayer(
              {
                id: `custom-image-${img.meta.id}`,
                source: `custom-image-${img.meta.id}`,
                type: 'raster',
                paint: {
                  'raster-fade-duration': 0
                }
              },
              lastLayerId
            );
            this.map.moveLayer(lastLayerId, `custom-image-${img.meta.id}`);
            lastLayerId = `custom-image-${img.meta.id}`;
          }
        }
      }
      this.clearCustomImageLoadedFromHistory();
    },

    cancelElevator(feature) {
      this.draw.delete(feature.id);
      this.updateFeatures(false, constants.OPERATIONS.FLOORPLAN.DELETE, true);
    },

    siteNameChanged(newName) {
      this.editSiteMutation({
        id: this.editedSite.id,
        name: newName,
        organizationId: this.editedSite.organizationId
      });
      this.addToHistory(constants.OPERATIONS.SITE.RENAME);
    },

    setEntryPointTransition(feature) {
      let selected, isNew;
      if (feature) {
        selected = feature;
        isNew = true;
      } else {
        selected = this.draw.getSelected();
        if (!this.isOneEntryPointSelected) {
          return;
        }
        selected = selected.features[0];
        isNew = false;
      }
      selected.properties.networkTransition = this.networkTransition;
      selected.properties.siteTransition = this.siteTransition;
      this.draw.add(selected);
      const updated = this.draw.getAll();
      this.draw.set(updated);
      this.updateFeatures(
        false,
        isNew
          ? constants.OPERATIONS.NETWORK.ADD_ENTRY_POINT
          : constants.OPERATIONS.NETWORK.UPDATE_ENTRY_POINT
      );
    },

    async _exportLevel() {
      this.isLoading = true;

      await this.exportLevel(this.exportAs);
      this.showExportPopup = false;
      this.isLoading = false;
    },

    updateSelectedZone(e) {
      for (const change in e.changes) {
        this.draw.setFeatureProperty(e.id, change, e.changes[change]);
      }
      this.draw.set(this.draw.getAll());
    }
  },
  components: {
    GLIMap: () => import('../components/Map'),
    GliEditable,
    ElevatorDialog,
    StairsPanel,
    ContextMenu,
    LevelSelector: () => import('../components/LevelSelector'),
    LevelEditor: () => import('../components/LevelEditor'),
    PoiSearch: () => import('../components/PoiSearch'),
    RoutingPanel: () => import('../components/RoutingPanel'),
    VtrPanel,
    EdgeTypeSelector: () => import('../components/EdgeTypeSelector.vue'),
    PoiPanel: () => import('../components/PoiPanel.vue'),
    AnnotationPanel: () => import('../components/AnnotationPanel.vue'),
    CustomImagePanel: () => import('../components/CustomImagePanel.vue'),
    EntryPointPanel: () => import('../components/EntryPointPanel.vue'),
    PanelButton,
    ReferenceNodePanel,
    ZonePanel: () => import('../components/ZonePanel.vue')
  }
};
</script>
