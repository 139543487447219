const MapboxDraw = require('@mapbox/mapbox-gl-draw');
import constants from '~/shared/constants';
import createSupplementaryPoints from '@mapbox/mapbox-gl-draw/src/lib/create_supplementary_points';
import {
  isOfMetaType,
  isActiveFeature,
  isShiftDown
} from '@mapbox/mapbox-gl-draw/src/lib/common_selectors';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import moveFeatures from '@mapbox/mapbox-gl-draw/src/lib/move_features';
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import constrainFeatureMovement from '@mapbox/mapbox-gl-draw/src/lib/constrain_feature_movement';
import {
  bearing as turfBearing,
  distance as turfDistance,
  ellipse as turfEllipse,
  midpoint as turfMidpoint,
  destination as turfDestination
} from '@turf/turf';
import createSupplementaryPointsForEllipse from './createSupplementaryPointsForEllipse';
import createSupplementaryPointsForRectangle from './createSupplementaryPointsForRectangle';

const isVertex = isOfMetaType(Constants.meta.VERTEX);
const isMidpoint = isOfMetaType(Constants.meta.MIDPOINT);

const DirectSelectModeOverride = MapboxDraw.modes.direct_select;

DirectSelectModeOverride.onSetup = function(opts) {
  const featureId = opts.featureId;
  const feature = this.getFeature(featureId);

  if (!feature) {
    throw new Error('You must provide a featureId to enter direct_select mode');
  }

  if (feature.type === Constants.geojsonTypes.POINT) {
    throw new TypeError("direct_select mode doesn't handle point features");
  }

  const state = {
    featureId,
    feature,
    dragMoveLocation: opts.startPos || null,
    dragMoving: false,
    canDragMove: false,
    selectedCoordPaths: opts.coordPath ? [opts.coordPath] : []
  };

  this.setSelectedCoordinates(this.pathsToCoordinates(featureId, state.selectedCoordPaths));

  this.setSelected(featureId);
  doubleClickZoom.disable(this);

  this.setActionableState({
    trash: true
  });

  return state;
};

DirectSelectModeOverride.onMouseDown = DirectSelectModeOverride.onTouchStart = function(state, e) {
  state.coordsAtMouseDown = [...state.feature.toGeoJSON().geometry.coordinates];

  if (isVertex(e)) {
    return this.onVertex(state, e);
  }
  if (isActiveFeature(e)) {
    return this.onFeature(state, e);
  }
  if (isMidpoint(e)) {
    return this.onMidpoint(state, e);
  }
};

DirectSelectModeOverride.onTouchEnd = DirectSelectModeOverride.onMouseUp = function(state) {
  if (state.dragMoving) {
    this.fireUpdate();
    this.map.fire(constants.CUSTOM_DRAW_EVENTS.MODIFICATION);
  }
  this.stopDragging(state);
};

DirectSelectModeOverride.onMouseMove = function(state, e) {
  const isFeature = isActiveFeature(e);
  const onVertex = isVertex(e);
  const noCoords = state.selectedCoordPaths.length === 0;
  if (isFeature && noCoords) {
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  } else if (onVertex && !noCoords) {
    this.updateUIClasses({ mouse: Constants.cursors.MOVE });
  } else {
    this.updateUIClasses({ mouse: Constants.cursors.NONE });
  }
  this.stopDragging(state);

  // Skip render
  return true;
};

DirectSelectModeOverride.dragFeature = function(state, e, delta) {
  moveFeatures(this.getSelected(), delta);
  this.getSelected()
    .filter((feature) => feature.properties.type === constants.FACILITY_TYPES.ELLIPSE)
    .map((ellipse) => ellipse.properties.center)
    .forEach((center) => {
      center[0] += delta.lng;
      center[1] += delta.lat;
    });
  state.dragMoveLocation = e.lngLat;
};

DirectSelectModeOverride.dragVertex = function(state, e, delta) {
  if (state.feature.properties.type === constants.FACILITY_TYPES.ELLIPSE) {
    let { center, eccentricity, steps } = state.feature.properties;

    const bearing = turfBearing(center, [e.lngLat.lng, e.lngLat.lat]);
    const semiMajorAxis = turfDistance(center, [e.lngLat.lng, e.lngLat.lat]);
    const semiMinorAxis = semiMajorAxis * Math.sqrt(1 - eccentricity ** 2);

    state.feature.properties = {
      ...state.feature.properties,
      semiMajorAxis,
      semiMinorAxis,
      bearing
    };

    const ellipse = turfEllipse(center, semiMinorAxis, semiMajorAxis, { angle: bearing, steps });
    state.feature.incomingCoords(ellipse.geometry.coordinates);
  } else if (state.feature.properties.type === constants.FACILITY_TYPES.RECTANGLE) {
    const selectedPath = state.selectedCoordPaths[0];
    let selectedCoord = state.feature.getCoordinate(selectedPath);

    const coord00 = state.feature.getCoordinate('0.0');
    const coord01 = state.feature.getCoordinate('0.1');
    const coord02 = state.feature.getCoordinate('0.2');
    const coord03 = state.feature.getCoordinate('0.3');

    const bearing = turfBearing(coord03, coord00);

    state.feature.updateCoordinate(
      selectedPath,
      selectedCoord[0] + delta.lng,
      selectedCoord[1] + delta.lat
    );

    const getAdjacentVertexCoords = (draggedVertexCoords, oppositeVertexCords) => {
      const newMidpoint = turfMidpoint(draggedVertexCoords, oppositeVertexCords);
      const newMainDiagonalLength = turfDistance(draggedVertexCoords, oppositeVertexCords);
      const newMainDiagonalBearing = turfBearing(draggedVertexCoords, oppositeVertexCords);
      const newSubDiagonalBearing = bearing * 2 - newMainDiagonalBearing;

      return [
        turfDestination(
          newMidpoint.geometry.coordinates,
          newMainDiagonalLength / 2,
          newSubDiagonalBearing - 180
        ).geometry.coordinates,
        turfDestination(
          newMidpoint.geometry.coordinates,
          newMainDiagonalLength / 2,
          newSubDiagonalBearing
        ).geometry.coordinates
      ];
    };

    const newCoords = state.feature.getCoordinate(selectedPath);
    switch (selectedPath) {
      case '0.0': {
        const [coords1, coords2] = getAdjacentVertexCoords(newCoords, coord02);

        state.feature.updateCoordinate('0.1', ...coords1);
        state.feature.updateCoordinate('0.3', ...coords2);
        break;
      }
      case '0.1': {
        const [coords1, coords2] = getAdjacentVertexCoords(newCoords, coord03);

        state.feature.updateCoordinate('0.0', ...coords1);
        state.feature.updateCoordinate('0.2', ...coords2);
        break;
      }
      case '0.2': {
        const [coords1, coords2] = getAdjacentVertexCoords(newCoords, coord00);

        state.feature.updateCoordinate('0.3', ...coords1);
        state.feature.updateCoordinate('0.1', ...coords2);
        break;
      }
      case '0.3': {
        const [coords1, coords2] = getAdjacentVertexCoords(newCoords, coord01);

        state.feature.updateCoordinate('0.2', ...coords1);
        state.feature.updateCoordinate('0.0', ...coords2);
        break;
      }
    }
  } else if (state.feature.properties.type === constants.FACILITY_TYPES.POLYGON) {
    const selectedCoords = state.selectedCoordPaths.map((coord_path) =>
      state.feature.getCoordinate(coord_path)
    );

    const selectedCoordPoints = selectedCoords.map((coords) => ({
      type: Constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      let coord = [
        selectedCoords[i][0] + constrainedDelta.lng,
        selectedCoords[i][1] + constrainedDelta.lat
      ];

      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0], coord[1]);
    }
  } else if (state.feature.properties._type === constants.FEATURE_TYPES.ZONE) {
    const selectedCoords = state.selectedCoordPaths.map((coord_path) =>
      state.feature.getCoordinate(coord_path)
    );

    const selectedCoordPoints = selectedCoords.map((coords) => ({
      type: Constants.geojsonTypes.FEATURE,
      id: state.featureId,
      properties: {},
      geometry: {
        type: Constants.geojsonTypes.POINT,
        coordinates: coords
      }
    }));

    if (!e.originalEvent.altKey && selectedCoordPoints.length === 1) {
      if (this.snap(state, selectedCoordPoints[0], delta)) {
        return;
      }
    }

    const constrainedDelta = constrainFeatureMovement(selectedCoordPoints, delta);
    for (let i = 0; i < selectedCoords.length; i++) {
      let coord = [
        selectedCoords[i][0] + constrainedDelta.lng,
        selectedCoords[i][1] + constrainedDelta.lat
      ];

      state.feature.updateCoordinate(state.selectedCoordPaths[i], coord[0], coord[1]);
    }
  }
};

DirectSelectModeOverride.onMidpoint = function(state, e) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;
  state.feature.addCoordinate(about.coord_path, about.lng, about.lat);

  this.fireUpdate();
  state.selectedCoordPaths = [about.coord_path];
};

DirectSelectModeOverride.onVertex = function(state, e) {
  this.startDragging(state, e);
  const about = e.featureTarget.properties;

  const selectedIndex = state.selectedCoordPaths.indexOf(about.coord_path);
  if (!isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths = [about.coord_path];
  } else if (isShiftDown(e) && selectedIndex === -1) {
    state.selectedCoordPaths.push(about.coord_path);
  }

  const selectedCoordinates = this.pathsToCoordinates(state.featureId, state.selectedCoordPaths);
  this.setSelectedCoordinates(selectedCoordinates);
};

DirectSelectModeOverride.onKeyUp = function(state, e) {
  if (e.keyCode === 27) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT);
  }
};

DirectSelectModeOverride.onTrash = function(state) {
  if (state.feature.properties._type === constants.FEATURE_TYPES.FACILITY) {
    this.deleteFeature([state.feature.id]);
    this.changeMode(Constants.modes.SIMPLE_SELECT);
    return;
  }

  state.selectedCoordPaths
    .sort((a, b) => b.localeCompare(a, 'en', { numeric: true }))
    .forEach((id) => {
      state.feature.removeCoordinate(id);
    });
  this.fireUpdate();
  state.selectedCoordPaths = [];
  this.clearSelectedCoordinates();
  this.fireActionable(state);
  if (state.feature.isValid() === false) {
    this.deleteFeature([state.featureId]);
    this.changeMode(Constants.modes.SIMPLE_SELECT, {});
  }
};

DirectSelectModeOverride.snap = function(state, movedVertex, delta) {
  if (state.feature.properties._type === constants.FEATURE_TYPES.ZONE) {
    let coords = [...movedVertex.geometry.coordinates];

    // dragPosition field contains those coordinates of the point
    // where it would be if it were not witheld or snapped
    if (state.dragPosition && state.dragPosition[movedVertex.id]) {
      const storedCoords = state.dragPosition[movedVertex.id];
      coords = [storedCoords[0] + delta.lng, storedCoords[1] + delta.lat];
      delete state.dragPosition[movedVertex.id];
    }

    const { x, y } = this._ctx.map.project(coords);

    let renderedFeatures = this._ctx.map.queryRenderedFeatures([
      [x - constants.SNAPPING_DISTANCE_PX / 2, y - constants.SNAPPING_DISTANCE_PX / 2],
      [x + constants.SNAPPING_DISTANCE_PX / 2, y + constants.SNAPPING_DISTANCE_PX / 2]
    ]);

    // try to snap to a point
    const renderedPoints = renderedFeatures.filter(
      (feature) =>
        feature.properties.user__type === constants.FEATURE_TYPES.NODE ||
        feature.properties.meta === Constants.meta.VERTEX
    );

    // first try to snap to the same type (zone vertex)
    let pointToSnap = renderedPoints.find((point) => {
      const feature = this.getFeature(point.properties.id ?? point.properties.parent);
      return (
        feature.properties._type === state.feature.properties._type &&
        feature.id !== state.feature.id &&
        // selected features cannot be snapped to each other
        !this.getSelectedIds().includes(feature.id)
      );
    });

    // then try to snap to other points (floorplan nodes)
    if (!pointToSnap) {
      pointToSnap = renderedPoints.find(
        (feature) =>
          feature.properties.user__type === constants.FEATURE_TYPES.NODE &&
          // selected features cannot be snapped to each other
          !this.getSelectedIds().includes(feature.properties.id)
      );
    }

    if (pointToSnap) {
      state.dragPosition = state.dragPosition || {};
      state.dragPosition[movedVertex.id] = coords;

      state.feature.updateCoordinate(
        state.selectedCoordPaths[0],
        ...pointToSnap.geometry.coordinates
      );

      if (
        !state.lastSnappingCoords ||
        JSON.stringify(state.lastSnappingCoords) !==
          JSON.stringify(pointToSnap.geometry.coordinates)
      ) {
        this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
          coordinates: pointToSnap.geometry.coordinates
        });
        state.lastSnappingCoords = pointToSnap.geometry.coordinates;
      }
      return true;
    }
    return false;
  }

  if (state.lastSnappingCoords) {
    this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
      coordinates: undefined
    });

    state.lastSnappingCoords = undefined;
  }
};

DirectSelectModeOverride.toDisplayFeatures = function(state, geojson, display) {
  if (state.featureId === geojson.properties.id) {
    geojson.properties.active = Constants.activeStates.ACTIVE;
    display(geojson);
    let supplementaryPoints = undefined;
    if (geojson.properties.user_type === constants.FACILITY_TYPES.ELLIPSE) {
      supplementaryPoints = createSupplementaryPointsForEllipse(geojson);
    } else if (geojson.properties.user_type === constants.FACILITY_TYPES.RECTANGLE) {
      supplementaryPoints = createSupplementaryPointsForRectangle(geojson);
    } else if (geojson.properties.user_type === constants.FACILITY_TYPES.POLYGON) {
      supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      });
    } else if (geojson.properties.user__type === constants.FEATURE_TYPES.ZONE) {
      supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: true,
        selectedPaths: state.selectedCoordPaths
      });
    }

    supplementaryPoints.forEach(display);
  } else {
    if (
      state.feature.properties._type === constants.FEATURE_TYPES.ZONE &&
      geojson.properties.user__type === constants.FEATURE_TYPES.ZONE
    ) {
      const supplementaryPoints = createSupplementaryPoints(geojson, {
        map: this.map,
        midpoints: false,
        selectedPaths: state.selectedCoordPaths
      });
      supplementaryPoints.forEach(display);
    }
    geojson.properties.active = Constants.activeStates.INACTIVE;
    display(geojson);
  }
  this.fireActionable(state);
};
export default DirectSelectModeOverride;
