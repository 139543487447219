<template>
  <v-sheet class="pa-0 d-flex flex-column ">
    <v-combobox
      :label="$t('Select a level where the stairs end')"
      item-text="name"
      item-value="id"
      v-model="connectedLevelId"
      :items="selectableLevels"
      @change="(level) => change(level.id)"
    ></v-combobox>

    <span v-if="connectedLevelId" class="caption">
      {{ $t('Click on the map to add the end of the stairs.') }}</span
    >

    <gli-button class="mt-3 align-self-end" small @click="$emit('cancel')">{{
      $t('Cancel')
    }}</gli-button>
  </v-sheet>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters, mapActions } from 'vuex';
import GliButton from './base/GliButton.vue';

export default {
  components: { GliButton },
  name: 'StairsPanel',

  data() {
    return {
      connectedLevelId: undefined
    };
  },
  computed: {
    ...mapGetters('level', ['levels', 'editedLevel']),
    ...mapGetters('stairs', [
      'editedStairs',
      '',
      'editedConnectedStairs',
      'isNew',
      'addStartedOnLevelId'
    ]),
    ...mapGetters('feature', ['features', 'exchangePoints']),

    selectableLevels() {
      return this.levels.filter((level) => level.id !== this.addStartedOnLevelId);
    }
  },

  methods: {
    ...mapActions('level', ['change']),
  }
};
</script>
