<template>
  <v-tooltip bottom v-if="editorMode">
    <template v-slot:activator="{ on }">
      <v-btn
        :class="'mr-1 my-1 ' + btnClass"
        v-bind="$attrs"
        small
        min-width="0"
        width="44"
        v-on="on"
        :color="color"
        @click="$emit('click', $event)"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'PanelButton',

  props: ['color', 'icon', 'tooltip', 'btnClass'],

  data() {
    return {};
  },

  computed: {
    ...mapGetters('status', ['editorMode'])
  },

  methods: {}
};
</script>
