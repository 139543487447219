<template>
  <div style="display:none" id="contextMenuContent">
    <v-card class="mx-auto" tile>
      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in contextMenuItems" :key="i" :bind-click="item.function">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.mapboxgl-popup {
  z-index: 10;
  .mapboxgl-popup-content {
    border-radius: none;
    min-width: 200px;
    max-width: 800px;
    padding: 0px !important;

    .v-card__title {
      padding: 6px;
    }

    .v-list-item__icon {
      margin-right: 8px;
    }
  }
}
</style>

<script>
import mapboxgl from 'mapbox-gl';
import { mapActions, mapGetters } from 'vuex';
import constants from '~/shared/constants';
import i18n from '../i18n';

export default {
  name: 'ContextMenu',

  props: ['map', 'draw'],

  data() {
    return {
      contextMenu: undefined,
      contextMenuItems: [],
      isRotating: false
    };
  },
  computed: {
    ...mapGetters('status', ['editorMode']),
    ...mapGetters('level', ['editedLevel']),
    ...mapGetters('stairs', ['connectedStairsLevelName', 'getStairsData'])
  },

  mounted() {
    // remove the contextmenu if not clicked inside the contextmenu
    document.addEventListener('click', this.handleClick);

    this.map.on('mousedown', () => {
      this.isRotating = false;
      this.removeContextMenu();
    });

    this.map.on('rotatestart', () => {
      this.isRotating = true;
    });

    this.map.on('contextmenu', (e) => {
      // do not show context menu if right-click was used to rotate the map
      if (this.isRotating || this.draw.getMode() === constants.DRAW_MODES.EDGE) {
        return;
      }

      this.contextMenuItems = [];

      const clickedFeatures = e.target.queryRenderedFeatures(e.point);

      const routeFromFeature = clickedFeatures.find(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.ROUTE_FROM
      );
      if (routeFromFeature) {
        this.contextMenuItems.push({
          title: 'Remove route from',
          icon: 'mdi-human-male',
          function: `()=>{this.routeFrom(null);}`
        });
      } else {
        this.contextMenuItems.push({
          title: 'Route from',
          icon: 'mdi-human-male',
          function: `(e)=>{this.routeFrom({levelId:'${this.editedLevel.id}', displayLevel:${
            this.editedLevel.order
          }, coordinates:[${e.lngLat.toArray()}]});}`
        });
      }

      const routeToFeature = clickedFeatures.find(
        (feature) => feature.properties._type === constants.FEATURE_TYPES.ROUTE_TO
      );

      if (routeToFeature) {
        this.contextMenuItems.push({
          title: 'Remove route to',
          icon: 'mdi-flag',
          function: `()=>{this.routeTo(null);}`
        });
      } else {
        this.contextMenuItems.push({
          title: 'Route to',
          icon: 'mdi-flag',
          function: `(e)=>{this.routeTo({levelId:'${this.editedLevel.id}', displayLevel:${
            this.editedLevel.order
          }, coordinates:[${e.lngLat.toArray()}]});}`
        });
      }

      if (this.editorMode) {
        const elevatorFeature = clickedFeatures.find(
          (feature) =>
            feature.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
            feature.properties.user_type === constants.JUNCTION_TYPES.ELEVATOR
        );
        if (elevatorFeature) {
          this.contextMenuItems.push({
            title: 'Edit elevator',
            icon: 'mdi-pencil',
            function: `()=>{this.editElevator('${elevatorFeature.properties.id}');}`
          });

          this.contextMenuItems.push({
            title: 'Delete elevator',
            icon: 'mdi-delete',
            function: `()=>{this.deleteElevator({
              elevatorId: '${elevatorFeature.properties.id}',
              calledFromContextMenu: true});}`
          });
        }

        const stairsFeature = clickedFeatures.find(
          (feature) =>
            feature.properties.user__type === constants.FEATURE_TYPES.JUNCTION &&
            feature.properties.user_type === constants.JUNCTION_TYPES.STAIRS
        );

        if (stairsFeature) {
          const levelName = this.connectedStairsLevelName(stairsFeature.properties.id);
          const title = i18n.t('Go to end: ') + levelName;

          this.contextMenuItems.push({
            title: title,
            icon: 'mdi-target',
            // eslint-disable-next-line max-len
            function: `()=>{this.goToConnectedStairs('${stairsFeature.properties.id}');}`
          });

          this.contextMenuItems.push({
            title: 'Delete stairs',
            icon: 'mdi-delete',
            function: `()=>{this.deleteStairs({
              stairsId: '${stairsFeature.properties.id}',
              openConfirmPopup: true});}`
          });
        }
      }

      this.map.getCanvas().style.cursor = 'default';

      this.$nextTick(() => {
        const contextMenuContent = document.getElementById('contextMenuContent').cloneNode(true);
        contextMenuContent.style.display = 'block';
        contextMenuContent.id = 'contextMenuContent-cloned';

        this.contextMenu = new mapboxgl.Popup({
          offset: 0,
          closeButton: false
        })
          .setLngLat(e.lngLat)
          .setDOMContent(contextMenuContent)
          .addTo(this.map);

        // bind popup-content events to vue components methods
        document.querySelectorAll('.mapboxgl-popup-content *[bind-click]').forEach((element) => {
          element.addEventListener('click', () => {
            this.removeContextMenu();

            const func = element.getAttribute('bind-click');

            if (Object.keys(this).indexOf(func) > -1) {
              // a name of a function on this vue component
              this[element.getAttribute('bind-click')](e);
            } else {
              // a complete function (bind to the vue component /this/)
              ((func) => {
                return eval(func)(e);
              }).call(this, func);
            }
          });
        });
      });
    });
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClick);
  },

  methods: {
    ...mapActions('elevator', { editElevator: 'edit', deleteElevator: 'delete' }),
    ...mapActions('stairs', {
      deleteStairs: 'delete'
    }),
    ...mapActions('routing', ['routeFrom', 'routeTo']),
    ...mapActions('level', { changeLevel: 'change' }),

    handleClick(e) {
      let path = e.path || (e.composedPath && e.composedPath());
      if (path.every((item) => item.id !== 'contextMenuContent-cloned')) {
        this.removeContextMenu();
      }
    },
    removeContextMenu() {
      if (this.contextMenu) {
        this.contextMenu.remove();
        this.contextMenu = undefined;
      }
    },

    async goToConnectedStairs(id) {
      const data = await this.getStairsData({ stairsId: id });
      const coords = data.connectedStairs.feature.geometry.coordinates;
      await this.changeLevel(data.connectedStairs.levelId);
      this.map.flyTo({ center: [...coords] });
      this.draw.changeMode(constants.DRAW_MODES.SIMPLE_SELECT, {
        featureIds: [data.connectedStairs.id]
      });
    }
  },
  components: {}
};
</script>
