import * as Constants from '@mapbox/mapbox-gl-draw/src/constants';
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';
import {
  bearing as turfBearing,
  bearingToAzimuth as turfBearingToAzimuth,
  length as turfLength,
  lineIntersect as turfLineIntersect,
  nearestPointOnLine as turfNearestPointOnLine,
  transformRotate as turfTransformRotate
} from '@turf/turf';
import constants from '~/shared/constants';

import {
  getFromPropertyName,
  getToPropertyName,
  haveCommonEndpoint,
  isEdgeOrNetworkSegment,
  isNodeOrJunction,
  splitLine,
  tIntersection
} from '~/shared/utils';

import { store } from '../../store';

const DrawEdgeMode = {
  onSetup: function(opts) {
    doubleClickZoom.disable(this);

    const pointType =
      opts.type === constants.FEATURE_TYPES.EDGE
        ? constants.FEATURE_TYPES.NODE
        : constants.FEATURE_TYPES.JUNCTION;

    let state = {
      type: opts.type,
      pointType: pointType,
      from: undefined,
      guideline: undefined,
      lastSnappingCoords: undefined,
      levelId: opts.levelId
    };
    return state;
  },

  onClick: function(state, e) {
    this.snapAngle(state, e);
    this.snap(state, e);

    this.map.fire(constants.CUSTOM_DRAW_EVENTS.EDGE_DRAWING, {
      isDrawing: false,
      feature: undefined
    });

    if (e.originalEvent.button !== constants.MOUSE_BUTTON.LEFT) {
      if (state.from) {
        //copying value, because clickOnFeature changes the value in the state
        const removeLast = state.onlyOneSingleNodeAdded;
        this.clickOnFeature(state, state.from);
        if (removeLast) {
          store.dispatch('history/undoAndRemoveLast', null, { root: true });
        }
        return;
      } else {
        return this.changeMode('simple_select');
      }
    }

    const renderedFeatures = this._ctx.map.queryRenderedFeatures(e.point);

    const segmentFeatures = renderedFeatures.filter(
      (feature) =>
        state.type === feature.properties.user__type &&
        !feature.properties.user_guideline &&
        !feature.properties.user_measure_guides
    );

    const pointFeatures = renderedFeatures.filter(
      (feature) => state.pointType === feature.properties.user__type
    );

    let feature = undefined;
    if (pointFeatures.length) {
      feature = this.getFeature(pointFeatures[0].properties.id);
    } else if (segmentFeatures.length) {
      feature = this.getFeature(segmentFeatures[0].properties.id);
    }

    if (isNodeOrJunction(feature)) {
      this.clickOnFeature(state, feature);
    } else if (isEdgeOrNetworkSegment(feature)) {
      const segment = feature;

      const nearestPoint = turfNearestPointOnLine(segment, e.lngLat.toArray());

      const newPoint = this.newFeature({
        type: 'Feature',
        properties: {
          _type:
            state.type === constants.FEATURE_TYPES.EDGE
              ? constants.FEATURE_TYPES.NODE
              : constants.FEATURE_TYPES.JUNCTION,
          type: 'normal',
          levelId: state.levelId
        },
        geometry: {
          type: 'Point',
          coordinates: nearestPoint.geometry.coordinates
        }
      });

      newPoint.properties.id = newPoint.id;

      this.addFeature(newPoint);
      this.map.fire(Constants.events.CREATE, {
        features: [newPoint.toGeoJSON()],
        skipHistory: state.from
      });

      if (!state.from) {
        state.onlyOneSingleNodeAdded = true;
      }

      const originalTo = {
        toId: segment.properties[getToPropertyName(state.type)],
        coordinates: segment.coordinates[1]
      };

      segment.properties[getToPropertyName(state.type)] = newPoint.id;
      segment.incomingCoords([segment.coordinates[0], nearestPoint.geometry.coordinates]);

      const newSegment = this.newFeature({
        type: 'Feature',
        properties: {
          ...segment.properties,
          alreadyCreated: false,
          id: undefined,
          levelId: state.levelId
        },
        geometry: {
          type: 'LineString',
          coordinates: [nearestPoint.geometry.coordinates, originalTo.coordinates]
        }
      });

      newSegment.properties[getFromPropertyName(state.type)] = newPoint.id;
      newSegment.properties[getToPropertyName(state.type)] = originalTo.toId;

      this.addFeature(newSegment);

      this.clickOnFeature(state, newPoint);
    } else {
      // if there is no node or junction in the clicked position, then create one
      let point = this.newFeature({
        type: 'Feature',
        properties: {
          _type:
            state.type === constants.FEATURE_TYPES.EDGE
              ? constants.FEATURE_TYPES.NODE
              : constants.FEATURE_TYPES.JUNCTION,
          type: 'normal',
          levelId: state.levelId
        },
        geometry: {
          type: 'Point',
          coordinates: e.lngLat.toArray()
        }
      });

      this.addFeature(point);
      this.map.fire(Constants.events.CREATE, {
        features: [point.toGeoJSON()],
        skipHistory: state.from
      });

      if (!state.from) {
        state.onlyOneSingleNodeAdded = true;
      }

      this.clickOnFeature(state, point);
    }
  },

  clickOnFeature: function(state, feature) {
    if (!isNodeOrJunction(feature)) {
      state.from = undefined;
      state.clickedFeature = undefined;
      return;
    }

    // force to send the clicked feature to toDisplayFeatures
    if (state.clickedFeature) {
      state.clickedFeature.incomingCoords(state.clickedFeature.coordinates);
    }

    state.clickedFeature = feature;

    // force to send the clicked feature to toDisplayFeatures
    state.clickedFeature.incomingCoords(feature.coordinates);

    if (!state.from) {
      this.setFrom(state, feature);
      return;
    }

    // click on the same point
    if (state.from.id === feature.id) {
      state.clickedFeature = undefined;

      this.unsetFrom(state);
      return;
    }

    if (!this.edgeAlreadyExists(state.type, state.from.id, feature.id)) {
      let line = this.newFeature({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [state.from.coordinates, feature.coordinates]
        },
        properties: {
          _type: state.type,
          levelId: state.levelId
        }
      });

      if (state.type === constants.FEATURE_TYPES.NETWORK_SEGMENT) {
        line.properties.wheelchair = constants.DEFAULT_WHEELCHAIR;
        line.properties.vtr = constants.DEFAULT_VTR;
        line.properties.pathClass = constants.PATH_CLASSES.NORMAL;
      }
      line.properties[getFromPropertyName(state.type)] = state.from.id;
      line.properties[getToPropertyName(state.type)] = feature.id;

      this._ctx.api.add(line.toGeoJSON());
      this.map.fire(Constants.events.CREATE, {
        features: [line.toGeoJSON()]
      });

      this.cutIntersectingLines(state.pointType, state.type);
    }
    this.setFrom(state, feature);
  },

  snap: function(state, e) {
    // snapping is disabled if the alt key is pressed
    if (!e.originalEvent.altKey) {
      const renderedFeatures = this._ctx.map
        .queryRenderedFeatures([
          [
            e.point.x - constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y - constants.SNAPPING_DISTANCE_PX / 2
          ],
          [
            e.point.x + constants.SNAPPING_DISTANCE_PX / 2,
            e.point.y + constants.SNAPPING_DISTANCE_PX / 2
          ]
        ])
        .filter(
          (feature) =>
            feature.properties.user__type &&
            !feature.properties.user_guideline &&
            !feature.properties.user_measure_guides
        );

      // first try to snap to a point
      const renderedPoints = renderedFeatures.filter(
        (feature) =>
          feature.properties.user__type === state.pointType ||
          feature.properties.user__type === constants.FEATURE_TYPES.REFERENCE_NODE
      );

      if (renderedPoints.length) {
        const renderedPoint = renderedPoints[0];
        const point = this.getFeature(renderedPoint.properties.id);
        e.lngLat.lng = point.coordinates[0];
        e.lngLat.lat = point.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = renderedPoint;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !== JSON.stringify(e.lngLat.toArray())
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();
          this.updateHighlightPoint(state, e.lngLat.toArray());
        }
        return;
      }

      // then try to snap to a segment
      const renderedSegments = renderedFeatures.filter(
        (feature) => feature.properties.user__type === state.type
      );

      if (renderedSegments.length) {
        const renderedSegment = renderedSegments[0];
        const segment = this.getFeature(renderedSegment.properties.id);

        const nearestPoint = turfNearestPointOnLine(segment, e.lngLat.toArray());

        e.lngLat.lng = nearestPoint.geometry.coordinates[0];
        e.lngLat.lat = nearestPoint.geometry.coordinates[1];
        e.point = this._ctx.map.project(e.lngLat);
        e.featureTarget = renderedSegment;

        if (
          !state.lastSnappingCoords ||
          JSON.stringify(state.lastSnappingCoords) !==
            JSON.stringify(nearestPoint.geometry.coordinates)
        ) {
          this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
            coordinates: e.lngLat.toArray()
          });
          state.lastSnappingCoords = e.lngLat.toArray();
          this.updateHighlightPoint(state, e.lngLat.toArray());
        }

        return;
      }
    }
    if (state.lastSnappingCoords) {
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });

      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
    }
  },

  snapAngle: function(state, e) {
    if (!state.from) {
      return;
    }

    // snapping is disabled if the alt key is pressed
    if (!e.originalEvent.altKey) {
      const { features } = this._ctx.api.getAll();
      const connectedLines = features.filter(
        (feature) =>
          feature.properties._type === state.type &&
          (feature.properties[getFromPropertyName(state.type)] === state.from.id ||
            feature.properties[getToPropertyName(state.type)] === state.from.id)
      );

      if (!connectedLines.length) {
        return undefined;
      }

      const guidelineAngle = turfBearingToAzimuth(
        turfBearing(state.from.coordinates, e.lngLat.toArray())
      );

      const connectedLinesAngles = [];
      connectedLines.forEach((line) => {
        let toCoords = line.geometry.coordinates[0];
        if (line.properties[getFromPropertyName(state.type)] === state.from.id) {
          toCoords = line.geometry.coordinates[1];
        }
        const angle = turfBearingToAzimuth(turfBearing(state.from.coordinates, toCoords));
        connectedLinesAngles.push(angle);
      });

      let clockwiseLeft = connectedLinesAngles
        .filter((angle) => angle <= guidelineAngle)
        .reduce((prev, curr) => (prev > curr ? prev : curr), -1);

      if (clockwiseLeft < 0) {
        clockwiseLeft = connectedLinesAngles.reduce(
          (prev, curr) => (prev > curr ? prev : curr),
          -1
        );
      }

      let diffAngle = guidelineAngle - clockwiseLeft;
      if (diffAngle < 0) {
        diffAngle = diffAngle + 360;
      }

      const angleToSnap =
        Math.round(diffAngle / constants.SNAPPING_ANGLE_DEGREE) * constants.SNAPPING_ANGLE_DEGREE;

      const stateFromCoords = JSON.parse(JSON.stringify(state.from.coordinates));
      let rotated = turfTransformRotate(
        { type: 'LineString', coordinates: [stateFromCoords, e.lngLat.toArray()] },
        angleToSnap - diffAngle,
        {
          pivot: stateFromCoords,
          mutate: true
        }
      );

      e.lngLat.lng = rotated.coordinates[1][0];
      e.lngLat.lat = rotated.coordinates[1][1];
    }
  },

  setFrom: function(state, feature) {
    if (state.from) {
      state.onlyOneSingleNodeAdded = false;
    }

    state.from = feature;

    if (!state.guideline) {
      let guideline = this.newFeature({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [feature.coordinates, feature.coordinates]
        },
        properties: {
          _type: state.type,
          guideline: true
        }
      });

      this.addFeature(guideline);
      state.guideline = guideline;
      this.select(guideline.id);
      this.map.fire(Constants.events.CREATE, {
        features: [guideline.toGeoJSON()],
        skipHistory: true
      });
    } else {
      state.guideline.updateCoordinate(0, feature.coordinates[0], feature.coordinates[1]);
    }
  },

  unsetFrom: function(state) {
    state.from = undefined;
    state.onlyOneSingleNodeAdded = false;

    if (state.guideline) {
      this.deselect(state.guideline.id);
      this.deleteFeature(state.guideline.id, { silent: true });
      state.guideline = undefined;
    }
  },

  cutIntersectingLines: function(pointType, segmentType) {
    let changed, i, j;
    do {
      const features = this._ctx.api
        .getAll()
        .features.filter(
          (feature) =>
            feature.properties._type === segmentType &&
            !feature.properties.guideline &&
            !feature.properties.user_measure_guides
        );
      changed = false;
      i = 0;
      while (i < features.length - 1 && !changed) {
        j = i + 1;
        while (j < features.length && !changed) {
          if (
            !haveCommonEndpoint(features[i], features[j]) &&
            !tIntersection(features[i], features[j])
          ) {
            const intersection = turfLineIntersect(features[i], features[j]);
            if (intersection.features && intersection.features.length === 1) {
              const lineOne = features[i];
              const lineTwo = features[j];
              const newPoint = intersection.features[0];
              newPoint.properties.levelId = lineOne.properties.levelId;
              newPoint.properties._type = pointType;
              if (pointType === constants.FEATURE_TYPES.JUNCTION) {
                newPoint.properties.type = constants.JUNCTION_TYPES.NORMAL;
              }
              const newPointId = this._ctx.api.add(newPoint)[0];
              newPoint.id = newPointId;

              const lineOneParts = splitLine(lineOne, newPoint);
              const lineTwoParts = splitLine(lineTwo, newPoint);

              if (lineOneParts[0] && lineOneParts[1] && lineTwoParts[0] && lineTwoParts[1]) {
                this._ctx.api.add(lineOneParts[0]);
                this._ctx.api.add(lineOneParts[1]);
                this._ctx.api.add(lineTwoParts[0]);
                this._ctx.api.add(lineTwoParts[1]);

                this.deleteFeature(lineOne.id, { silent: true });
                this.deleteFeature(lineTwo.id, { silent: true });

                changed = true;
              } else {
                this.deleteFeature(newPointId, { silent: true });
              }
            }
          }
          j++;
        }
        i++;
      }
    } while (changed);
  },

  onMouseMove: function(state, e) {
    this.snapAngle(state, e);
    this.snap(state, e);

    if (state.from) {
      state.guideline.updateCoordinate(1, e.lngLat.lng, e.lngLat.lat);
      this.fireEdgeDrawingEvent(state, e);
    }
  },

  /** Returns the minimum and maximum angle between the drawn edge
   * and the edges that started or ended on the same node. */
  getAngles(state, endPoint) {
    const { features } = this._ctx.api.getAll();
    const connectedLines = features.filter(
      (feature) =>
        feature.properties._type === state.type &&
        (feature.properties[getFromPropertyName(state.type)] === state.from.id ||
          feature.properties[getToPropertyName(state.type)] === state.from.id)
    );

    if (!connectedLines.length) {
      return undefined;
    }

    const guidelineAngle = turfBearingToAzimuth(turfBearing(state.from.coordinates, endPoint));

    let rightAngle = 360;
    let leftAngle = 360;

    connectedLines.forEach((line) => {
      let toCoords = line.geometry.coordinates[0];
      if (line.properties[getFromPropertyName(state.type)] === state.from.id) {
        toCoords = line.geometry.coordinates[1];
      }

      const angle = turfBearingToAzimuth(turfBearing(state.from.coordinates, toCoords));

      const diffAngle = angle - guidelineAngle; //diff to the right

      const diffRight = diffAngle < 0 ? 360 + diffAngle : diffAngle;
      const diffLeft = 360 - diffRight;

      if (diffRight < rightAngle) {
        rightAngle = diffRight;
      }

      if (diffLeft < leftAngle) {
        leftAngle = diffLeft;
      }
    });

    const minAngle = rightAngle <= leftAngle ? rightAngle : leftAngle;

    return { rightAngle, leftAngle, minAngle };
  },

  fireEdgeDrawingEvent: function(state, e) {
    const mouseCoords = e.lngLat.toArray();

    const angles = this.getAngles(state, mouseCoords);

    const length = turfLength({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [state.from.coordinates, mouseCoords]
      }
    });

    this._ctx.api.setFeatureProperty(
      state.guideline.id,
      'length',
      (length * 1000).toFixed(2) + ' m'
    );

    const from = this.map.project(state.from.coordinates);
    const to = this.map.project(mouseCoords);
    const pixelLength = Math.sqrt(
      (from.x - to.x) * (from.x - to.x) + (from.y - to.y) * (from.y - to.y)
    );
    const bearing = turfBearing(state.from.coordinates, mouseCoords);
    // bearing has to be adjusted with map bearing
    let adjustedBearing = bearing - this.map.getBearing();
    adjustedBearing = adjustedBearing >= 180 ? adjustedBearing - 360 : adjustedBearing;
    adjustedBearing = adjustedBearing <= -180 ? adjustedBearing + 360 : adjustedBearing;
    // works strangely with left anchor, so it has to be right
    let anchor = 'right';
    // pixelLength has to be divided by 12 because of unit conversion (em->px)
    // and by 2, because it is centered, so only half of the length is needed
    let offsetX = pixelLength / 24;
    let offsetY;
    if (adjustedBearing < 0) {
      // if both angles and length are displayed, shift length to the top of the angle
      if (offsetX < 5.5 && angles) {
        offsetY = 2;
      } else {
        offsetY = 1;
      }
      // *left* lines' offsets have to be adjusted according to the length of the text,
      // which changes with the number of digits of course
      if (length * 100 < 1) {
        offsetX = offsetX - 3;
      } else if (length * 10 < 1) {
        offsetX = offsetX - 3.5;
      } else if (length < 1) {
        offsetX = offsetX - 4;
      } else {
        offsetX = offsetX - 5;
      }
    }
    if (adjustedBearing >= 0) {
      // if both angles and length are displayed, shift length to the top of the angle
      if (offsetX < 5.5 && angles) {
        offsetY = -2;
      } else {
        offsetY = -1;
      }
    }

    this._ctx.api.setFeatureProperty(state.guideline.id, 'anchor', anchor);
    this._ctx.api.setFeatureProperty(state.guideline.id, 'offset', [offsetX, offsetY]);
    if (angles && angles.leftAngle) {
      this._ctx.api.setFeatureProperty(
        state.guideline.id,
        'left_angle',
        `${angles.leftAngle.toFixed(2)}°`
      );
    }

    if (angles && angles.rightAngle) {
      this._ctx.api.setFeatureProperty(
        state.guideline.id,
        'right_angle',
        `${angles.rightAngle.toFixed(2)}°`
      );
    }

    this.map.fire(constants.CUSTOM_DRAW_EVENTS.EDGE_DRAWING, {
      isDrawing: true,
      minAngle: angles?.minAngle,
      length,
      mouseCoords
    });
  },

  onKeyUp: function(state, e) {
    if (e.keyCode === 27) {
      state.lastSnappingCoords = undefined;
      this.removeHighlightPoint(state);
      this.map.fire(constants.CUSTOM_DRAW_EVENTS.SNAPPING, {
        coordinates: undefined
      });
      return this.changeMode('simple_select');
    }
  },

  onMouseOut: function(state) {
    state.lastSnappingCoords = undefined;
    this.removeHighlightPoint(state);
  },

  toDisplayFeatures: function(state, geojson, display) {
    // highlight clicked feature
    if (state.clickedFeature && geojson.properties.id === state.clickedFeature.id) {
      geojson.properties.active = Constants.activeStates.ACTIVE;
    }

    display(geojson);
  },

  edgeAlreadyExists: function(type, fromId, toId) {
    return this._ctx.api
      .getAll()
      .features.filter((feature) => isEdgeOrNetworkSegment(feature))
      .some((feature) => {
        const fromProperty = feature.properties[getFromPropertyName(type)];
        const toProperty = feature.properties[getToPropertyName(type)];

        return (
          (fromProperty === fromId && toProperty === toId) ||
          (fromProperty === toId && toProperty === fromId)
        );
      });
  },

  onStop: function(state) {
    if (state.guideline) {
      this.deleteFeature([state.guideline.id], { silent: true });
    }

    if (state.onlyOneSingleNodeAdded) {
      store.dispatch('history/undoAndRemoveLast', null, { root: true });
    }

    doubleClickZoom.enable(this);
  },

  updateHighlightPoint(state, coordinates) {
    const highlightFeature = {
      type: 'Feature',
      properties: {
        _type: constants.FEATURE_TYPES.HIGHLIGHT_POINT,
        levelId: state.levelId
      },
      geometry: {
        type: 'Point',
        coordinates: coordinates
      }
    };

    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
    }

    const highlightPoint = this.newFeature(highlightFeature);
    highlightPoint.properties.id = highlightPoint.id;
    this.addFeature(highlightPoint);
    state.highlightPoint = highlightPoint;
  },

  removeHighlightPoint(state) {
    if (state.highlightPoint && state.highlightPoint.id) {
      this.deleteFeature(state.highlightPoint.id);
      state.highlightPoint = undefined;
    }
  }
};

export default DrawEdgeMode;
